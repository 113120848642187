import {Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import axiosInstance from '../../../../utils/axios'
import * as authHelper from './AuthHelpers'
import {UserModel} from './_models'

type AuthContextProps = {
  currentUser: UserModel | undefined | null
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined | null>>
  logout: () => void
  getCurrentUser: () => void
  getCurrentMemberData: () => null
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  getCurrentUser: () => {},
  getCurrentMemberData: () => null,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [currentUser, setCurrentUser] = useState<UserModel | undefined | null>(() => {
    const storedUser = localStorage.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const getCurrentUser = async () => {
    const userId = await JSON.parse(localStorage.getItem('userId') || '{}');
    await axiosInstance
      .get(`/api/users/${userId}`)
      .then((response) => {
        const user = response.data;
        localStorage.setItem('currentUser', JSON.stringify(user));
        setCurrentUser(user); // Update currentUser
        storeParticipantInformation(userId); // Update currentMemberData
        window.location.replace('/dashboard');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getCurrentMemberData = () => {
    const data = localStorage.getItem('currentMemberData');
    return data ? JSON.parse(data) : null;
  }


  const storeParticipantInformation = async (representativeId: number) => {
    const participantMemberdata = await axiosInstance.get(
      `/api/members?filters[representativeId][userId]=${representativeId}`
    );

     const memberData = {
      ...participantMemberdata.data.data[0].attributes,
      id: participantMemberdata.data.data[0].id,
    };
    localStorage.setItem('currentMemberData', JSON.stringify(memberData)); // Update currentMemberData
  }

  useEffect(() => {
    // Function to fetch user data based on userId
    const fetchUserByUserId = async (userId: string) => {
      try {
        const response = await axiosInstance.get(`/api/users/${userId}`)
        const user = response.data
        localStorage.setItem('currentUser', JSON.stringify(user))
        setCurrentUser(user)
      } catch (err) {
        console.error(err)
      }
    }

    // Fetch user data when the component mounts
    const userId = JSON.parse(localStorage.getItem('userId') || '{}')
    if (userId && Object.keys(userId).length > 0) {
      fetchUserByUserId(userId)
    }

    // Listen for changes in userId and update currentUser
    window.addEventListener('storage', (event) => {
      if (event.key === 'userId') {
        const newUserId = JSON.parse(event.newValue || '{}');
        fetchUserByUserId(newUserId);
        storeParticipantInformation(newUserId); // Update currentMemberData
      }
    });

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('storage', (event) => {
        if (event.key === 'userId') {
          const newUserId = JSON.parse(event.newValue || '{}')
          fetchUserByUserId(newUserId)
        }
      })
    }
  }, [])

  //Porce Logout and remove auth
  const logout = () => {
    authHelper.removeAuth()
    window.location.replace('/')
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        logout,
        getCurrentUser,
        getCurrentMemberData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {setCurrentUser} = useAuth()
  useEffect(() => {
    authHelper.getToken()
    const currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser)
      setCurrentUser(parsedUser)
    }
  }, [])

  return <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
