import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import MySwal from '../../../../../utils/sweetAlert'
import {ChangeEvent, useState, FormEvent, useEffect} from 'react'
import {useMission} from '../../core/MissionProvider'
import axiosInstance from '../../../../../utils/axios'
import axiosInstanceFormData from '../../../../../utils/axiosFormData'
import {useParams} from 'react-router-dom'
import {IStepperProps} from '../../../../../types/types'
import {useAuth} from '../../../auth'
import { Widget } from '@typeform/embed-react'
import axiosInstanceTypeForm from '../../../../../utils/axiosTypeForm'

const CSRStep: React.FC<IStepperProps> = ({activeStep, setActiveStep, isRepresentativeView}) => {
  const [downloadLink, setDownloadLink] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [hasCSR, setHasCSR] = useState(false)
  const {missionId} = useParams()
  const {currentMissionRegistration, setLoadingScreen,fetchMissionDetails} = useMission()
  const {currentUser} = useAuth()
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/missions/${missionId}`);
        const data = response.data;
        setDownloadLink(data.data.attributes.csrLink ? data.data.attributes.csrLink  : '#')
        // Do something with the data, e.g., update state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [missionId]);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasCSR(event.target.checked)
  }

//   useEffect(() => {
//     try {
//       if(downloadLink){
//        const response = axiosInstanceTypeForm.get(`/${downloadLink}/responses`)
//         console.log(response)
//           }
//     } catch (error) {
//         console.error('There was an error in the fetch request:', error);
//     }
// }, [downloadLink]); 


  // const handleFileChange = (e) => {
  //   const file = e.target.files[0]
  //   setSelectedFile(file)
  // }
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleFormSubmit = async () => {
    setFormSubmitted(true);
    await axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
      data: {
        submittedCSRStatus: 2,
        billingStatus: 2,
      },
    })
    await axiosInstance.post(`/api/registration-logs`, {
      data:{
        title: 'Uploaded',
        message: 'Uploaded the CSR',
        company: currentMissionRegistration?.compDisplayName,
        category: 'CSR',
        user_id: currentUser?.id,
        mission: missionId,
      }
    })
    MySwal.fire({
      title: 'CSR Submitted',
      icon: 'success',
      confirmButtonColor: '#1976d2',
      confirmButtonText: 'Next',
    }).then(() => {
      if (isRepresentativeView) {
        window.location.href = `/representative`
      } else {
        window.location.href = `/missions/view/${missionId}`
      }
    })
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSize) {
        MySwal.fire({
          text: 'File size exceeds the limit of 5 MB.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
      const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
      if (!allowedFileTypes.includes(file.type)) {
        MySwal.fire({
          text: 'Invalid file type. Please select a PDF, DOCX, XLSX, or PPT file.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
      setSelectedFile(file)
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setLoadingScreen(true)
      if (hasCSR) {
        axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
          data: {
            submittedCSRStatus: 2,
            billingStatus: 2,
          },
        })
        await axiosInstance.post(`/api/registration-logs`, {
          data:{
            title: 'Declined',
            message: 'Declined the CSR',
            company: currentMissionRegistration?.compDisplayName,
            category: 'CSR',
            user_id: currentUser?.id,
            mission: missionId,
          }
        })
        MySwal.fire({
          title: 'CSR Declined',
          icon: 'success',
          confirmButtonColor: '#1976d2',
          confirmButtonText: 'Next',
        }).then(() => {
          if (isRepresentativeView) {
            window.location.href = `/representative`
          } else {
            window.location.href = `/missions/view/${missionId}`
          }
        })
      } else {
        axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
          data: {
            submittedCSRStatus: 2,
            billingStatus: 2,
          },
        })
        await axiosInstance
          .post(`/api/member-csrs`, {
            data: {
              //@ts-ignore
              member: currentMissionRegistration?.memberId?.data.id,
            },
          })
          .then(async (response) => {
            const formData = new FormData()
            if (selectedFile) {
              formData.append('files', selectedFile)
              formData.append('refId', `${response.data.data.id}`)
              formData.append('ref', 'api::member-csr.member-csr')
              formData.append('field', 'csrFile')
              formData.append('path', 'csrFile')
            }
            await axiosInstanceFormData.post('/api/upload', formData)
          })
          await axiosInstance.post(`/api/registration-logs`, {
            data:{
              title: 'Uploaded',
              message: 'Uploaded the CSR',
              company: currentMissionRegistration?.compDisplayName,
              category: 'CSR',
              user_id: currentUser?.id,
              mission: missionId,
            }
          })
        MySwal.fire({
          title: 'CSR Uploaded',
          icon: 'success',
          confirmButtonColor: '#1976d2',
          confirmButtonText: 'Next',
        }).then(() => {
          if (isRepresentativeView) {
            window.location.href = `/representative`
          } else {
            window.location.href = `/missions/view/${missionId}`
          }
        })
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Please attach a file or decline to provide CSR materials.',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
    } finally {
      setLoadingScreen(false)
    }
  }
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
       {/* @ts-ignore */}
       {downloadLink && downloadLink !== '#' && !(downloadLink.includes('.') || downloadLink.includes('https')) ? <>
       <Widget
            id={downloadLink}
            style={{ width: '100%', height: '600px' }}
            className="my-form"
            onSubmit={handleFormSubmit}
          />
          {formSubmitted && <p>Redirecting...</p>}
      <Container>
        <Grid container spacing={2} mt={0.2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='edit-csr-decline'
                      name='csr_decline'
                      checked={hasCSR}
                      onChange={handleCheckboxChange}
                      color='primary'
                    />
                  }
                  label={
                    //@ts-ignore
                    <strong>
                      {currentMissionRegistration?.compDisplayName} will not provide CSR materials
                      for this registration.
                    </strong>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item textAlign='right' xs={12}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                sx={{
                  float: "left",
                  marginRight: 2,
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
              { showBackToDetailsButton && (<Button
                  type='button'
                  variant='contained'
                  color='primary'
                  href={`/missions/view/${missionId}`}
                  sx={{
                    float: "left"
                  }}
                >
                  Back to Details Page
                </Button>)}
              <Button type='button' variant='contained' color='primary' onClick={handleSubmit}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      </> : <>
      <Typography variant='h5'>
          No Form has been provided at the moment you may choose to
          decline to provide CSR materials.
        </Typography>
        <Container>
        <Grid container spacing={2} mt={0.2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='edit-csr-decline'
                      name='csr_decline'
                      checked={hasCSR}
                      onChange={handleCheckboxChange}
                      color='primary'
                    />
                  }
                  label={
                    //@ts-ignore
                    <strong>
                      {currentMissionRegistration?.compDisplayName} will not provide CSR materials
                      for this registration.
                    </strong>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item textAlign='right' xs={12}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                sx={{
                  marginRight: 2,
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
              <Button type='button' variant='contained' color='primary' onClick={handleSubmit}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      </>}
      
    </Box>
  )
}

export default CSRStep
