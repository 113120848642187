import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../utils/axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {Done, CheckCircle, RemoveCircle} from '@mui/icons-material'
import CustomNoRowsOverlay from '../../../common/components/TableNoDataOverlay'
import {Box, Chip, Stack, Typography} from '@mui/material'

type LogsFormProps = {
  missionId: string;
};

type Notification = {
    id: number;
    attributes: {
      title: string;
      message: string;
      company: string;
      user_id: number;
      category: string;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
    };
  };

const LogsForm: React.FC<LogsFormProps> = ({ missionId }) => {
  const [logs, setLogs] = useState<Notification[]>([]);
  const [users, setUsers] = useState({});
  const [statusFilter, setStatusFilter] = useState('all');
  const [hasCsr, setHasCsr] = useState()

  const SortingChips = () => {
    const handleClickBill = () => {
      setStatusFilter('Billing Info');
    };
  
    const handleClickParticipants = () => {
        setStatusFilter('Participants');
      };

    const handleClickTalk = () => {
      setStatusFilter('Talkpoints'); 
    };

    const handleClickCompany = () => {
        setStatusFilter('Company');
    };

    const handleClickCSR= () => {
        setStatusFilter('CSR');
    };
  
    const handleClick = () => {
      setStatusFilter('all'); 
    };
  
    return (
      <Stack direction='row' spacing={2}>
        <Chip size='small' label='All' onClick={handleClick} icon={<Done />} />
        <Chip
          color='primary'
          size='small'
          label='Billing Info'
          onClick={handleClickBill}
          icon={<RemoveCircle />}
        />
        <Chip
          color='secondary'
          size='small'
          label='Participants Info'
          onClick={handleClickParticipants}
          icon={<RemoveCircle />}
        />
        <Chip
          color='error'
          size='small'
          label='Talkpoints'
          onClick={handleClickTalk}
          icon={<RemoveCircle />}
        />
        <Chip
          color='success'
          size='small'
          label='Company Profile'
          onClick={handleClickCompany}
          icon={<RemoveCircle />}
        />
        {hasCsr &&
         <Chip
          color='warning'
          size='small'
          label='CSR'
          onClick={handleClickCSR}
          icon={<RemoveCircle />}
        />
        }
      </Stack>
    )
  }

  useEffect(() => {
    const fetchCsr = async () => {
    const res= await axiosInstance.get(`/api/missions/${missionId}`)
    const data = res.data.data.attributes.hasCsr
    setHasCsr(data)
    }
    fetchCsr()
  },[missionId])
  useEffect(() => {
    axiosInstance.get('/api/users').then(response => {
      const usersById = response.data.reduce((acc, user) => ({
        ...acc,
        [user.id]: user,
      }), {});
      setUsers(usersById);
    }).catch(error => {
      console.error("Failed to fetch users:", error);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/registration-logs?filters[mission][id]=${missionId}&pagination[page]=1&pagination[pageSize]=100&sort[0]=createdAt:desc`);
        setLogs(response.data.data);
      } catch (error) {
        console.error('Error fetching log data:', error);
      }
    };
    fetchData();
  }, [missionId]);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'message',
      headerName: 'Action',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: true,
      flex: 2,
    },
    {
        field: 'company',
        headerName: 'Company',
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        editable: false,
        sortable: true,
        flex: 1,
      },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(date);
      
        return (
          <>
            <Typography>
              {formattedDate}
            </Typography>
          </>
        )
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(date);
      
        return (
          <>
            <Typography>
              {formattedDate}
            </Typography>
          </>
        )
      },
    },
    {
        field: 'user_id',
        headerName: 'Done By',
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        editable: false,
        sortable: true,
        renderCell: (params) => {
          const displayName = users[params.value]?.username || "Not Specified";
          return (
            <Typography>{displayName}</Typography>
          );
        },
      }
  ];
  
  const rows = logs.map((logs) => ({
    id: logs.id,
    title: logs.attributes.title,
    message: logs.attributes.message,
    company: logs.attributes.company,
    user_id: logs.attributes.user_id,
    category: logs.attributes.category,
    createdAt: logs.attributes.createdAt,
    updatedAt: logs.attributes.updatedAt,
    publishedAt: logs.attributes.publishedAt,
  }));

  const sortedRows = rows.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
  const filteredRows = sortedRows
  .filter(data => {
      switch (statusFilter) {
        case 'all':
          return true;
        case 'Billing Info':
          return data.category === "Billing Info"
        case 'Participants':
          return data.category === "Participants"
        case 'Talkpoints':
          return data.category === "Talkpoints"
        case 'Company':
          return data.category === "Company"
        case 'CSR':
          return data.category === "CSR"
        default:
          return true;
      }
  });

  return (
    <Box
      sx={{
        height: '300px',
        width: '100%',
        marginTop: '5px',
      }}
    >
    <div className='d-flex  justify-content-between mb-4'>
    <Typography variant='h5'>Logs</Typography>
    <SortingChips />
    </div>
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={filteredRows as unknown as readonly any[]}
        columns={columns}
        hideFooterPagination
        hideFooter
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
    </Box>
  );
};

export default LogsForm;
