import React from 'react'
import {IParticipantInformation} from '../../../../../types/types'

type ParticipantInfoCardProps = {
  participantInfo: IParticipantInformation
  triggerEdit: (step: number) => void
  isSummary: boolean
  hasPermission?: boolean
}

const ParticipantInfoCard: React.FC<ParticipantInfoCardProps> = ({
  participantInfo,
  triggerEdit,
  isSummary,
  hasPermission
}) => {
  const firstName =
    (participantInfo.usersPermissionsUserId && participantInfo.usersPermissionsUserId.firstName) ??
    participantInfo.firstName
  const lastName =
    (participantInfo.usersPermissionsUserId && participantInfo.usersPermissionsUserId.lastName) ??
    participantInfo.lastName

  const title =
    (participantInfo.usersPermissionsUserId && participantInfo.usersPermissionsUserId.jobTitle) ??
    participantInfo.title

  return (
    <>
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>Participant: {`${firstName} ${lastName} - ${title}`}</h3>
          </div>
          <div className='card-toolbar'>
            {hasPermission && (<>
              {isSummary ? <></> :
                  <i
                  className='btn btn-sm btn-icon btn-light-primary bi-pencil-square fs-4'
                  onClick={() => {
                    triggerEdit(2)
                  }}
                ></i>} 
            </>)}
          </div>
        </div>
        <div className='card-body'>
          <div className='container mt-5'>
            <div className='row'>
              <div className='col-md-4'>
              {participantInfo.photo ? (
                <img src={participantInfo.photo} alt='Profile Picture' className='img-fluid' />
              ) : (
                <div className='not-uploaded-label'>Not Uploaded</div>
              )}
                </div>
              <div className='col-md-8'>
                {/* bio is html string */}
                <div dangerouslySetInnerHTML={{__html: participantInfo.bio}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantInfoCard
