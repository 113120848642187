/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import Header from '../../common/components/Header'
import './styles/general-style.css'
import LoadingScreen from '../../common/components/LoadingScreen'
import {useMission} from './core/MissionProvider'

const MissionsLayout = () => {
  const {loadingScreen} = useMission()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      {loadingScreen && <LoadingScreen />}
      <Header isRepresentative={false}/>
      <div className='container'>
        <div className='d-flex flex-wrap'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export {MissionsLayout}
