import {Route, Routes} from 'react-router-dom'
import {MissionsLayout} from './MissionsLayout'
import MissionsForm from './components/MissionsForm'
import ViewMission from './pages/ViewMissions'
import RegisterParticipants from './pages/RegisterParticipant'

const MissionsPage = () => (
  <Routes>
    <Route element={<MissionsLayout />}>
      <Route path='/create' element={<MissionsForm title='Create Mission' action='add' />} />
      <Route
        path='/edit/:missionId'
        element={<MissionsForm title='Update Mission' action='edit' />}
      />
      <Route path='/view/:missionId' element={<ViewMission />} />
      <Route path='/view/:missionId/registration' element={<RegisterParticipants />} />
      <Route path='/view/:missionId/registration/:missionRegistrationId' element={<RegisterParticipants />} />
    </Route>
  </Routes>
)

export default MissionsPage
