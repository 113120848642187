import React, {useCallback, useEffect, useRef, useState} from 'react'
import { useLocation } from 'react-router-dom'
import axiosInstance from '../../../../utils/axios'
import {formatDateRangeToYYYYMMDDFormat, formatDateToYYYYMMDDFormat} from '../../../../utils/helper'
import Cards from '../../../common/components/Cards'
import {useAuth} from '../../auth'
import {useMission} from '../core/MissionProvider'
import {KTIcon} from '../../../../_metronic/helpers'
import {TRepView} from '../../../../types/types'

export const UpcomingMissions: React.FC<TRepView> = ({isRepresentativeView = false}) => {
  const {currentUser} = useAuth()
  const {missionsUpcoming, setMissionsUpcoming, setMissions, missions} = useMission()
  const upComingMissionsCount = useRef(0)
  const location = useLocation()
  const [loading, setLoading] = useState(true) // Loading state

  const fetchMissions = useCallback(
    async (url, setStateCallback, isRepresentativeView, CurrentRegisteredMissions) => {
      setLoading(true)  // Start loading
      try {
        const response = await axiosInstance.get(url)
        if (isRepresentativeView) {
          const registeredUrl = `/api/missions/registered/${currentUser?.id}`
          const registered = await axiosInstance.get(registeredUrl)
          const missionDataId = response.data.data.map((item) => item.id)
          const registeredId = registered.data.map((item) => item.id)
          const notRegistered = missionDataId.filter((id) => !registeredId.includes(id))
          const notRegMission = response.data.data.filter((item) => notRegistered.includes(item.id))
          const filteredData = notRegMission.filter((item) => {
            const regCloseDate = new Date(item.attributes.regCloseDate);
            const currentDate = new Date();
            regCloseDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            return regCloseDate >= currentDate;
          });
          upComingMissionsCount.current = filteredData.length
          setStateCallback(filteredData.map((r) => ({...r['attributes'], id: r.id})))
        } else {
          const filteredData = response.data.data.filter((item) => {
            const regCloseDate = new Date(item.attributes.regCloseDate);
            const currentDate = new Date();
            regCloseDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            return regCloseDate >= currentDate;
          });
          upComingMissionsCount.current = filteredData.length
          setStateCallback(filteredData.map((r) => ({...r['attributes'], id: r.id})))
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false) // Stop loading
      }
    },
    [currentUser?.id, isRepresentativeView]
  )

  useEffect(() => {
    if (!currentUser || !currentUser.id) {
      console.error('User data is not available.')
      return
    }
    if (missionsUpcoming === undefined) {
      let url = ''
      if (isRepresentativeView) {
        url = `/api/missions?populate=*,missionRegistrationsId,missionRegistrationsId.memberId.representativeId.userId&filters[$or][0][missionRegistrationsId][id][$null]=true&filters[$or][1][missionRegistrationsId][memberId][representativeId][userId][id][$ne]=${currentUser.id}&filters[$or][2][isActive][$eq]=${true}`
      } else {
        url = `/api/missions?sort=regCloseDate:asc&populate[createdByUser][fields]=email&populate=missionRegistrationsId&filters[isActive][$eq]=${true}`
      }
      fetchMissions(url, setMissionsUpcoming, isRepresentativeView, missions)
    }
  }, [currentUser, fetchMissions, missionsUpcoming, setMissionsUpcoming, setMissions])

  // Separate useEffect to handle location changes
  useEffect(() => {
    if (currentUser && missionsUpcoming !== undefined) {
      let url = ''
      if (isRepresentativeView) {
        url = `/api/missions?populate=*,missionRegistrationsId,missionRegistrationsId.memberId.representativeId.userId&filters[$or][0][missionRegistrationsId][id][$null]=true&filters[$or][1][missionRegistrationsId][memberId][representativeId][userId][id][$ne]=${currentUser.id}&filters[$or][2][isActive][$eq]=${true}`
      } else {
        url = `/api/missions?sort=regCloseDate:asc&populate[createdByUser][fields]=email&populate=missionRegistrationsId&filters[isActive][$eq]=${true}`
      }

      fetchMissions(url, setMissionsUpcoming, isRepresentativeView, missions)
    }
  }, [location, currentUser, isRepresentativeView, fetchMissions, setMissionsUpcoming])

  return (
    <div className='row mt-10'>
      <div className='d-flex align-items-start'>
        <KTIcon iconName='category' className='fs-1 mr-auto' />
        <div className='ml-auto' style={{marginLeft: '10px'}}>
          <h2>Upcoming Missions ({upComingMissionsCount.current})</h2>
        </div>
      </div>
      <div
        className={`d-flex flex-wrap ${
          missionsUpcoming === undefined ? `justify-content-center  p-10` : `justify-content-start`
        }`}
      >
        {loading ? (
          <div className='d-flex justify-content-center align-items-center w-100' style={{height: '200px'}}>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : missionsUpcoming !== undefined ? (
          <>
            {isRepresentativeView ? (
              <>
                {upComingMissionsCount.current > 0 ? (
                  missionsUpcoming?.map((mission, index) => {
                    return (
                      <Cards
                        id={mission.id}
                        title={mission.title}
                        duration={formatDateRangeToYYYYMMDDFormat(
                          mission.msStartDate,
                          mission.msEndDate
                        )}
                        registrationDeadline={formatDateToYYYYMMDDFormat(mission.regCloseDate)}
                        registrationStatus={mission.isActive}
                        refundCutDate={formatDateToYYYYMMDDFormat(mission.refundCutDate)}
                        inProgress={mission.inprogCount}
                        submitted={mission.submCount}
                        approved={mission.apprCount}
                        cardHeight={!currentUser?.isStaff ? '285px' : '380px'}
                        cardWidth='450px'
                        key={mission.id}
                        isRepresentativeView={!currentUser?.isStaff ? true : false}
                        isUpcoming={isRepresentativeView ? true : false}
                        timezone_abbrev={mission.timezone_abbrev}
                      />
                    )
                  })
                ) : (
                  <div
                    className='container d-flex justify-content-center align-items-center'
                    style={{height: '10vh'}}
                  >
                    <div className='alert alert-primary mt-10 mx-5' role='alert'>
                      <h3 className='mt-2'>No Upcoming Missions</h3>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {missionsUpcoming?.length === 0 || missionsUpcoming === undefined ? (
                  <div
                    className='container d-flex justify-content-center align-items-center'
                    style={{height: '10vh'}}
                  >
                    <div className='alert alert-primary mt-10 mx-5' role='alert'>
                      <h3 className='mt-2'>No Upcoming Missions</h3>
                    </div>
                  </div>
                ) : (
                  <>
                    {missionsUpcoming?.map((mission, index) => {
                      return (
                        <Cards
                          id={mission.id}
                          title={mission.title}
                          duration={formatDateRangeToYYYYMMDDFormat(
                            mission.msStartDate,
                            mission.msEndDate
                          )}
                          registrationDeadline={formatDateToYYYYMMDDFormat(mission.regCloseDate)}
                          registrationStatus={mission.isActive}
                          refundCutDate={formatDateToYYYYMMDDFormat(mission.refundCutDate)}
                          inProgress={mission.inprogCount}
                          submitted={mission.submCount}
                          approved={mission.apprCount}
                          cardHeight={!currentUser?.isStaff ? '285px' : '380px'}
                          cardWidth='450px'
                          key={mission.id}
                          isRepresentativeView={!currentUser?.isStaff ? true : false}
                          isUpcoming={isRepresentativeView ? true : false}
                          missionDetails={mission}
                          timezone_abbrev={mission.timezone_abbrev}
                        />
                      )
                    })}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <h4>No Mission Found.</h4>
          </>
        )}
      </div>
    </div>
  )
}

export default UpcomingMissions
