import {createTheme, ThemeProvider} from '@mui/material'
import {Outlet} from 'react-router-dom'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {LayoutProvider} from '../_metronic/layout/core'
import {AuthInit} from './modules/auth'
import {MissionProvider} from './modules/mission/core/MissionProvider'

const theme = createTheme({
  typography: {
    fontFamily: ['"Helvetica Neue"', 'sans-serif'].join(','),
  },
})

const App = () => {
  return (
    <LayoutProvider>
      <ThemeProvider theme={theme}>
        <AuthInit>
          <MissionProvider>
            <Outlet />
            <MasterInit />
          </MissionProvider>
        </AuthInit>
      </ThemeProvider>
    </LayoutProvider>
  )
}

export {App}
