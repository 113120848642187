import { AuthModel, UserModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'currentUser'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}


const getToken = (): string | null => {
  const item = localStorage.getItem('usasean-pkey');
  if (item) {
    const parsedItem = JSON.parse(item);
    const now = new Date().getTime();
    
    // Set the expiration time to one day (24 hours) from now
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    const expirationTime = now + oneDayInMilliseconds;

    if (parsedItem.expiration && parsedItem.expiration < now) {
      localStorage.removeItem('usasean-pkey'); // Remove the item if it has expired
      window.location.replace('/');
      return null;
    }
    
    // Update the expiration time in the parsedItem object
    parsedItem.expiration = expirationTime;

    // Save the updated parsedItem back to localStorage
    localStorage.setItem('usasean-pkey', JSON.stringify(parsedItem));

    return parsedItem.token;
  }
  
  localStorage.removeItem('currentUser')
  return null;
}

const getCurrentUser = (): UserModel | null => {
  const item = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (item) {
    const parsedItem = JSON.parse(item);
    const now = new Date().getTime();
    if (parsedItem.expiration && parsedItem.expiration < now) {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY); // Remove the item if it has expired
      return null;
    }
    return parsedItem;
  }
  return null;
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentMemberData')
    localStorage.removeItem('usasean-pkey')
    localStorage.removeItem('userId')
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}



export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const token = getToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export { AUTH_LOCAL_STORAGE_KEY, getAuth, getToken, getCurrentUser, removeAuth, setAuth }

