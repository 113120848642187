import React, { useEffect, useState } from 'react'
import { TCompanyProfile } from '../../../../../types/types'
import axiosInstance from '../../../../../utils/axios'

type CompanyProfileCardProps = {
  companyProfileInfo: TCompanyProfile
  triggerEdit: (step: number) => void
  isSummary: boolean
  hasPermission?: boolean
}

const CompanyProfileCard: React.FC<CompanyProfileCardProps> = ({ companyProfileInfo, triggerEdit, isSummary, hasPermission }) => {
  const [member, setMember] = useState()
  useEffect(() => {
    const fetch = async () => {
      await axiosInstance(`api/mission-registrations/${companyProfileInfo.id}?populate=memberId.logo`).then((res) => {
        if (res.data.data) {
          const mregister = res.data.data
          const member = mregister.attributes.memberId.data
          if (member) {
            setMember(member)
          }
        }
      })
    }
    fetch()
  }, [companyProfileInfo.id])
  //@ts-ignore
  const logoUrl = companyProfileInfo.compLogo || (member?.attributes?.logo?.data?.attributes?.url);

  return (
    <div className='card card-custom gutter-b'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='card-label'>
            Company Display Name: {companyProfileInfo.compDisplayName}
          </h3>
        </div>
        <div className='card-toolbar'>
          {hasPermission && (!isSummary && (
            <i className='btn btn-sm btn-icon btn-light-primary bi-pencil-square fs-4' onClick={() => triggerEdit(4)}></i>
          ))}
        </div>
      </div>
      <div className='card-body'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-md-4'>
            {logoUrl ? (
              <img src={logoUrl} alt='Company Picture' className='img-fluid' />
            ) : (
                <div className='not-uploaded-label'>Not Uploaded</div>
              )}
            </div>
            <div className='col-md-8'>
            {companyProfileInfo.referredName &&
            <h4 className='card-label'>
            Mission Reference Name: {companyProfileInfo.referredName}
            </h4>}
            {companyProfileInfo.participationPublic !== null ? <h4 className='card-label'>
            Public Participation: {companyProfileInfo.participationPublic ? 'Yes': 'No'}
            </h4> : <></>}
            <h4 className='card-label'>
            Company Bio:
            </h4>
              <div dangerouslySetInnerHTML={{ __html: companyProfileInfo.compBio }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyProfileCard
