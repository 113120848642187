import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { TMembers, TMission, TMissionRegistrations, TUser } from '../../../../types/types'
import axiosInstance from '../../../../utils/axios'
import { useAuth } from '../../auth'

type MissionContextProps = {
  missions: TMission[]
  missionsUpcoming: TMission[] | undefined
  setMissions: Dispatch<SetStateAction<TMission[]>>
  setMissionsUpcoming: Dispatch<SetStateAction<TMission[] | undefined>>
  otherMissions: TMission[]
  setOtherMissions: Dispatch<SetStateAction<TMission[]>>
  fetchMissionDetails: (id: string) => TMission | undefined
  selectedMission: TMission | undefined
  setSelectedMission: Dispatch<SetStateAction<TMission | undefined>>
  loadingScreen: boolean
  setLoadingScreen: Dispatch<SetStateAction<boolean>>
  handlePageChange: (pageNumber: string) => void
  totalPages: number
  setTotalPages: Dispatch<SetStateAction<number>>
  primaryParticipant: any
  setPrimaryParticipant: Dispatch<SetStateAction<any>>
  currentMember: TMembers | undefined
  setCurrentMember: Dispatch<SetStateAction<TMembers | undefined>>
  fetchMemberBillingInfo: (memberId: number) => {}
  fetchParticipantMemberInformation: (representativeId:number) => {}
  memberParticipants: TUser[]
  addMemberParticipants: Dispatch<SetStateAction<TUser[]>>
  fetchMemberRepresentatives: (memberId: number) => {} | undefined
  currentMissionRegistrationId: number
  setCurrentMissionRegistrationId: Dispatch<SetStateAction<number>>
  setCsrBoolean: Dispatch<SetStateAction<boolean>>
  CsrBoolean: boolean
  currentMissionRegistration: TMissionRegistrations | undefined
  setCurrentMissionRegistration: Dispatch<SetStateAction<TMissionRegistrations | undefined>>
  fetchMissionRegistrationsByCompanyDisplayName: (missionId: string, registrationId: string ,compDisplayName: string) => void
}

const initMissionPropsState: MissionContextProps = {
  missions: [],
  setMissions: () => {},
  missionsUpcoming: [],
  setMissionsUpcoming: () => {},
  otherMissions: [],
  setOtherMissions: () => {},
  fetchMissionDetails: (id: string): TMission | undefined => undefined,
  selectedMission: undefined,
  setSelectedMission: () => {},
  loadingScreen: false,
  setLoadingScreen: () => {},
  handlePageChange: (pageNumber: string) => {},
  totalPages: 1,
  setTotalPages: () => {},
  primaryParticipant: {},
  setPrimaryParticipant: () => Promise<{}>,
  currentMember: undefined,
  setCurrentMember: () => {},
  fetchMemberBillingInfo: (memberId: number) => Promise<{}>,
  fetchParticipantMemberInformation: (representativeId:number) => Promise<{}>,
  memberParticipants: [],
  addMemberParticipants: () => {},
  fetchMemberRepresentatives: (memberId: number) => Promise<undefined>,
  currentMissionRegistrationId: 0,
  setCurrentMissionRegistrationId: () => {},
  currentMissionRegistration: undefined,
  setCurrentMissionRegistration: () => {},
  setCsrBoolean: () => {},
  CsrBoolean: false,
  fetchMissionRegistrationsByCompanyDisplayName: (missionId: string, registrationId: string, compDisplayName: string) => Promise<void>
}

const MissionContext = createContext<MissionContextProps>(initMissionPropsState)

const useMission = () => {
  return useContext(MissionContext)
}

const MissionProvider: FC<WithChildren> = ({children}) => {
  const [missionsUpcoming, setMissionsUpcoming] = useState<TMission[] | undefined>(undefined)
  const [missions, setMissions] = useState<TMission[]>([])
  const [selectedMission, setSelectedMission] = useState<TMission | undefined>()
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
  const [otherMissions, setOtherMissions] = useState<TMission[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [primaryParticipant, setPrimaryParticipant] = useState<any>({})
  const [currentMember, setCurrentMember] = useState<TMembers | undefined>()
  const [memberParticipants,addMemberParticipants] = useState<TUser[]>([]);
  const [currentMissionRegistrationId, setCurrentMissionRegistrationId] = useState<number>(0);
  const [currentMissionRegistration,setCurrentMissionRegistration] = useState<TMissionRegistrations | undefined>()
  const [CsrBoolean, setCsrBoolean] = useState<boolean>(false)
  const { currentUser } = useAuth();

  const fetchMissionDetails = (id: string): TMission | undefined => {
    return missions.find((mission) => mission.id == id)
  }

  const handlePageChange = async (pageNumber: string) => {
    const pageSize = 9
    const url = `/api/missions?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}`
    const response = await axiosInstance.get(url)
    setMissions(response.data.data.map((r) => ({...r['attributes'], id: r.id})))
    setTotalPages(response.data.meta.pagination.pageCount)
  }

  const fetchMemberBillingInfo = async (memberId:number): Promise<void> => {
    const memberResponse = await axiosInstance.get(`/api/billing-infos/?filters[memberInfo][id]=${memberId}`)
    const primaryContact = memberResponse.data.data.length !== 0 ? {
      ...memberResponse.data.data[0].attributes,
      id: memberResponse.data.data[0].attributes.userId,
      billingInfoId: memberResponse.data.data[0].id,
    } : null
    return primaryContact
  }

  const fetchParticipantMemberInformation = async (representativeId: number) => {
      const participantMemberdata = await axiosInstance.get(
        `/api/members?filters[representativeId][userId]=${representativeId}`
      )
      setCurrentMember({
        ...participantMemberdata.data.data[0].attributes,
        id: participantMemberdata.data.data[0].id,
      })
  }

  const fetchMemberRepresentatives = async (memberId:number) => {
   const memberUsers = await axiosInstance.get(`/api/users?filters[userRepresentativeId][memberId][id]=${memberId}&populate=missionMemparticipantUserId`);
   addMemberParticipants(memberUsers.data)
  }

  const fetchMissionRegistrationsByCompanyDisplayName = (missionId: string, registrationId: string, compDisplayName: string) => {
    if (registrationId) {
      axiosInstance.get(`/api/mission-registrations/${registrationId}`)
        .then((response) => {
          if (response && response.data) {
            const specificRegistration = response.data.data;
            setCurrentMissionRegistration((prev) => ({
              ...prev,
              ...specificRegistration.attributes,
              id: specificRegistration.id,
            }));
          } else {
            setCurrentMissionRegistration(undefined);
          }
        })
        .catch((error) => {
          console.error("Error fetching specific mission registration:", error);
          setCurrentMissionRegistration(undefined);
        });
    } else {
      axiosInstance.get(`/api/missions/${missionId}?populate=missionRegistrationsId.missionMemparticipantsRegistrationId,missionRegistrationsId.memMissionTalkingPoints,missionRegistrationsId.compLogo&filters[missionRegistrationsId][compDisplayName]=${compDisplayName}`)
        .then((response) => {
          if (response && response.data.data.attributes.missionRegistrationsId.data.length > 0) {
            const missionRegistrations = response.data.data.attributes.missionRegistrationsId.data;
            const specificRegistration = missionRegistrations.find(registration => registration.attributes.compDisplayName === compDisplayName);
            if (specificRegistration) {
              setCurrentMissionRegistration((prev) => ({
                ...prev,
                ...specificRegistration.attributes,
                id: specificRegistration.id,
              }));
            } else {
              setCurrentMissionRegistration(undefined);
            }
          } else {
            setCurrentMissionRegistration(undefined);
          }
        })
        .catch((error) => {
          console.error("Error fetching mission registrations:", error);
          setCurrentMissionRegistration(undefined);
        });
    }
  }
  

  return (
    <MissionContext.Provider
      value={{
        missions,
        setMissions,
        missionsUpcoming,
        setMissionsUpcoming,
        fetchMissionDetails,
        selectedMission,
        setSelectedMission,
        loadingScreen,
        setLoadingScreen,
        otherMissions,
        setOtherMissions,
        handlePageChange,
        totalPages,
        setTotalPages,
        primaryParticipant,
        setPrimaryParticipant,
        currentMember,
        setCurrentMember,
        fetchMemberBillingInfo,
        fetchParticipantMemberInformation,
        memberParticipants,
        addMemberParticipants,
        fetchMemberRepresentatives,
        currentMissionRegistrationId,
        setCurrentMissionRegistrationId,
        currentMissionRegistration,
        setCurrentMissionRegistration,
        setCsrBoolean,
        CsrBoolean,
        fetchMissionRegistrationsByCompanyDisplayName
      }}
    >
      {children}
    </MissionContext.Provider>
  )
}

export { MissionProvider, useMission }

