import {FC, useCallback, useState, useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {useNavigate, useParams} from 'react-router-dom'
import {TMission} from '../../../../types/types'
import axiosInstance from '../../../../utils/axios'
import {
  countDaysLeft,
  formatDateRangeToYYYYMMDDFormat,
  formatDateToYYYYMMDDFormat,
} from '../../../../utils/helper'
import {useMission} from '../core/MissionProvider'
import MissionMeetings from '../components/MissionMeetings'
import ParticipantsTable from '../components/ParticipantsTable'
import ExportOptions from '../components/Export'
import LogsForm from '../components/Logs'
import LogoForm from '../components/Logo'
import ProfileForm from '../components/Profiles'
import {KTIcon} from '../../../../_metronic/helpers'
import {Button} from '@mui/material'
import {useAuth} from '../../../modules/auth'
const ViewMission: FC = () => {
  const {missionId} = useParams()
  const [activeTab, setActiveTab] = useState('registrations')
  const [mission, setMission] = useState<TMission | null>(null)
  const navigate = useNavigate()
  const {setLoadingScreen, setSelectedMission} = useMission()
  const {currentUser} = useAuth()
  const [hasPermission, setHasPermission] = useState(false)

  useEffect(() => {
    axiosInstance
      .get(`/api/mission-managers?populate=*&filters[user_managers][id]=${currentUser?.id}`)
      .then((response) => {
        if(process.env.isPermissionEnabled ==='true'){
        const missionManagers = response.data.data[0].attributes.missionRelation.data;
        if (missionManagers.length === 0) {
          setHasPermission(false);
        } else {
          const hasMissionPermission = missionManagers.some((manager) => {
            return manager.id === parseInt(missionId ? missionId : '0');
          });
  
          setHasPermission(hasMissionPermission);
        }
      }else{
        setHasPermission(true);
      }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentUser, missionId]);

  const fetchData = useCallback(async () => {
    if (!missionId || mission) {
      return
    }

    try {
      const response = await axiosInstance.get(`/api/missions/${missionId}?populate=missionMemparticipant.registrationId,missionRegistrationsId`)
      const missionData = response.data.data.attributes
      setMission(missionData)
    } catch (error) {
      console.error('Error fetching mission data:', error)
    } finally {
      setLoadingScreen(false)
    }
  }, [missionId, mission, setLoadingScreen])

  fetchData()

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey)
  }
  return (
    mission && (
      <Container className='mt-20 mb-20'>
        <a href='/' className='btn btn-icon btn-default mr-5'>
          <KTIcon className='fs-3 text-default mr-5' iconName='arrow-left' />
          <span style={{marginRight: '5px'}} className='fs-12 text-primary'>
            {' '}
            back
          </span>
        </a>
        <Row className='mt-5 mb-20'>
          <Col>
            <div className='d-block'>
              <h1> {mission.title} </h1>
              <span>
                {' '}
                <strong> Dates: </strong>{' '}
                {formatDateRangeToYYYYMMDDFormat(mission.msStartDate, mission.msEndDate)}{' '}{mission.timezone_abbrev}
              </span>
            </div>
          </Col>
        </Row>
        <Row className='mt-10'>
          <Col>
            <div className='d-block missionDetails'>
              <p>
              {' '}
              <strong> Status:</strong>{' '}
              <span className={mission.isActive ? 'active' : ''}>
                {mission.isActive && 'Active'}
              </span>
            </p>
            <p>
              {' '}
              <strong> Registration Deadline:</strong>{' '}
              {formatDateToYYYYMMDDFormat(mission.regCloseDate)} {' '}{mission.timezone_abbrev} -
              <strong> {countDaysLeft(mission.regCloseDate)} </strong>
            </p>
            <p>
              {' '}
              <strong> Refund cut-off date:</strong>{' '}
              {formatDateToYYYYMMDDFormat(mission.refundCutDate)}{' '}{mission.timezone_abbrev}
            </p>
            <p>
              {' '}
              <strong> Participant Limit (per registration):</strong> {mission.participant}
            </p>
            <p>
            {' '}
            <strong>Companies Registered:</strong>{' '}
             {/* @ts-ignore */}
            {mission.missionRegistrationsId ? mission.missionRegistrationsId.data.reduce(
                  (count, registration) =>
                    count + (registration.attributes.isCancelled ? 0 : 1),
                  0
                )
              : 0}
          </p>
          <p>
            {' '}
            <strong>Participants Registered:</strong>{' '}
             {/* @ts-ignore */}
            {mission.missionMemparticipant? mission.missionMemparticipant.data.reduce(
                  (count, participant) =>
                    count +
                    (participant.attributes.registrationId?.data?.attributes.isCancelled
                      ? 0
                      : 1),
                  0
                )
              : 0}
          </p>
            </div>
          </Col>
          {/* <Col>
            <Row className='missionStats'>
              <Col sm={12} md={3}>
                <div className='d-flex flex-column align-content-center align-items-center inprogress'>
                  <span className='statNumber'> {mission.inprogCount ?? 0} </span>
                  <span className='statStatus'> In Progress </span>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className='d-flex flex-column align-content-center align-items-center submitted'>
                  <span className='statNumber'> {mission.submCount ?? 0} </span>
                  <span className='statStatus'> Submitted </span>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className='d-flex flex-column align-content-center align-items-center approved'>
                  <span className='statNumber'> {mission.apprCount ?? 0} </span>
                  <span className='statStatus'> Approved </span>
                </div>
              </Col>
            </Row>
          </Col> */}
        </Row>
        <Row className='mt-5'>
          <Col>
            {hasPermission && (
              <>
                <Button
                  className='btn btn-secondary missionButton'
                  onClick={() => navigate(`/missions/edit/${missionId}`)}
                  style={{
                    padding: '5px 0',
                  }}
                >
                  {' '}
                  Edit{' '}
                </Button>{' '}
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setSelectedMission(mission)
                    navigate(`/missions/view/${missionId}/registration`)
                  }}
                >
                  Initiate Member Registration
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row className='mt-10'>
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={handleTabSelect}
            id='uncontrolled-tab-example'
            className='mb-3 custom-tabs'
          >
            <Tab
              eventKey='registrations'
              title={
                <span className='mr-2'>
                  Registrations
                  <i className='bi bi-newspaper icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'registrations' && missionId && (
                <ParticipantsTable
                  missionId={missionId}
                  mission={mission}
                  hasPermission={hasPermission}
                  registrationStatus={mission.isActive}
                />
              )}
            </Tab>
            <Tab
              eventKey='meetings'
              title={
                <span className='mr-2'>
                  Meetings
                  <i className='bi bi-people-fill icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'meetings' && missionId && <MissionMeetings missionId={missionId} hasPermission={hasPermission}/>}
            </Tab>
            <Tab
              eventKey='export'
              title={
                <span className='mr-2'>
                  Export
                  <i className='bi bi-file-earmark-text icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'export' && <ExportOptions />}
            </Tab>
          <Tab
              eventKey='Logs'
              title={
                <span className='mr-2'>
                  Logs 
                  <i className='bi bi-card-list icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'Logs' && missionId && <LogsForm missionId={missionId}/>}
            </Tab>
            <Tab
              eventKey='Logo'
              title={
                <span className='mr-2'>
                  Logo 
                  <i className='bi bi-aspect-ratio icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'Logo' && missionId && <LogoForm missionId={missionId}/>}
            </Tab>
            <Tab
              eventKey='Profile'
              title={
                <span className='mr-2'>
                  Profiles
                  <i className='bi bi-aspect-ratio icons' />{' '}
                </span>
              }
              className='tab-headers'
            >
              {activeTab === 'Profile' && missionId && <ProfileForm missionId={missionId}/>}
            </Tab>
          </Tabs>
        </Row>
      </Container>
    )
  )
}

export default ViewMission
