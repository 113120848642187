import {useEffect, useRef, useState} from 'react'
import {Pagination} from 'react-bootstrap'
import axiosInstance from '../../../../utils/axios'
import {formatDateRange, formatDateRangeToYYYYMMDDFormat} from '../../../../utils/helper'
import Cards from '../../../common/components/Cards'
import {useMission} from '../core/MissionProvider'
import {KTIcon} from '../../../../_metronic/helpers'

export const OtherMissions: React.FC = ({}) => {
  const {otherMissions, setOtherMissions, setLoadingScreen} = useMission()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const otherMissionsCount = useRef(0)

  useEffect(() => {
    // Fetch missions based on currentPage and itemsPerPage
    const fetchMissions = async () => {
      try {
        setLoadingScreen(true)
        const pageSize = 9
        const url = `/api/missions?pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}&sort[0]=isActive:desc&sort[1]=msStartDate:desc`
        const response = await axiosInstance.get(url)
        otherMissionsCount.current = response.data.meta.pagination.total
        setOtherMissions(response.data.data.map((r) => ({...r['attributes'], id: r.id})))
        setTotalPages(response.data.meta.pagination.pageCount)
      } catch (error) {
        console.error('Error fetching missions:', error)
      } finally {
        setLoadingScreen(false)
      }
    }

    fetchMissions()
  }, [currentPage, setOtherMissions])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }
 
  return (
    <div className='row mt-10'>
      <div className='d-flex flex-wrap justify-content-start mb-4'>
          <KTIcon iconName='flag' className='fs-1 mr-auto' />
          <div className="ml-auto" style= {{marginLeft:"10px"}}>
              <h5>Other Upcoming Missions ({otherMissionsCount.current})</h5>
              {/* <span style={{fontWeight: '500'}}>managed by you</span> */}
          </div>
      </div>
      <div className='d-flex flex-wrap justify-content-start'>
        {otherMissions.length > 0 ?
          otherMissions
          .sort((a, b) => (a.isActive === b.isActive) ? 0 : a.isActive ? -1 : 1)
          .map((mission, index) => {
            if (mission.isActive) {
                return (
                  <Cards
                    id={mission.id}
                    title={mission.title}
                    duration={formatDateRangeToYYYYMMDDFormat(mission.msStartDate, mission.msEndDate)}
                    registrationDeadline={mission.regCloseDate}
                    registrationStatus={mission.isActive}
                    refundCutDate={mission.refundCutDate}
                    inProgress={mission.inprogCount}
                    submitted={mission.submCount}
                    approved={mission.apprCount}
                    cardHeight='250px'
                    cardWidth='410px'
                    key={mission.id}
                    isOtherMission
                    timezone_abbrev={mission.timezone_abbrev}
                  />
                );
            }
            else{
              return (
                <Cards
                  id={mission.id}
                  title={mission.title}
                  duration={formatDateRangeToYYYYMMDDFormat(mission.msStartDate, mission.msEndDate)}
                  registrationDeadline={mission.regCloseDate}
                  registrationStatus={mission.isActive}
                  refundCutDate={mission.refundCutDate}
                  inProgress={mission.inprogCount}
                  submitted={mission.submCount}
                  approved={mission.apprCount}
                  cardHeight='250px'
                  cardWidth='410px'
                  key={mission.id}
                  isOtherMission
                  timezone_abbrev={mission.timezone_abbrev}
                />
              );
            }
          })
          :
          <div className="container d-flex justify-content-center align-items-center" style={{height: "10vh"}}>
                  <div className="alert alert-primary mt-10 mx-5" role="alert">
                    <h3 className='mt-2'>No Other Upcoming Missions</h3>
                  </div>
               </div>
          }
      </div>
      <div className='mt-10'>
        {otherMissions.length > 0 && (
          <Pagination>
            <Pagination.Prev onClick={handlePreviousPage} disabled={currentPage === 1} />
            <Pagination>
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
            <Pagination.Next onClick={handleNextPage} disabled={currentPage === totalPages} />
          </Pagination>
        )}
      </div>
    </div>
  )
}

export default OtherMissions
