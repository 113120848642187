export const formatDateRange = (startDate: string, endDate: string): string => {
    return `${startDate} - ${endDate}`;
}

export const formatDateRangeToYYYYMMDDFormat = (start: string, end: string): string => {
    const startDateTimestamp = Date.parse(start);
    const endDateTimestamp = Date.parse(end);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    };

    const formattedStartDate = new Intl.DateTimeFormat('en-US', options).format(startDateTimestamp);
    const formattedEndDate = new Intl.DateTimeFormat('en-US', options).format(endDateTimestamp);

    return `${formattedStartDate} - ${formattedEndDate}`;
}

export const formatDateToYYYYMMDDFormat = (date: string): string => {
    const inputDateTimestamp = Date.parse(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDateTimestamp);

    return `${formattedDate}`;
}

export const countDaysLeft = (givenDateString: string): string => {
    const givenDateTimestamp = Date.parse(givenDateString);
    const todayTimestamp = Date.parse(new Date().toISOString().split('T')[0]); // Get timestamp for today's date in UTC
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const daysLeft = Math.ceil((givenDateTimestamp - todayTimestamp) / oneDayInMilliseconds);

    if (isNaN(givenDateTimestamp) || daysLeft < 0) {
        return "Over Due";
    } else if (daysLeft === 0) {
        return "Today";
    } else if (daysLeft === 1) {
        return "Tomorrow";
    } else {
        return `${daysLeft} days left`;
    }
}

export const isWayPastCOB = (COBDateString: string | undefined, createdAt: string | undefined): boolean => {
    if (COBDateString === undefined) {
        return false;
    }

    const currentDateTimestamp = createdAt !== undefined ? Date.parse(createdAt) : Date.parse(new Date().toISOString().split('T')[0]);
    const COBDateTimestamp = Date.parse(COBDateString);
    const nextDayCOBTimestamp = COBDateTimestamp + 1000 * 60 * 60 * 24; // Add one day in milliseconds

    return currentDateTimestamp > nextDayCOBTimestamp;
};
