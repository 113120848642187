import {Box, Container, Step, StepLabel, Stepper} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../auth'
import CompanyProfile from '../components/RegisterParticipants/CompanyProfile'
import MemberBillingInformation from '../components/RegisterParticipants/MemberBillingInformation'
import ParticipantInformation from '../components/RegisterParticipants/ParticipantInformation'
import TalkingPoints from '../components/RegisterParticipants/TalkingPoints'
import {useMission} from '../core/MissionProvider'
import CSRStep from '../components/RegisterParticipants/CSRStep'
import axiosInstance from '../../../../utils/axios'

const steps = [
  'Billing Information',
  'Participant Information',
  'Talking Points',
  'Company Profile',
  'CSR Materials',
]

const ViewRepresentativeMission: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const {missionId, missionRegistrationId} = useParams()
  const {currentUser} = useAuth()
  const {
    fetchMissionRegistrationsByCompanyDisplayName,
    fetchMemberBillingInfo,
    fetchParticipantMemberInformation,
    fetchMemberRepresentatives,
    currentMember,
    currentMissionRegistration,
    setLoadingScreen,
    setCsrBoolean,
    CsrBoolean,
  } = useMission()

  useEffect(() => {
    const fetchRegistrationData = async () => {
      setLoadingScreen(true)
      // Only Gets the current user member data if the user is not a staff
      if (currentUser && currentUser.id && !currentUser?.isStaff) {
        setLoadingScreen(true)
        try {
          await Promise.all([fetchParticipantMemberInformation(currentUser.id)]).catch(() =>
            setLoadingScreen(false)
          )
          setLoadingScreen(false)
        } catch (error) {
          setLoadingScreen(false)
        }
      }else{
        setLoadingScreen(false)
      }
    }

    fetchRegistrationData()
  }, [currentUser])

  useEffect(() => {
    if (currentMember) {
      if(currentMember.id !== null){
        fetchMemberRepresentatives(currentMember.id)
      }else{
        console.log("Register Participant View", "No CurrentMember ID found")
      }
    }
  }, [currentMember])

  useEffect(() => {
    if (missionId) {
      axiosInstance.get(`/api/missions/${missionId}`).then((response) => {
        setCsrBoolean(response.data.data.attributes.hasCsr)
      })
    }
  }, [missionId])

  //@TODO add checker if staff
  useEffect(() => {
    if (!currentMissionRegistration && currentMember && missionId /* && !currentUser?.isStaff */) {
      fetchMissionRegistrationsByCompanyDisplayName(missionId,missionRegistrationId? missionRegistrationId:'' ,currentMember.displayName)
    }
  }, [currentUser, currentMissionRegistration, currentMember, missionId])

  const getStepContent = (stepIndex: number): JSX.Element => {
    switch (stepIndex) {
      case 0:
        return <MemberBillingInformation setActiveStep={setActiveStep} activeStep={stepIndex} isRepresentativeView={true}/>
      case 1:
        return <ParticipantInformation setActiveStep={setActiveStep} activeStep={stepIndex} />
      case 2:
        return (
          <TalkingPoints
            missionId={missionId ?? ''}
            setActiveStep={setActiveStep}
            activeStep={stepIndex}
          />
        )
      case 3:
        return <CompanyProfile setActiveStep={setActiveStep} activeStep={stepIndex} CsrBoolean={CsrBoolean} isRepresentativeView={true} />
      case 4:
        return <CSRStep setActiveStep={setActiveStep} activeStep={stepIndex} isRepresentativeView={true} />

      default:
        return <MemberBillingInformation setActiveStep={setActiveStep} activeStep={stepIndex} />
    }
  }

  const handleStepClick = (stepIndex: number) => {
    if(missionRegistrationId){
    setActiveStep(stepIndex);
    }
  };

  const stepsWithoutCsr = !CsrBoolean ? steps.filter((step) => step !== 'CSR Materials') : steps

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 10,
      }}
    >
      <Container
        style={{
          borderRadius: '20px',
          backgroundColor: '#ffffff',
          padding: '20px',
          overflow: 'auto',
        }}
      >
        {' '}
        <Stepper activeStep={activeStep} alternativeLabel>
          {stepsWithoutCsr.map((label, index) => (
            <Step key={label}>
            <StepLabel onClick={() => handleStepClick(index)}>{label}</StepLabel>
          </Step>
          ))}
        </Stepper>
        <div
          style={{
            marginTop: '50px',
          }}
        >
          {getStepContent(activeStep)}
        </div>
      </Container>
    </Box>
  )
}

export default ViewRepresentativeMission
