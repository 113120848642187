import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  createFilterOptions,
} from '@mui/material'
import {omit} from 'rambda'
import {ChangeEvent, FormEvent, useEffect, useState, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {IStepperProps, TMembers, TMissionRegistrations, TUser} from '../../../../../types/types'
import axiosInstance from '../../../../../utils/axios'
import {useMission} from '../../core/MissionProvider'
import MySwal from '../../../../../utils/sweetAlert'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
//@ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {useAuth} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import isValidPhoneNumber from 'libphonenumber-js';
import MuiPhoneNumber from 'material-ui-phone-number'

const filter = createFilterOptions<string>()

const memberBillingDefaults: TMissionRegistrations = {
  id: '',
  compDisplayName: '',
  compLogo: null,
  compBio: '',
  isSubmitted: false,
  isCancelled: false,
  createdByUser: null,
  billingRepName: '',
  billingRepTitle: '',
  billingRepCompany: '',
  billingAddress: '',
  billingPhoneNum: '',
  billingEmailAdd: '',
  billingSpecIns: '',
  billingStatus: 2, //!TODO confirm if initial should be 1(not complete) and upon updated by member, should be 2
  participantStatus: 0,
  talkpointsStatus: 0,
  companyProfileStatus: 0,
  memberId: 0,
  missionId: 0,
  billingInfoId: null,
  submittedCSRStatus: 0,
  representativeName: '',
  billingStatusEx: 0,
  notes: '',
  createdAt:'',
  estimatedInvestments: '',
  referredName: '',
  participationPublic: null,
}

const MemberBillingInformation: React.FC<IStepperProps> = ({
  activeStep,
  setActiveStep,
  isRepresentativeView,
}) => {
  const {
    currentMember,
    addMemberParticipants,
    memberParticipants,
    currentMissionRegistration,
    setCurrentMissionRegistration,
    setLoadingScreen,
    fetchMemberBillingInfo,
    fetchParticipantMemberInformation,
    fetchMemberRepresentatives,
    selectedMission,
    loadingScreen
  } = useMission()

  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');
  const {currentUser, getCurrentMemberData} = useAuth()
  const {missionId, missionRegistrationId} = useParams()
  const [specialInstructionsCharCount, setSpecialInstructionsCharCount] = useState<number>(0)
  const [members, setMembers] = useState<TMembers[]>([])
  const [currentPrimaryContact, setCurrentPrimaryContact] = useState<any>({
    billingAddress: '',
    company: '',
    email: '',
    fullName: '',
    title: '',
    userId: 0,
    billingInfoId: null,
    phoneNumber: ''
  })
  const [currentPrimaryContactId, setCurrentPrimaryContactId] = useState<number>(0)
  const [currentPrimaryRep, setCurrentPrimaryRep] = useState<any>({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isMemberDropdownDisabled, setIsMemberDropdownDisabled] = useState<boolean>(false)
  const [haveBillingInfo, setHaveBillingInfo] = useState<boolean>(false)
  const [haveOpened, setHaveOpened] = useState<boolean>(false)
  const [selectedMember, setSelectedMember] = useState<TMembers>({
    id: null,
    memberId: '',
    displayName: '',
    isActive: true,
    memberSince: '',
    memberBio: '',
    logo: '',
    referredName: '',
    participationPublic: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  type TMissionRegistrationswithError = TMissionRegistrations & {
    phoneNumError?: string
    emailError?: string
  }

  const [formData, setFormData] = useState<TMissionRegistrationswithError>(memberBillingDefaults)
  const [initialFormData, setInitialFormData] = useState<TMissionRegistrationswithError>(memberBillingDefaults)

  const fetchBillingInfoByCurrentUserMember = useCallback(
    async (
      memberId,
      fetchCallback,
      setStateCallback,
      setFormData,
      currentUser,
      currentPrimaryContact,
      selectedMember,
      missionId,
      fetchParticipantMemberInformation
    ) => {
      let primaryContact = await (fetchCallback(memberId) as Promise<void>) // Explicitly annotate as Promise
      //@ts-ignore
      if(primaryContact.billingInfoId){
        setHaveBillingInfo(true)
      }else{
        setHaveBillingInfo(false)
      }
      //@ts-ignore
      setCurrentPrimaryContactId(primaryContact.billingInfoId)
      setStateCallback(primaryContact)
      fetchParticipantMemberInformation(currentUser.id)
      setFormData((prevFormData) => ({
        ...prevFormData,
        createdByUser: currentUser?.id,
        billingAddress: currentPrimaryContact.billingAddress,
        compDisplayName: currentPrimaryContact.company,
        billingEmailAdd: currentPrimaryContact.email,
        billingRepName: currentPrimaryContact.fullName,
        billingPhoneNum: currentPrimaryContact.phoneNumber,
        billingSpecIns: currentPrimaryContact.specialInstructions,
        billingRepCompany: currentPrimaryContact.company,
        billingRepTitle: currentPrimaryContact.title,
        memberId: selectedMember.id,
        missionId: parseInt(missionId),
        billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
        billingStatusEx: 2,
      }))
      setInitialFormData((prevFormData) => ({
        ...prevFormData,
        createdByUser: currentUser?.id,
        billingAddress: currentPrimaryContact.billingAddress,
        compDisplayName: currentPrimaryContact.company,
        billingEmailAdd: currentPrimaryContact.email,
        billingRepName: currentPrimaryContact.fullName,
        billingPhoneNum: currentPrimaryContact.phoneNumber,
        billingSpecIns: currentPrimaryContact.specialInstructions,
        billingRepCompany: currentPrimaryContact.company,
        billingRepTitle: currentPrimaryContact.title,
        memberId: selectedMember.id,
        missionId: parseInt(missionId),
        billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
        billingStatusEx: 2,
      }))
    },
    []
  )
  useEffect(()=>{
    if(currentMissionRegistration?.id){
      if(!missionRegistrationId && !haveOpened && !loadingScreen && !isLoading){
        setHaveOpened(true)
      MySwal.fire({
        title: `The ${currentMissionRegistration.billingRepCompany} is already registered for this mission.`,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: 'Proceed',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setHaveOpened(false)
          if(isRepresentativeView){
            navigate(`/representative/mission/${missionId}/registration/${currentMissionRegistration.id}`)
          }else{
            navigate(`/missions/view/${missionId}/registration/${currentMissionRegistration.id}`)
          }
        }
      })
    }
  }
  },[currentMissionRegistration])

  useEffect(() => {
    fetchMembers()
  }, [])

  const fetchMembers = ()=>{
    setCurrentMissionRegistration(undefined)
    addMemberParticipants([])
    axiosInstance
      .get('/api/members?pagination[page]=1&pagination[pageSize]=200')
      .then((response) => {
        const allMembers = response.data.data.map((member) => {
          return {
            ...member.attributes,
            id: member.id,
          } as TMembers
        })
        setMembers(allMembers)
      })
      .catch((error) => {
        console.error('Error fetching member data:', error)
      })
  }

  useEffect(() => {
    if (memberParticipants.length > 0) {
      setCurrentPrimaryRep(memberParticipants[0])
    }
  }, [memberParticipants])

  useEffect(() => {
    if (
      missionId &&
      missionRegistrationId &&
      selectedMember.id == null &&
      currentPrimaryContact.billingInfoId === null
    ) {
      setIsMemberDropdownDisabled(true)
      setLoadingScreen(true)
      axiosInstance
        .get(
          `/api/mission-registrations/${missionRegistrationId}/?populate=memberId,billingInfoId,missionId,compLogo`
        )
        .then(({data}) => {
          const {data: missionRegistration} = data
          const {attributes: missionRegistrationDetails} = missionRegistration
          const {memberId, billingInfoId} = missionRegistrationDetails
          if(billingInfoId){
            setHaveBillingInfo(true)
          }
          else{
            setHaveBillingInfo(false)
          }

          setSelectedMember(memberId.data.attributes)
          fetchMemberRepresentatives(memberId.data.id)
          setCurrentPrimaryContact(billingInfoId.data.attributes)
          setCurrentPrimaryContactId(billingInfoId.data.id)

          setCurrentMissionRegistration((prev) => ({
            ...prev,
            ...missionRegistrationDetails,
            id: missionRegistration.id,
          }))
          if (data.data.attributes.representativeName) {
            setSelectedOption(data.data.attributes.representativeName)
          }

          setFormData(missionRegistrationDetails)
          setInitialFormData(missionRegistrationDetails)
        })
        .catch((err) => console.error(err))
        .finally(() => setLoadingScreen(false))
    } else if (
      missionId &&
      !missionRegistrationId &&
      selectedMember.id == null &&
      currentMember?.id != null &&
      currentUser
    ) {
      // for representative registration
      setIsMemberDropdownDisabled(true)
      setSelectedMember(currentMember)
      fetchMemberRepresentatives(currentMember.id)
      fetchBillingInfoByCurrentUserMember(
        currentMember.id,
        fetchMemberBillingInfo,
        setCurrentPrimaryContact,
        setFormData,
        currentUser,
        currentPrimaryContact,
        selectedMember,
        missionId,
        fetchParticipantMemberInformation
      )
    } else if (currentPrimaryContact && selectedMember && missionId && currentUser?.id != null) {
      // Set the default values of the current selected member registration
      if (currentUser?.isStaff) {
        if (!missionRegistrationId) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentPrimaryContact.userId,
            billingAddress: currentPrimaryContact.billingAddress,
            compDisplayName: currentPrimaryContact.company,
            billingEmailAdd: currentPrimaryContact.email,
            billingRepName: currentPrimaryContact.fullName,
            billingPhoneNum: currentPrimaryContact.phoneNumber,
            billingSpecIns: currentPrimaryContact.specialInstructions || '',
            billingRepCompany: currentPrimaryContact.company,
            billingRepTitle: currentPrimaryContact.title,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
            billingStatusEx: 2,
          }))
          setInitialFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentPrimaryContact.userId,
            billingAddress: currentPrimaryContact.billingAddress,
            compDisplayName: currentPrimaryContact.company,
            billingEmailAdd: currentPrimaryContact.email,
            billingRepName: currentPrimaryContact.fullName,
            billingPhoneNum: currentPrimaryContact.phoneNumber,
            billingSpecIns: currentPrimaryContact.specialInstructions || '',
            billingRepCompany: currentPrimaryContact.company,
            billingRepTitle: currentPrimaryContact.title,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
            billingStatusEx: 2,
          }))
        } else if (
          currentMissionRegistration !== undefined &&
          currentMissionRegistration.billingInfoId
        ) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentMissionRegistration.createdByUser,
            billingAddress: currentMissionRegistration.billingAddress,
            compDisplayName: currentMissionRegistration.compDisplayName,
            billingEmailAdd: currentMissionRegistration.billingEmailAdd,
            billingRepName: currentMissionRegistration.billingRepName,
            billingPhoneNum: currentMissionRegistration.billingPhoneNum,
            billingSpecIns: currentMissionRegistration.billingSpecIns || '',
            billingRepCompany: currentMissionRegistration.billingRepCompany,
            billingRepTitle: currentMissionRegistration.billingRepTitle,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: currentMissionRegistration.billingInfoId,
            billingStatusEx: 2,
          }))
          setInitialFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentMissionRegistration.createdByUser,
            billingAddress: currentMissionRegistration.billingAddress,
            compDisplayName: currentMissionRegistration.compDisplayName,
            billingEmailAdd: currentMissionRegistration.billingEmailAdd,
            billingRepName: currentMissionRegistration.billingRepName,
            billingPhoneNum: currentMissionRegistration.billingPhoneNum,
            billingSpecIns: currentMissionRegistration.billingSpecIns || '',
            billingRepCompany: currentMissionRegistration.billingRepCompany,
            billingRepTitle: currentMissionRegistration.billingRepTitle,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: currentMissionRegistration.billingInfoId,
            billingStatusEx: 2,
          }))
        } else {
          console.log('currentMissionRegistration is empty as staff')
        }
      } else {
        if (!missionRegistrationId) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentUser?.id,
            billingAddress: currentPrimaryContact.billingAddress,
            compDisplayName: currentPrimaryContact.company,
            billingEmailAdd: currentPrimaryContact.email,
            billingRepName: currentPrimaryContact.fullName,
            billingPhoneNum: currentPrimaryContact.phoneNumber,
            billingSpecIns: currentPrimaryContact.specialInstructions,
            billingRepCompany: currentPrimaryContact.company,
            billingRepTitle: currentPrimaryContact.title,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
            billingStatusEx: 2,
          }))
          setInitialFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentUser?.id,
            billingAddress: currentPrimaryContact.billingAddress,
            compDisplayName: currentPrimaryContact.company,
            billingEmailAdd: currentPrimaryContact.email,
            billingRepName: currentPrimaryContact.fullName,
            billingPhoneNum: currentPrimaryContact.phoneNumber,
            billingSpecIns: currentPrimaryContact.specialInstructions,
            billingRepCompany: currentPrimaryContact.company,
            billingRepTitle: currentPrimaryContact.title,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: parseInt(currentPrimaryContact.billingInfoId),
            billingStatusEx: 2,
          }))
        } else if (
          currentMissionRegistration !== undefined &&
          currentMissionRegistration.billingInfoId
        ) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentMissionRegistration.createdByUser,
            billingAddress: currentMissionRegistration.billingAddress,
            compDisplayName: currentMissionRegistration.compDisplayName,
            billingEmailAdd: currentMissionRegistration.billingEmailAdd,
            billingRepName: currentMissionRegistration.billingRepName,
            billingPhoneNum: currentMissionRegistration.billingPhoneNum,
            billingSpecIns: currentMissionRegistration.billingSpecIns,
            billingRepCompany: currentMissionRegistration.billingRepCompany,
            billingRepTitle: currentMissionRegistration.billingRepTitle,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: currentMissionRegistration.billingInfoId,
            billingStatusEx: 2,
          }))
          setInitialFormData((prevFormData) => ({
            ...prevFormData,
            createdByUser: currentMissionRegistration.createdByUser,
            billingAddress: currentMissionRegistration.billingAddress,
            compDisplayName: currentMissionRegistration.compDisplayName,
            billingEmailAdd: currentMissionRegistration.billingEmailAdd,
            billingRepName: currentMissionRegistration.billingRepName,
            billingPhoneNum: currentMissionRegistration.billingPhoneNum,
            billingSpecIns: currentMissionRegistration.billingSpecIns,
            billingRepCompany: currentMissionRegistration.billingRepCompany,
            billingRepTitle: currentMissionRegistration.billingRepTitle,
            memberId: selectedMember.id,
            missionId: parseInt(missionId),
            billingInfoId: currentMissionRegistration.billingInfoId,
            billingStatusEx: 2,
          }))
        } else {
          console.log('currentMissionRegistration is empty as representative')
        }
      }
    }
  }, [
    missionRegistrationId,
    currentMissionRegistration,
    currentPrimaryContact,
    selectedMember,
    currentUser,
    currentMember,
  ])

  const handleChangeNumber = (value,country) => {
    const countryCode = country.countryCode.toUpperCase();
    const phoneNumber = isValidPhoneNumber(value, countryCode);
   
    if (!phoneNumber || !phoneNumber.isValid()) {
      setFormData((prevData) => ({
        ...prevData,
        phoneNumError: 'Please enter a valid phone number',
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        phoneNumError: '',
        billingPhoneNum: value,
      }));
    }
    // setFormData((prevData) => ({
    //   ...prevData,
    //   billingPhoneNum: value,
    // }));
    // setCurrentPrimaryContact((prevData) => ({
    //   ...prevData,
    //   phoneNumber: value,
    // }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    // Validate email and phone number in real-time
    if (name === 'billingEmailAdd') {
      if (!isEmailValid(value)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: 'Please enter a valid email address',
        }))
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: '',
        }))
      }
    }
  }

  const handleMemberSelect = async (newValue: any) => {
    console.log(newValue,'newValue')
    if (newValue) {
      if (currentUser?.isStaff) {
        setLoadingScreen(true)
        let {data} = await axiosInstance.get(
          `/api/mission-registrations/?populate=missionId,memberId,billingInfoId&filters[missionId][id][$eq]=${missionId}&filters[memberId][id]=${newValue.id}`
        )
        setLoadingScreen(false)
        if (data.data.length > 0) {
          setSelectedMember({
            id: null,
            memberId: '',
            displayName: '',
            isActive: true,
            memberSince: '',
            memberBio: '',
            logo: '',
            referredName: '',
            participationPublic: null,
          })
          setCurrentPrimaryContact({
            billingAddress: '',
            company: '',
            email: '',
            fullName: '',
            title: '',
            userId: 0,
            billingInfoId: null,
          })
          MySwal.fire({
            html: `To update their registration, please click "proceed". If registering a different member, please select another name.`,
            title: `The ${newValue.displayName} is already registered for this mission.`,
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
          }).then(async (result) => {
            if (result.isConfirmed) {
              navigate(`${data.data[0].id}`)
            } else {
              addMemberParticipants([])
            }
          })
        } else {
          setSelectedMember(newValue)
          fetchMemberRepresentatives(newValue.id)
          let primaryContact = await (fetchMemberBillingInfo(newValue.id) as Promise<void>) // Explicitly annotate as Promise
          if(primaryContact !== null){
            setHaveBillingInfo(true)
          }
          else{
            setHaveBillingInfo(false)
          }
          setCurrentPrimaryContact(primaryContact)
          //@ts-ignore
          setCurrentPrimaryContactId(primaryContact.billingInfoId)
          setFormData((prevData) => ({
            ...prevData,
            memberName: newValue.displayName,
            id: newValue.id,
          }))
        }
      } else {
        setSelectedMember(newValue)
        fetchMemberRepresentatives(newValue.id)
        let primaryContact = await (fetchMemberBillingInfo(newValue.id) as Promise<void>) // Explicitly annotate as Promise
        if(primaryContact !== null){
          setHaveBillingInfo(true)
        }
        else{
          setHaveBillingInfo(false)
        }
        setCurrentPrimaryContact(primaryContact)
        //@ts-ignore
        setCurrentPrimaryContactId(primaryContact.billingInfoId)
        setFormData((prevData) => ({
          ...prevData,
          memberName: newValue.displayName,
          id: newValue.id,
        }))
      }
    }
  }

  function areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  
    return true;
  }

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  // const isPhoneNumberValid = (phoneNumber: string) => {
  //   const cleanedPhoneNumber = phoneNumber.replace(/[\s-()]/g, '')
  //   const phoneRegex = /^\+\d{8,}$/
  //   return phoneRegex.test(cleanedPhoneNumber)
  // }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setFormSubmitted(true)
    setIsLoading(true)
    if (
      !formData.billingRepName ||
      !formData.billingRepTitle ||
      !formData.compDisplayName ||
      !formData.billingAddress ||
      !formData.billingPhoneNum ||
      !formData.billingEmailAdd
    ) {
      MySwal.fire({
        text: 'Please fill in all required fields.',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
      setIsLoading(false)
      return
    }
    if (formData.phoneNumError || formData.emailError) {
      MySwal.fire({
        text: 'Please fix the errors in the phone number and email fields.',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
      setIsLoading(false)
      return
    }
    try {
      if (!missionRegistrationId) {
        await axiosInstance.post(`/api/mission-registrations`, {
          data: {
            ...omit(['id', 'missionMemparticipantsRegistrationId '], formData),
            billingRepCompany: selectedMember.displayName
          },
        }).then((response) => {
          setCurrentMissionRegistration((prev) => ({
            ...prev,
            ...response.data.data.attributes,
            id: response.data.data.id,
          }))
          if(haveBillingInfo === false){
            axiosInstance.post(`/api/billing-infos`, {
              data:{
                fullName: formData.billingRepName,
                title: formData.billingRepTitle,
                company: formData.compDisplayName,
                billingAddress: formData.billingAddress,
                email: formData.billingEmailAdd,
                memberInfo: formData.memberId,
                userId: currentUser?.id,
                specialInstructions: formData.billingSpecIns,
                mission_registrations: response.data.data.id,
              }
            })
          }else{
            axiosInstance.put(`/api/billing-infos/${currentPrimaryContactId}`, {
              data:{
                fullName: formData.billingRepName,
                title: formData.billingRepTitle,
                company: formData.compDisplayName,
                billingAddress: formData.billingAddress,
                phoneNumber: formData.billingPhoneNum,
                email: formData.billingEmailAdd,
                userId: currentUser?.id,
                specialInstructions: formData.billingSpecIns,
              }
            })
          }
        })
       await axiosInstance.post(`/api/registration-logs`, {
          data:{
            title: 'Created',
            message: 'Created their Billing Information',
            company: formData.compDisplayName,
            category: 'Billing Info',
            user_id: currentUser?.id,
            mission: missionId,
          }
        })
        MySwal.fire({
          text: 'Registration Initiated and Billing information saved!',
          timer: 2000,
          icon: 'success',
          showConfirmButton: false,
          allowOutsideClick: false,
        }).then(() => {
          setIsLoading(false)
          setActiveStep(activeStep + 1)
        })
      } else if (missionRegistrationId && currentUser) {
        const isFormDataChanged = () => {
          return !areObjectsEqual(omit(['id', 'missionMemparticipantsRegistrationId'], formData), initialFormData);
        };
        if(isFormDataChanged()){
          MySwal.fire({
            title: currentUser?.isStaff
              ? 'You are about to update billing information of this member for this mission.'
              : 'You are about to update billing information of your registration for this mission.',
            text: 'Do you want to proceed?',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              setIsLoading(true)
              await axiosInstance
                .put(`/api/mission-registrations/${missionRegistrationId}`, {
                  data: omit(['id', 'missionMemparticipantsRegistrationId '], formData),
                })
                .then((response) => {
                  if(haveBillingInfo === false){
                    axiosInstance.post(`/api/billing-infos`, {
                      data:{
                        fullName: formData.billingRepName,
                        title: formData.billingRepTitle,
                        company: formData.compDisplayName,
                        billingAddress: formData.billingAddress,
                        email: formData.billingEmailAdd,
                        memberInfo: formData.memberId,
                        userId: currentUser?.id,
                        specialInstructions: formData.billingSpecIns,
                        mission_registrations: response.data.data.id,
                      }
                    })
                  }else{
                    axiosInstance.put(`/api/billing-infos/${currentPrimaryContactId}`, {
                      data:{
                        fullName: formData.billingRepName,
                        title: formData.billingRepTitle,
                        company: formData.compDisplayName,
                        billingAddress: formData.billingAddress,
                        phoneNumber: formData.billingPhoneNum,
                        email: formData.billingEmailAdd,
                        userId: currentUser?.id,
                        specialInstructions: formData.billingSpecIns,
                      }
                    })
                  }
                  setIsLoading(false)
                  MySwal.fire({
                    text: 'Billing Information Updated',
                    timer: 2000,
                    icon: 'success',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                  }).then(() => {
                    setCurrentMissionRegistration((prev) => ({
                      ...prev,
                      ...response.data.data.attributes,
                      id: response.data.data.id,
                    }))
                    setFormSubmitted(false)
                    setActiveStep(activeStep + 1)
                  })
                })
                 await axiosInstance.post(`/api/registration-logs`, {
                  data:{
                    title: 'Updated',
                    message: 'Updated their Billing Information',
                    company: formData.compDisplayName,
                    category: 'Billing Info',
                    user_id: currentUser?.id,
                    mission: missionId,
                  }
                })
                .catch((error) => {
                  MySwal.fire({
                    title: 'Oops. Something Went Wrong!',
                    text: 'Please contact your IT Administrator or try again later.',
                    timer: 2000,
                    icon: 'error',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                  setFormSubmitted(false)
                })
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
              setFormSubmitted(false)
              setIsLoading(false)
            }
          })
        }
        else{
          setFormSubmitted(false)
          setActiveStep(activeStep + 1)
        }
        
      }
    } catch (error) {
      MySwal.fire({
        text: 'Something went wrong',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
    } finally {
      setActiveStep(activeStep + 1)
      await setLoadingScreen(false)
    }
  }

  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const [customInputValue, setCustomInputValue] = useState('')
  const options = memberParticipants.map((participant) => participant.username)

  const handleInputChange = (event, newValue) => {
    setCustomInputValue(newValue)
  }
  const createNewMemberAndCallAPI = async (displayName) => {
    const regex = /"([^"]*)"/g;
    const matches = displayName.match(regex);
    const cleanedDisplayName = matches ? matches[0].replace(/"/g, '').trim() : '';
    MySwal.fire({
      title: `Are you sure you want to add ${cleanedDisplayName} to members.`,
      icon: 'info',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingScreen(true);
    try {
      const billingInfoResponse = await axiosInstance.post(`/api/billing-infos`, {
        data: {
          company: cleanedDisplayName,
        },
      });
      const memberResponse = await axiosInstance.post(`/api/members`, {
        data: {
          isActive: true,
          displayName: cleanedDisplayName,
          billingInfo: billingInfoResponse.data.data.id,
        },
      });
      //@ts-ignore
      handleMemberSelect({
        id: memberResponse.data.data.id,
        displayName: memberResponse.data.data.attributes.displayName,
      });
      await fetchMembers()
    } catch (error) {
      // Handle errors
      console.error('Error creating member:', error);
    } finally {
      setLoadingScreen(false);
    }
      } else {
        setSelectedMember({
          id: null,
          memberId: '',
          displayName: '',
          isActive: true,
          memberSince: '',
          memberBio: '',
          logo: '',
          referredName: '',
          participationPublic: null,
        })
      }
    })
  };
  
  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginTop: 5,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Container>
            {!isRepresentativeView && currentUser ? (
              <>
                <Typography
                  variant='h5'
                  gutterBottom
                  className='mb-5'
                  hidden={currentUser?.isMember}
                >
                  Select Member
                </Typography>
                {/* <Typography gutterBottom>
                Select a member for the list below to initiate a registration on behalf of that
                member.
              </Typography> */}
                <Autocomplete
                  hidden={currentUser?.isMember}
                  disableClearable={isMemberDropdownDisabled}
                  disabled={isMemberDropdownDisabled || isLoading}
                  disableListWrap={isMemberDropdownDisabled}
                  key={formData.id}
                  id='member-autocomplete'
                  getOptionLabel={(member) => member.displayName}
                  value={selectedMember}
                  options={members}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.id === null) {
                      createNewMemberAndCallAPI(newValue.displayName);
                    } else {
                      handleMemberSelect(newValue);
                    }
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} name='id' label='Members' />
                  }}
                  sx={{
                    marginBottom: 3,
                  }}
                  getOptionDisabled={() => !!missionRegistrationId || !!currentMissionRegistration}
                  filterOptions={(options, { inputValue }) => {
                    const filtered = options.filter((member) => {
                      const displayName = member.displayName.toLowerCase();
                      const input = inputValue.toLowerCase();
                      return displayName.includes(input);
                    });
                
                    const isExisting = options.some((op) => op.displayName.toLowerCase() === inputValue.toLowerCase());
                
                    if (inputValue !== '' && !isExisting) {
                      //@ts-ignore
                      filtered.push({
                        displayName: `Add "${inputValue}"`,
                        id: null,
                      });
                    }
                    return filtered;
                  }}
                />
              </>
            ) : (
              <></>
            )}

            {selectedOption && (
              <div className='alert alert-sm alert-dismissible alert-dark d-flex align-items-center p-5 pb-3'>
                <span className='svg-icon svg-icon-2hx svg-icon-dark me-3'>
                  <span className='svg-icon svg-icon-2hx svg-icon-dark me-4'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mh-50px'
                    >
                      <rect
                        opacity='0.3'
                        x='2'
                        y='2'
                        width='20'
                        height='20'
                        rx='10'
                        fill='black'
                      ></rect>
                      <rect
                        x='11'
                        y='14'
                        width='7'
                        height='2'
                        rx='1'
                        transform='rotate(-90 11 14)'
                        fill='black'
                      ></rect>
                      <rect
                        x='11'
                        y='17'
                        width='2'
                        height='2'
                        rx='1'
                        transform='rotate(-90 11 17)'
                        fill='black'
                      ></rect>
                    </svg>
                  </span>
                </span>
                <div className='d-flex flex-column pe-0 pe-sm-10'>
                  <h5 className='mb-3'>
                    Fill in the details of the primary point of contact for all billing related to
                    this registration.
                  </h5>
                  <p className='fs-8'>
                    Details may be entered manually, or the <strong>Representatives</strong>{' '}
                    drop-down list may be used to auto-complete the necessary fields. This process{' '}
                    <strong>does not </strong>
                    add the contact as a participant on the mission.
                  </p>
                </div>
                <button
                  type='button'
                  className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                  data-bs-dismiss='alert'
                >
                  <span className='svg-icon svg-icon-2x svg-icon-light'>
                    <span className='svg-icon svg-icon-2x svg-icon-light'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='black'
                        ></rect>
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='black'
                        ></rect>
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            )}
            <Typography variant='h5' className='mb-5'>
              Select Representative{!currentUser?.isStaff}
            </Typography>
            <Autocomplete
              options={options}
              freeSolo
              value={selectedOption}
              inputValue={customInputValue}
              onInputChange={handleInputChange}
              filterOptions={(filterOptions, params) => {
                const filtered = filter(filterOptions, params)
                const {inputValue} = params
                const isExisting = filterOptions.some((op) => inputValue === op)
                if (inputValue !== '' && !isExisting) {
                  filtered.push(`Add "${inputValue}"`)
                }

                return filtered
              }}
              onChange={(event, newValue) => {
                let regex = /"([^"]*)"/g
                let matches = newValue?.match(regex)
                if (matches) {
                  const newEntry = matches[0].replace(/"/g, '')
                  setSelectedOption(newEntry)
                  setFormData((prevData) => ({
                    ...prevData,
                    billingRepName: newEntry,
                    representativeName: newEntry,
                  }))
                }
                else{
                  setSelectedOption(newValue)
                  //@ts-ignore
                  setFormData((prevData) => ({...prevData, billingRepName: newValue,representativeName: newValue}))
                }
              }}
              renderInput={(params) => <TextField {...params} label='Enter Representative Name' />}
              disabled={isLoading}
            />
          </Container>
          <Container hidden={selectedOption === null}>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='billingRepName'
                  label='Full Name'
                  fullWidth
                  value={formData.billingRepName ?? ''}
                  defaultValue={formData.billingRepName ?? ''}
                  onChange={handleChange}
                  error={formSubmitted && !formData.billingRepName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='billingRepTitle'
                  label='Title'
                  fullWidth
                  value={formData.billingRepTitle ?? ''}
                  defaultValue={formData.billingRepTitle ?? ''}
                  onChange={handleChange}
                  error={formSubmitted && !formData.billingRepTitle}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='compDisplayName'
                  label='Company (as it should appear on invoice)'
                  fullWidth
                  value={formData.compDisplayName ?? ''}
                  defaultValue={formData.compDisplayName ?? ''}
                  onChange={handleChange}
                  error={formSubmitted && !formData.compDisplayName}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='billingAddress'
                  label='Billing Address'
                  fullWidth
                  value={formData.billingAddress ?? ''}
                  defaultValue={formData.billingAddress ?? ''}
                  onChange={handleChange}
                  error={formSubmitted && !formData.billingAddress}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                  defaultCountry={'us'}
                  countryCodeEditable={false}
                  disableAreaCodes={true}
                  //@ts-ignore
                  onChange={handleChangeNumber}
                  name='billingPhoneNum'
                  label='Phone Number'
                  fullWidth
                  value={formData.billingPhoneNum ?? ''}
                  defaultValue={formData.billingPhoneNum ?? ''}
                  error={
                    (formSubmitted && !formData.billingPhoneNum) ||
                    formData.phoneNumError === 'Please enter a valid phone number'
                  }
                  disabled={isLoading}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: 'MuiInput-root',
                      notchedOutline: 'MuiOutlinedInput-notchedOutline',
                    },
                  }}
                />

                <span style={{color: 'red'}}>{formData.phoneNumError}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name='billingEmailAdd'
                  label='Email'
                  fullWidth
                  value={formData.billingEmailAdd ?? ''}
                  defaultValue={formData.billingEmailAdd ?? ''}
                  onChange={handleChange}
                  error={
                    (formSubmitted && !formData.billingEmailAdd) ||
                    formData.emailError === 'Please enter a valid email address'
                  }
                  disabled={isLoading}
                />
                <span style={{color: 'red'}}>{formData.emailError}</span>
              </Grid>
              <Grid item xs={12}>
                <h6> Invoicing Special Instructions</h6>
                <CKEditor
                  editor={Editor}
                  id={`bio_${formData.id}`}
                  onReady={(editor) => {
                    editor.setData(formData.billingSpecIns || '')
                  }}
                  onChange={(event, editor) => {
                    const wordCountPlugin = editor.plugins.get('WordCount')
                    setSpecialInstructionsCharCount(wordCountPlugin.characters)
                    const data = editor.getData()
                    setFormData((prevData) => ({
                      ...prevData,
                      billingSpecIns: data,
                    }))
                  }}
                  disabled={isLoading}
                />
                <div className='row'>
                  <div className='col-10'>
                    <span className='fs-8'>
                      <i>Optional</i>. Use this field to provide instructions for any form of
                      invoicing other than invoicing the entire amount to one account.
                    </span>
                  </div>
                  <div className='col-2'>
                    <span
                      id='word-count'
                      className='fs-8'
                      style={{
                        float: 'right',
                        color: specialInstructionsCharCount > 2500 ? 'red' : 'black',
                      }}
                    >
                      Character count: {specialInstructionsCharCount}/2500
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid mt={2} item xs={12}>
                <Button
                  type='button'
                  variant='contained'
                  color='error'
                  href={`/missions/view/${missionId}`}
                  disabled={isLoading}
                >
                  {missionRegistrationId ? 'Cancel' : 'Back'}
                </Button>{' '}
                {showBackToDetailsButton  && (
                <Button
                type='button'
                variant='contained'
                color='primary'
                href={`/missions/view/${missionId}`}
                disabled={isLoading}
                style={{marginLeft : "20px"}}
              >
                Back to Details Page
              </Button>)
               }
                {missionRegistrationId ? (
                  <>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      style={{float: 'right'}}
                      disabled={isLoading || specialInstructionsCharCount > 2500}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color='primary' />
                      ) : (
                        'Save and Continue'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='outlined'
                      color='primary'
                      style={{float: 'right', marginRight: '10px'}}
                      onClick={() => setActiveStep(activeStep + 1)}
                      disabled={isLoading}
                    >
                      Continue without Saving
                    </Button>
                  </>
                ) : (
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{float: 'right'}}
                    onClick={handleSubmit}
                    disabled={isLoading || specialInstructionsCharCount > 2500}
                  >
                    {isLoading ? <CircularProgress size={24} color='primary' /> : 'Next'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        </form>
      </Box>
    </>
  )
}

export default MemberBillingInformation
