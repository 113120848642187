import React from 'react'
import { TPastMissions } from '../../../../types/types'
import { formatDateRangeToYYYYMMDDFormat } from '../../../../utils/helper'
import Cards from '../../../common/components/Cards'
import {KTIcon} from '../../../../_metronic/helpers'

const PastMission: React.FC<TPastMissions> = ({ pastMissions }) => {
  return (
    <div className='row mt-10'>
      <div className='d-flex flex-wrap justify-content-start mb-4'>
      <KTIcon iconName='abstract-26' className='fs-1 mr-auto' />
      <div className='ml-auto' style={{marginLeft: '10px'}}>
        <h5>Past Missions ({pastMissions.length})</h5>
        </div>
      </div>
      <div className='d-flex flex-wrap justify-content-start'>
        {pastMissions.length > 0 && pastMissions
          .filter(mission => mission.isActive)
          .map((mission) => (
            <Cards
              id={mission.id}
              title={mission.title}
              duration={formatDateRangeToYYYYMMDDFormat(mission.msStartDate, mission.msEndDate)}
              registrationDeadline={mission.regCloseDate}
              registrationStatus={mission.isActive}
              refundCutDate={mission.refundCutDate}
              inProgress={mission.inprogCount}
              submitted={mission.submCount}
              approved={mission.apprCount}
              cardHeight='250px'
              cardWidth='410px'
              key={mission.id}
              missionDetails={mission}
              timezone_abbrev={mission.timezone_abbrev}
              isPastMission
            />
          ))}
      </div>
    </div>
  )
}

export default PastMission
