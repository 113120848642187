import {Button} from '@mui/material'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import axiosInstance from '../../../../utils/axios'
import {useParams} from 'react-router-dom'
const ExportOptions = () => {
  type ExportForm = {
    scope: string
    type: string
  }
  const {handleSubmit, register} = useForm<ExportForm>()
  const {missionId} = useParams()

  const onSubmit = async ({type}) => {
    window.open(`${process.env.REACT_APP_BACKEND_HOST}/api/missions/export/${missionId}/${type}`)
  }
  return (
    <Container>
      <Row>
        <Col>
          <p>
            Set export options below and click <b>Export</b> to download aggregate information about
            mission registrations and participants.
          </p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <strong>Scope *</strong>
              </Form.Label>
              <Form.Control
                as='select'
                {...register('scope', {required: 'Start Date is required'})}
              >
                <option>Select Scope</option>
                <option value='all'>All</option>
                <option value='approved'>Approved</option>
                <option value='cancelled'>Cancelled</option>
                <option value='incomplete'>Incomplete</option>
                <option value='submitted'>Submitted</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <strong>Export Type *</strong>
              </Form.Label>
              <Form.Control as='select' {...register('type', {required: 'Start Date is required'})}>
                <option>Select Export Type</option>
                <option value='CPPB'>Company Profiles + Participant Biographies</option>
                <option value='BPL'>Basic Participant List</option>
                <option value='DL'>Delegation List</option>
                <option value='PB'>Participant Biographies</option>
                <option value='TPBMT'>Talking Points by Meeting: All</option>
                <option value='SMBM'>Talking Points by Meeting: Speaking Role - Yes</option>
                <option value='TPBMB'>Talking Points by Member</option>
                <option value='AM'>Attending Member</option>
                <option value='TPM'>Talking Points Matrix</option>
                <option value='TC1'>Tent Card 30th ASEAN Transport Ministers Meeting</option>
                <option value='FBI'>[FINANCE] Billing Instructions - per Company</option>
                {/* <option value='NT'>Name Tag</option> */}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className='my-5'>
          <Col>
            <Button type='submit' color='primary' variant='contained'>
              Export
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default ExportOptions
