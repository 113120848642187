import UpcomingMissions from '../../mission/pages/UserMissions'
const MainDashboard = () => {
  return (
    <>
      <UpcomingMissions />
    </>
  )
}

export default MainDashboard
