import {Route, Routes} from 'react-router-dom'
import {DashboardLayout} from './DashboardLayout'
import MainDashboard from './components/MainDashboard'

const DashboardPage = () => (
  <Routes>
    <Route element={<DashboardLayout />}>
      <Route index element={<MainDashboard />} />
    </Route>
  </Routes>
)

export default DashboardPage
