import Editor from 'ckeditor5-custom-build/build/ckeditor'
//@ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react'
import React, {useState, useEffect, useCallback} from 'react'
import axiosInstance from '../../../../../utils/axios'

type ActionsCardProps = {
  handleClose: () => void
  triggerSubmit: () => void
  triggerCancel: () => void
  notes: string
  setNotes: React.Dispatch<React.SetStateAction<string>>
  isDisabled: boolean
  isSummary: boolean
  participantId: string
  hasPermission?: boolean
  registrationStatus?: boolean
}

const ActionsCard: React.FC<ActionsCardProps> = ({
  handleClose,
  triggerSubmit,
  triggerCancel,
  notes,
  setNotes,
  isDisabled,
  isSummary,
  participantId,
  hasPermission,
  registrationStatus
}) => {
 
  const [bioCharCount, setBioCharCount] = useState<number>(0)

  const saveNotesToAPI = () => {
    // Make an API POST request to save the notes
    axiosInstance
      .put(`/api/mission-registrations/${participantId}`, {data: {notes: notes}})
      .then((response) => {
        triggerSubmit()
      })
      .catch((error) => {
        console.error('Error saving notes:', error)
      })
  }

  const handleInputChange = useCallback((data) => {
    setNotes(data)
  }, [])

  useEffect(() => {
    if (participantId) {
      try {
        axiosInstance.get(`/api/mission-registrations/${participantId}`).then((response) => {
          const fetchedNotes = response.data.data.attributes.notes
          setNotes(fetchedNotes)
        })
      } catch (error) {
        setNotes('')
      }
    }
  }, [participantId])

  return (
    <div className='card card-custom gutter-b'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='card-label'>Registration Notes</h3>
        </div>
      </div>
      <div className='card-body px-5'>
        <CKEditor
         id={`notes-${participantId}`}
          editor={Editor}
          onReady={(editor) => {
            editor.setData(notes)
          }}
          onChange={(event, editor) => {
            const wordCountPlugin = editor.plugins.get('WordCount')
            setBioCharCount(wordCountPlugin.characters)
            const data = editor.getData()
            handleInputChange(data)
          }}
        />
        <div
          id='word-count'
          style={{
            textAlign: 'right',
            color: bioCharCount > 2500 ? 'red' : 'black',
            fontSize: '10px',
            fontStyle: 'italic',
          }}
        >
          Character count: {bioCharCount}/2500
        </div>
      </div>
        {isSummary && registrationStatus ? (
        <>
          <div className='card-footer px-3 py-3'>
            <div className='row'>
              <div className='col-auto'>
                <button className='btn btn-success' onClick={saveNotesToAPI}>
                  Save Notes
                </button>
              </div>
              <div className='col-auto ms-auto'>
                <button className='btn btn-primary' onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='card-footer px-3 py-3'>
            <div className='row'>
              <div className='col-auto'>
                <button
                  className='btn btn-success'
                  onClick={saveNotesToAPI}
                  disabled={bioCharCount > 2500}
                  hidden={!registrationStatus}
                >
                  Save Notes
                </button>
              </div>
              <div className='col-auto ms-auto'>
                <button className='btn btn-primary' onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ActionsCard
