import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../utils/axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Box, Button, Typography, Card, CardContent, CardActions,CardMedia } from '@mui/material';


type ProfileFormProps = {
  missionId: string;
};

type ProfileData = {
    attributes :{
    firstName: string;
    lastName: string;
    photo: {
      data?: {
        id: number;
        attributes: {
          url: string;
          name: string;
          ext: string
        };
      } | null;
    };
    };
  };

const ProfileForm: React.FC<ProfileFormProps> = ({ missionId }) => {
    const [logos, setLogos] = useState<ProfileData[]>([]);

    useEffect(() => {
        const fetchLogos = async () => {
          try {
            const response = await axiosInstance.get(`/api/missions/${missionId}?populate=missionMemparticipant.photo,missionMemparticipant.registrationId`);
            const missionRegistrations = response.data.data.attributes.missionMemparticipant.data;
            const logos = missionRegistrations.map(registration => registration)
            const sortedLogos = logos.sort((a, b) => {
              const nameA = a.attributes.firstName.toUpperCase();
              const nameB = b.attributes.firstName.toUpperCase(); 
              if (nameA < nameB) {
                return -1; 
              }
              if (nameA > nameB) {
                return 1; 
              }
              return 0; 
            });
            console.log(sortedLogos)
            setLogos(sortedLogos);
          } catch (error) {
            console.error('Failed to fetch logos:', error);
          }
        };
      
        fetchLogos();
      }, [missionId]);

  function downloadImage(url, filename) {
    const cacheBustedUrl = url + ((url.indexOf('?') === -1) ? '?' : '&') + 'cacheBust=' + new Date().getTime();
    fetch(cacheBustedUrl, {
      method: 'GET',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading the image:', error));
  }
  function downloadAllImages() {
    const zip = new JSZip();
    const imgFolder = zip.folder("images");

    const imagePromises = logos.map((logo) => {
      const logoData = logo.attributes.photo.data;
      const ext = logoData?.attributes.ext
      const compDisplayName = `${logo.attributes.firstName}${logo.attributes.lastName}${ext}`
      if (logoData) {
        const url = logoData.attributes.url;
        const cacheBustedUrl = url + ((url.indexOf('?') === -1) ? '?' : '&') + 'cacheBust=' + new Date().getTime();
        const name = compDisplayName;
        return fetch(cacheBustedUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //@ts-ignore
            imgFolder.file(name, blob);
          });
      }
    });

    Promise.all(imagePromises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "profiles.zip");
      });
    }).catch((error) => console.error('Error downloading images:', error));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
        marginTop: '5px',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
         <Typography variant="h5">Profiles</Typography>
        <Button size="large" variant="contained" onClick={downloadAllImages}>
          Download All
        </Button>
        </Box>
      {logos.map((logo, index) => (
        <Card key={index} sx={{ width: 'calc(20% - 16px)', display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="body1" gutterBottom>
              {logo.attributes.firstName} {logo.attributes.lastName}
            </Typography>
            {logo.attributes.photo.data && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '10px',
                  height: '150px',
                  overflow: 'hidden', 
                }}
              >
                <CardMedia
                  component="img"
                  image={logo.attributes.photo.data.attributes.url}
                  alt={logo.attributes.firstName}
                  sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '150px',
                  }}
                />
              </Box>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
            {logo.attributes.photo.data && (
              <Button
                size="small"
                variant="contained"
                onClick={() => downloadImage(logo?.attributes?.photo?.data?.attributes.url, logo?.attributes?.photo?.data?.attributes.name)}
              >
                Download
              </Button>
            )}
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};
export default ProfileForm;
