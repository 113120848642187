import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import '../styles/loading-screen.css' // You can create a CSS file for styling

function LoadingScreen() {
  return (
    <div className='fullscreen-spinner'>
      <Spinner animation='border' />
    </div>
  )
}

export default LoadingScreen
