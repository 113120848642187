import { useEffect } from "react";
import RegisteredMission from "../mission/pages/RegisteredMission"
import UpcomingMissions from "../mission/pages/UpcomingMissions"

const RepresentativeDashboard = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=018457fc-1aae-483b-a23a-8aeadccf47b1';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
      <>
      <div className='container-fluid px-20'>
        <RegisteredMission isRepresentativeView={true} />
        <UpcomingMissions isRepresentativeView={true} />
      </div>
       
      </>
    )
  }
  
  export default RepresentativeDashboard
  