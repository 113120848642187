import {Navigate, Route, Routes} from 'react-router-dom'
import DashboardPage from '../modules/dashboard/DashboardPage'
import MissionsPage from '../modules/mission/MissionPage'
import RepresentativePage from '../modules/representative/RepresentativePage'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/dashboard/*' element={<DashboardPage />} />
        <Route path='/missions/*' element={<MissionsPage />} />
        {/* Page Not Found */}
        <Route path='/error' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
