import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

interface AddParticipantProps {
  onAddParticipant: (participant: any) => void;
  openAdd: boolean;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddParticipant: React.FC<AddParticipantProps> = ({ onAddParticipant, openAdd, setOpenAdd }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setValidEmail] = useState(true);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const isValid = validateEmail(inputEmail);
    setValidEmail(isValid);
  };

  const textFieldStyle = {
    marginTop: '10px',
  };

  const handleClose = () => {
    setOpenAdd(false);
  };

  const isFormValid = firstName && lastName && title && email;

  const handleAddParticipant = () => {
    if (!isFormValid) {
      alert('All fields are required');
      return;
    }

    const participant = {
      firstName,
      lastName,
      jobTitle: title,
      participantEmail: email,
    };
    onAddParticipant(participant);
    setFirstName('');
    setLastName('');
    setTitle('');
    setEmail('');
    handleClose();
  };

  return (
    <>
      <Dialog open={openAdd} onClose={handleClose}>
        <DialogTitle>Add Participant</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={textFieldStyle}
            required
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={textFieldStyle}
            required
          />
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={textFieldStyle}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            required  
            style={textFieldStyle}
            error={!isValidEmail}
            helperText={!isValidEmail ? 'Invalid email format' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddParticipant}
            variant="contained"
            color="primary"
            disabled={!isFormValid || !isValidEmail}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddParticipant;
