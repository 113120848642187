import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import {AuthPage, Logout} from '../modules/auth'
import CallBack from '../modules/auth/components/Callback'
import * as authHelper from '../modules/auth/core/AuthHelpers'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {PrivateRoutes} from './PrivateRoutes'
import RepresentativePage from '../modules/representative/RepresentativePage'
import Maintenance from '../modules/auth/components/Maintenance'

const AppRoutes: FC = () => {
  const user = authHelper.getCurrentUser()
  const token = authHelper.getToken()

  const isMaintenanceMode = process.env.REACT_APP_FRONTEND_MAINTENANCE === 'true';

  if (isMaintenanceMode) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Maintenance />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {!user ? (
            <>
              <Route path='/*' element={<Navigate to='/login' />} />
              <Route path='login/*' element={<AuthPage />} />
              <Route path='/callback/:verificationToken/:userId/:expToken' element={<CallBack />} />
            </>
          ) : user.isStaff === true ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />{' '}
            </>
          ) : (
            <>
              <Route path='/*' element={<Navigate to='/representative' />} />
              <Route path='/representative/*' element={<RepresentativePage />} />
            </>
          )}
        </Route>
        <Route path='logout' element={<Logout />} />
        <Route path='*' element={<Navigate to='/error' />} />
        <Route path='error/*' element={<ErrorsPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
