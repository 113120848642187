import React,{useState} from 'react'
import {countDaysLeft} from '../../../utils/helper'
import {TCardProps} from '../../../types/types'
import {Button, Tooltip} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {useMission} from '../../modules/mission/core/MissionProvider'
import {ParticipantModal} from '../../modules/mission/components/ParticipantsModal'
import axiosInstance from '../../../utils/axios'
import MySwal from '../../../utils/sweetAlert'
import { useAuth } from '../../modules/auth'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Cards: React.FC<TCardProps> = ({
  title,
  duration,
  status,
  registrationDeadline,
  registrationStatus,
  inProgress,
  submitted,
  approved,
  cardWidth,
  cardHeight,
  id,
  isOtherMission = false,
  isRepresentativeView = false,
  isSubmitted,
  regId,
  isUpcoming=false,
  billingStatus,
  billingStatusEx,
  participantStatus,
  submittedCSRStatus,
  companyProfileStatus,
  talkpointsStatus,
  compDisplayName,
  hasCsr,
  refundCutDate,
  missionDetails,
  timezone_abbrev,
  msEndDate,
  isPastMission= false,
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summaryInfo, setSummaryInfo] = useState({} as any);

  const { setSelectedMission } = useMission()
  const {currentUser} = useAuth()
  const isPastRefundCutDate = new Date() > new Date(refundCutDate);
  const isPastmsEndDate = msEndDate ? new Date() > new Date(msEndDate) : false;


  const viewMission = (missionId: string) => {
    navigate(`/missions/view/${missionId}`)
  }

  const setColor = (status: number | undefined) => {
    if(status === 2) {
      return 'badge-success'
    } else if(status === 1) {
      return 'badge-warning'
    } else {
      return 'badge-danger'
    }
  }

  const cancelRegistration = (id: string) => {
    axiosInstance.put(`/api/mission-registrations/${id}`, 
    {data:{
      isSubmitted: false,
      isCancelled: true
    }})
     axiosInstance.post(`/api/registration-logs`, {
      data:{
        title: 'Cancelled',
        message: 'Cancelled their registration',
        company: compDisplayName,
        category: 'Billing Info',
        user_id: currentUser?.id,
        mission: id,
      }
    })
    MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Registration cancelled',
      timer: 2000,
    })
    setIsModalOpen(false);
  }

  const submitRegistration = (id: string) => {
    // axiosInstance.put(`/api/mission-registrations/${id}`,
    //  {data:{isSubmitted: true,isCancelled: false}})
    MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Successfully Saved Registration Notes',
      timer: 2000,
    })
    setIsModalOpen(false);
  }


  
  const viewReprenstativeMission = (missionId: string, missionRegisrationId: string) => {
    setSelectedMission(missionDetails)
    navigate(`/representative/mission/${missionId}/registration/${missionRegisrationId}`)
  }

  const registerReprenstativeMission = (missionId: string) => {
    setSelectedMission(missionDetails)
    navigate(`/representative/view/${missionId}/registration`)
  }
  const viewRegistration = async (missionId: string,missionRegisrationId: string) => {
    await axiosInstance.get(`/api/missions/${missionId}?populate=missionRegistrationsId.missionMemparticipantsRegistrationId.photo,missionRegistrationsId.memMissionTalkingPoints.missionMeetingsId,missionRegistrationsId.compLogo&filters[missionRegistrationsId][id]=${missionRegisrationId}`)
    .then((res) => {
      const mission = res.data.data.attributes
      const missionRegistrationsIdArray = mission.missionRegistrationsId.data;
      const foundRegistration = missionRegistrationsIdArray.find(registration => registration.id === missionRegisrationId);
      const missionRegistrationsId = foundRegistration.attributes
      const missionMemparticipantsRegistrationId = missionRegistrationsId.missionMemparticipantsRegistrationId.data.map(item => {
        const attributes = item.attributes;
        const photo = item.attributes.photo?.data?.attributes?.url ?? null;
          return {
              ...attributes,
              photo: photo,
          };
      });
      const memMissionTalkingPoints = missionRegistrationsId.memMissionTalkingPoints.data.map(item => {
        const attributes = item?.attributes;
        const missionMeetingsId = item?.attributes?.missionMeetingsId?.data?.attributes;
        return {
          ...attributes,
          missionMeetingsId: missionMeetingsId,
        };
      });

      const formattedData = {
        compDisplayName: missionRegistrationsId.compDisplayName,
        compBio: missionRegistrationsId.compBio,
        isSubmitted: missionRegistrationsId.isSubmitted,
        isCancelled: missionRegistrationsId.isCancelled,
        createdByUser: missionRegistrationsId.createdByUser,
        billingRepName: missionRegistrationsId.billingRepName,
        billingRepTitle: missionRegistrationsId.billingRepTitle,
        billingRepCompany: missionRegistrationsId.billingRepCompany,
        billingAddress: missionRegistrationsId.billingAddress,
        billingPhoneNum: missionRegistrationsId.billingPhoneNum,
        billingEmailAdd: missionRegistrationsId.billingEmailAdd,
        billingSpecIns: missionRegistrationsId.billingSpecIns,
        billingStatus: missionRegistrationsId.billingStatus,
        participantStatus: missionRegistrationsId.participantStatus,
        talkpointsStatus: missionRegistrationsId.talkpointsStatus,
        createdAt: missionRegistrationsId.createdAt,
        updatedAt: missionRegistrationsId.updatedAt,
        publishedAt: missionRegistrationsId.publishedAt,
        companyProfileStatus: missionRegistrationsId.companyProfileStatus,
        submittedCSRStatus: missionRegistrationsId.submittedCSRStatus,
        actions: missionRegistrationsId.actions,
        representativeName: missionRegistrationsId.representativeName,
        billingStatusEx: missionRegistrationsId.billingStatusEx,
        missionMemparticipantsRegistrationId: missionMemparticipantsRegistrationId,
        memMissionTalkingPoints: memMissionTalkingPoints,
        compLogo: missionRegistrationsId.compLogo.data ? missionRegistrationsId.compLogo.data.attributes.url : null,
        id: missionRegisrationId,
      };
      setSummaryInfo(formattedData)
    })
  
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCancel = (missionId: string, missionRegisrationId: string) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.put(`/api/mission-registrations/${missionRegisrationId}`,{data:{
          isCancelled: true,
          missionId: null,
        }})
        axiosInstance.post(`/api/registration-logs`, {
          data:{
            title: 'Cancelled',
            message: 'Cancelled their registration',
            company: compDisplayName,
            category: 'Billing Info',
            user_id: currentUser?.id,
            mission: missionId,
          }
        })
        MySwal.fire(
          'Cancelled!',
          'Your registration has been cancelled.',
          'success'
        ).then((result) => {
          window.location.reload()
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
       
      }
    })
  }

  return (
    <>
     {(isRepresentativeView && !registrationStatus) ? null : (
    <div
      className='card card-custom shadow cardBorderTop'
      style={{
        width: cardWidth,
        height: cardHeight,
        borderTop: !isOtherMission
          ? countDaysLeft(registrationDeadline) === 'Over Due'
            ? '10px solid red'
            : countDaysLeft(registrationDeadline) === 'Today'? '10px solid red' : '10px solid #297eff'
          : '10px solid black',
        marginRight: '20px',
        marginTop: '10px',
        paddingTop: '10px',
      }}
    >
      <div
        className='card-header border-bottom-0 d-block'
        onClick={() =>isRepresentativeView ? '' : viewMission(id)}
        style={{
          minHeight: '125px',
          cursor: isRepresentativeView ? '' :'pointer',
        }}
      >
          <>
            <span
              className={`badge badge-sm mb-2 ${
                registrationStatus ? 'badge-success' : 'badge-danger'
              } text-white mt-1`}
            >
              {registrationStatus ? 'ACTIVE' : 'INACTIVE'}
            </span>{' '}
          </>
        <h1
          className='card-title'
          style={{
            fontSize: '1.75rem',
          }}
        >
          {title}
        </h1>
        <p className='card-text border-bottom-0'>
          <i>{duration} {timezone_abbrev}</i>
        </p>

        {isRepresentativeView && (
          <>
            <p
              className='card-text border-bottom-0'
              style={{
                color:
                  countDaysLeft(registrationDeadline) === 'Today'
                    ? 'red'
                    : countDaysLeft(registrationDeadline) === 'Over Due'
                    ? 'red'
                    : 'black',
              }}
            >
              Reg. Deadline:{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                {registrationDeadline} {timezone_abbrev} ({countDaysLeft(registrationDeadline)}) 
              </span>
              <br />

              {refundCutDate && (
                <>
                  Refund Cut-off Date:{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      color:
                        countDaysLeft(refundCutDate) === 'Today'
                          ? 'red'
                          : countDaysLeft(refundCutDate) === 'Over Due'
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {refundCutDate} {timezone_abbrev} ({countDaysLeft(refundCutDate)})
                  </span>
                </>
              )}
            </p>
            
                  

            {/* !TODO Make this one dynamic */}
            {!isUpcoming && (
              <>
                <span className={`badge ${setColor(billingStatusEx)} text-white`}>Billing</span>{' '}
                <span className={`badge ${setColor(participantStatus)} text-white`}>Participants</span>{' '}
                <span className={`badge ${setColor(talkpointsStatus)} text-white`}>Talking Points</span>{' '}
                <span className={`badge ${setColor(companyProfileStatus)} text-white`}>Company Profile</span>{' '}
                {
                  hasCsr && (<span className={`badge ${setColor(submittedCSRStatus)} text-white`}>CSR</span> )
                }
              </>
            )}
          </>
        )}
      </div>
      <div className='card-body mt-8'>
        <div>
          {isOtherMission ? (
            <p
              className='card-text border-bottom-0'
              style={{
                color: registrationStatus ? 'green' : 'black',
                fontWeight: 'bold',
              }}
            >
              Status: {registrationStatus ? 'ACTIVE' : 'INACTIVE'}
            </p>
          ) : (
            <>
              {!isRepresentativeView && (
                <p className='card-text border-bottom-0'>
                Reg. Deadline:{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color:
                      countDaysLeft(registrationDeadline) === 'Today'
                        ? 'red'
                        : countDaysLeft(registrationDeadline) === 'Over Due'
                        ? 'red'
                        : 'black',
                  }}
                >
                  {registrationDeadline} {timezone_abbrev} ({countDaysLeft(registrationDeadline)}) 
                </span>
                <br />
                {refundCutDate && (
                  <>
                    Refund Cut-off Date:{' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        color:
                          countDaysLeft(refundCutDate) === 'Today'
                            ? 'red'
                            : countDaysLeft(refundCutDate) === 'Over Due'
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {refundCutDate} {timezone_abbrev} ({countDaysLeft(refundCutDate)})
                    </span>
                  </>
                )}
              </p>              
              )}
            </>
          )}
        </div>
      </div>
      <div className='card-footer border-top-0 p-0'>
        { isRepresentativeView && !isUpcoming 
                  ? 
                  <>
                  {!isSubmitted && registrationStatus?
                      <>
                      <div className='row p-5'>
                        <div className='d-flex flex-wrap justify-content-start'>
                          { isPastRefundCutDate ? 
                           <Tooltip 
                           title={<span style={{ fontSize: '1.25rem' }}>You can't cancel because it's way past the refund cut-off date. If you want to cancel, please contact our staff.</span>}
                           placement="bottom"
                           arrow
                         >
                           <ErrorOutlineIcon 
                             color="error" 
                             sx={{ fontSize: 30, margin: '0 10px' }} 
                           />
                         </Tooltip>
                          :
                          <Button type='button' variant='text' color='error' size='small' onClick={()=>handleCancel(id, regId ? regId : '')}>
                            Cancel
                          </Button>
                           }
                          {hasCsr ? (
                          <>
                          {billingStatusEx === 2 && participantStatus === 2 && talkpointsStatus === 2 && companyProfileStatus === 2 && submittedCSRStatus === 2 ? <>
                          <></>
                          </> :
                            <>
                            {!isPastmsEndDate ?
                              <Button type='button' variant='contained' color='primary' size='small' onClick={() => viewReprenstativeMission(id, regId ? regId : '')}>
                              Continue Registration
                            </Button>
                            : <></>}
                           <Button type='button' variant='contained' color='primary' size='small' style={{marginLeft: '10px'}} onClick={() => viewRegistration(id, regId ? regId : '')}>
                           View Registration
                         </Button>
                         </>
                              }
                          </>) :(<>
                            {billingStatusEx === 2 && participantStatus === 2 && talkpointsStatus === 2 && companyProfileStatus === 2 ? <>
                              <Button type='button' variant='contained' color='primary' size='small' style={{marginLeft: '10px'}} onClick={() => viewRegistration(id, regId ? regId : '')}>
                           View Registration
                         </Button>
                            </> :
                            <>
                             {!isPastmsEndDate ? 
                            <Button type='button' variant='contained' color='primary' size='small' onClick={() => viewReprenstativeMission(id, regId ? regId : '')}>
                            Continue Registration
                          </Button>
                          : <></>
                          }
                           <Button type='button' variant='contained' color='primary' size='small' style={{marginLeft: '10px'}} onClick={() => viewRegistration(id, regId ? regId : '')}>
                           View Registration
                          </Button>
                          </>
                              }
                          </>)}
                          {hasCsr ? (
                            <>
                              {billingStatusEx === 2 && participantStatus === 2 && talkpointsStatus === 2 && companyProfileStatus === 2 && submittedCSRStatus === 2 && (
                                <>
                                <Button type='button' variant='contained' color='primary' size='small' style={{marginLeft: '10px'}} onClick={() => viewRegistration(id, regId ? regId : '')}>
                                  View Registration
                                </Button>
                                <Box
                                display="flex"
                                alignItems="center"
                                borderRadius={1}
                                mx={2}
                              >
                                <CheckCircleIcon style={{ color: '#28a745'}} />
                                <Typography variant="h6" style={{ color: '#28a745' }}>
                                  Completed!
                                </Typography>
                              </Box>
                              </>
                              )}
                            </>
                          ) : (
                            <>
                            {billingStatusEx === 2 && participantStatus === 2 && talkpointsStatus === 2 && companyProfileStatus === 2 && (
                             <Box
                                display="flex"
                                alignItems="center"
                                borderRadius={1}
                                mx={2}
                              >
                                <CheckCircleIcon style={{ color: '#28a745'}} />
                                <Typography variant="h6" style={{ color: '#28a745' }}>
                                  Completed!
                                </Typography>
                              </Box>
                                    )}
                              {/* {billingStatusEx === 2 && participantStatus === 2 && talkpointsStatus === 2 && companyProfileStatus === 2 && (
                                <Button type='button' variant='contained' color='primary' size='small' style={{marginLeft: '10px'}} onClick={() => viewRegistration(id, regId ? regId : '')}>
                                  View Registration
                                </Button>
                              )} */}
                            </>
                          )}
                        </div>
                      </div>
                    </>             
                  :
                  <>
                     <div className='row p-5 px-9'>
                      <div className='d-flex flex-wrap justify-content-start'>
                        <Button type='button' variant='contained' color='primary' size='small' onClick={()=>viewRegistration(id,regId? regId: '')}>
                          View Registration
                        </Button>
                      </div>
                    </div>
                    </>
                 }
                  </>
                  : (
                      <>
                      { isRepresentativeView && isUpcoming && (
                         <div className='row p-5 ml-10'>
                          <div className='d-flex flex-wrap justify-content-start'>
                            <Button 
                              type='button' 
                              variant='contained' 
                              color='primary' 
                              size='small'
                              onClick={()=> registerReprenstativeMission(id)}
                              style={{
                                marginLeft:"13px"
                              }}
                            >
                              Register
                            </Button>
                          </div>
                        </div>
                      )}
                      </>
                    )
                }
                  {isModalOpen && (
                      <ParticipantModal
                        show={true}
                        handleClose={handleClose}
                        submitRegistration={submitRegistration}
                        cancelRegistration={cancelRegistration}
                        editRegistrationByStep={() => {}}
                        participant={summaryInfo}
                        //TODO: Rework when there is submitted for registrations
                        isSummary={false}
                        id={id}
                        regId={regId}
                        viewReprenstativeMission={viewReprenstativeMission}
                        isRepresentativeView={isRepresentativeView}
                        registrationStatus={registrationStatus}
                      />
                    )}
        {!isOtherMission && !isRepresentativeView && !isPastMission && (
          <div className='row p-5'>
            <div className='d-flex flex-wrap justify-content-center'>
              <div className='border border-gray-300 border-dashed rounded min-w-75px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='fs-2 fw-bolder'>{//@ts-ignore
                   missionDetails.missionRegistrationsId.data.filter(item => !item.attributes.isCancelled).length ?? 0}</div>
                </div>
                <div className='fw-bold fs-6 text-gray-400'>SUBMITTED</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-75px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='fs-2 fw-bolder'>{//@ts-ignore
                   missionDetails !== undefined && missionDetails.missionRegistrationsId.data && missionDetails.missionRegistrationsId.data.filter((data, index) => 
                     !data.attributes.isCancelled && // Exclude cancelled items
                     (hasCsr ? 
                       (data.attributes.billingStatusEx !== 2 || data.attributes.participantStatus !== 2 || data.attributes.talkpointsStatus !== 2 || data.attributes.companyProfileStatus !== 2 || data.submittedCSRStatus !== 2) :
                       (data.attributes.billingStatusEx !== 2 || data.attributes.participantStatus !== 2 || data.attributes.talkpointsStatus !== 2 || data.attributes.companyProfileStatus !== 2)
                     )
                   ).length}</div>
                </div>
                <div className='fw-bold fs-6 text-gray-400'>INCOMPLETE</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-75px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='fs-2 fw-bolder'>{//@ts-ignore
                 missionDetails !== undefined && missionDetails.missionRegistrationsId.data && missionDetails.missionRegistrationsId.data.filter((data, index) => 
                   !data.attributes.isCancelled && // Exclude cancelled items
                   (hasCsr ? 
                     (data.attributes.billingStatusEx === 2 && data.attributes.participantStatus === 2 && data.attributes.talkpointsStatus === 2 && data.attributes.companyProfileStatus === 2 && data.submittedCSRStatus === 2) :
                     (data.attributes.billingStatusEx === 2 && data.attributes.participantStatus === 2 && data.attributes.talkpointsStatus === 2 && data.attributes.companyProfileStatus === 2)
                   )
                 ).length}</div>
                </div>
                <div className='fw-bold fs-6 text-gray-400'>COMPLETE</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
     )}
     </>
  )
}

export default Cards
