import axios, { AxiosInstance } from 'axios';
import * as authHelper from '../app/modules/auth/core/AuthHelpers';

const axiosInstanceFormData: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

axiosInstanceFormData.interceptors.request.use(
  (config) => {
      config.headers['Authorization'] = `Bearer ${authHelper.getToken()}`
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceFormData.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstanceFormData;
