import {Box, Typography, Grid, InputLabel, TextField, Button, Container, FormControlLabel, Checkbox, FormGroup, Radio, RadioGroup, FormControl, Avatar} from '@mui/material'
import MySwal from '../../../../../utils/sweetAlert'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
//@ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {useState, ChangeEvent, FormEvent, useEffect} from 'react'
import {IStepperProps, TMembers} from '../../../../../types/types'
import {useMission} from '../../core/MissionProvider'
import axiosInstance from '../../../../../utils/axios'
import axiosInstanceFormData from '../../../../../utils/axiosFormData'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'


const CompanyProfile: React.FC<IStepperProps> = ({
  activeStep,
  setActiveStep,
  CsrBoolean,
  isRepresentativeView,
}) => {
  const companyDefaults = {
    id: 0,
    memberId: '',
    displayName: '',
    isActive: true,
    memberSince: '',
    memberBio: '',
    compBio: '',
    logo: '',
    compLogo: null,
    referredName: '',
    participationPublic: null,
  }

  const [company, setCompany] = useState<
    TMembers & {
      compLogo: Blob | null,
    }
  >(companyDefaults)
  const [isCompanyLogoUpdated, setIsCompanyLogoUpdated] = useState<boolean>(false)

  const [bioCharCount, setBioCharCount] = useState<number>(0)
  const {setLoadingScreen, currentMissionRegistration} = useMission()
  const {missionRegistrationId, missionId} = useParams()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [hasDeclined, setHasDeclined] = useState(false)
  const {currentUser} = useAuth()
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');
  
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasDeclined(event.target.checked)
  }
  const fetchMissionRegistration = async () => {
    try {
      await axiosInstance
        .get(`/api/mission-registrations/${missionRegistrationId}?populate=compLogo`)
        .then(({data}) => {
          const companyDetails = data.data
          setCompany({
            ...companyDetails.attributes,
            id: companyDetails.id,
            displayName:
              companyDetails.attributes.compDisplayName ??
              companyDetails.attributes.billingRepCompany,
            compLogo: companyDetails.attributes.compLogo.data
              ? companyDetails.attributes.compLogo.data.attributes
              : null ,
            compBio: companyDetails.attributes.compBio ? companyDetails.attributes.compBio : null,
            referredName: companyDetails.attributes.referredName ? companyDetails.attributes.referredName : null,
            participationPublic: companyDetails.attributes.participationPublic !== null ? companyDetails.attributes.participationPublic === true ? 'true' : 'false' : null,
          })
          setHasDeclined(companyDetails.attributes.hasDeclinedLogo)
          setLoadingScreen(false)
        })
    } catch (error) {
      console.error(error)
      setLoadingScreen(false)
    }
  }
  useEffect(()=>{
    try{
      if(company.id){
        axiosInstance
        .get(`/api/mission-registrations/${company.id}?populate=memberId,memberId.logo`)
        .then((res)=>{
        const companyDetails = res.data.data.attributes.memberId.data
        //@ts-ignore
        setCompany(prevCompany => ({
          ...prevCompany,
          compLogo: companyDetails.attributes.logo.data
            ? companyDetails.attributes.logo.data.attributes
            : null ,
          compBio: companyDetails.attributes.memberBio ? companyDetails.attributes.memberBio : null
        }));
      })
      }
  } catch (error) {
    console.error(error)
    setLoadingScreen(false)
  }
  },[company.id])

  useEffect(() => {
    if (missionRegistrationId) {
      setLoadingScreen(true)
      fetchMissionRegistration()
    }
    else if(currentMissionRegistration){
      //@ts-ignore
      setCompany(prevCompany =>({
        ...prevCompany,
        id: currentMissionRegistration.id,
        displayName: currentMissionRegistration.compDisplayName,
        compBio: currentMissionRegistration.compBio,
      }))
    }
  }, [missionRegistrationId])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setCompany((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isYes = value === "true";

    setCompany((prevData) => ({
      ...prevData,
      [name]: isYes ? true : false,
      referredName: isYes && !prevData.referredName ? prevData.displayName : prevData.referredName,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!allowedFileTypes.includes(file.type)) {
        MySwal.fire({
          text: 'Please select a valid JPEG or PNG image.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      } else {
        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          MySwal.fire({
            text: 'File size exceeds the limit of 5 MB.',
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        } else {
          setCompany((prevFormData) => ({
            ...prevFormData,
            compLogo: file,
          }));
          setIsCompanyLogoUpdated(true);
        }
      }
    }
  };
  
  const submitHandler = async (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    const payload = {
      compDisplayName: company.displayName,
      compBio: company.compBio,
      referredName: company.referredName,
      participationPublic: company.participationPublic
    }
    if (!company.displayName || (!hasDeclined && !company.compLogo)) {
      MySwal.fire({
        text: 'Please fill in all required fields.',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
      return
    }

    if (missionRegistrationId || currentMissionRegistration) {
      let dataAdd = {}
      const hasAnyFieldNullOrEmpty = hasDeclined ? payload.compDisplayName === '' || payload.compBio === '' : payload.compDisplayName === '' || payload.compBio === '' || !company.compLogo
      const allTalkingPointsHaveData = hasDeclined ?
        payload.compDisplayName !== '' && payload.compBio !== '' :
        payload.compDisplayName !== '' && payload.compBio !== '' && company.compLogo 
      if (hasAnyFieldNullOrEmpty) {
        dataAdd = {...payload, companyProfileStatus: 1, billingStatus: 2}
      }
      if (allTalkingPointsHaveData) {
        dataAdd = {...payload, companyProfileStatus: 2, billingStatus: 2}
      }
      if(hasDeclined){
        dataAdd= {...dataAdd,hasDeclinedLogo: true}
      }
      else{
        dataAdd= {...dataAdd,hasDeclinedLogo: false}
      }

      try {
        setLoadingScreen(true)
        await axiosInstance.put(`/api/mission-registrations/${missionRegistrationId ? missionRegistrationId : currentMissionRegistration?.id}`, {
          data: dataAdd,
        })
        if (missionRegistrationId) {
          await axiosInstance.post(`/api/registration-logs`, {
            data:{
              title: 'Updated',
              message: 'Updated the Company Profile',
              //@ts-ignore
              company: dataAdd.compDisplayName,
              category: 'Company',
              user_id: currentUser?.id,
              mission: missionId,
            }
          })
        }else{
          await axiosInstance.post(`/api/registration-logs`, {
            data:{
              title: 'Created',
              message: 'Created the Company Profile',
              //@ts-ignore
              company: dataAdd.compDisplayName,
              category: 'Company',
              user_id: currentUser?.id,
              mission: missionId,
            }
          })
        }
        if (isCompanyLogoUpdated && company.compLogo && !hasDeclined) {
          const formData = new FormData()
          formData.append('files', company.compLogo)
          formData.append('refId', `${missionRegistrationId ? missionRegistrationId : currentMissionRegistration?.id}`)
          formData.append('ref', 'api::mission-registration.mission-registration')
          formData.append('field', 'compLogo')
          formData.append('path', 'compLogo')
          await axiosInstanceFormData.post('/api/upload', formData)
        }
        if(company.id){
          await axiosInstance.get(`/api/mission-registrations/${company.id}?populate=memberId`)
          .then((res)=>{
                 axiosInstance.put(`/api/members/${res.data.data.attributes.memberId.data.id}`,{
                  data:{
                    memberBio: payload.compBio
                  }
                })
                if (isCompanyLogoUpdated && company.compLogo && !hasDeclined) {
                      const formData = new FormData()
                      formData.append('files', company.compLogo)
                      formData.append('refId', `${res.data.data.attributes.memberId.data.id}`)
                      formData.append('ref', 'api::member.member')
                      formData.append('field', 'logo')
                      formData.append('path', 'logo')
                      axiosInstanceFormData.post('/api/upload', formData)
              }
          })
        }
        setLoadingScreen(false)
        MySwal.fire({
          title: 'Company Registration Updated',
          icon: 'success',
          confirmButtonColor: '#1976d2',
          confirmButtonText: 'Next',
        }).then(() => {
          if (!CsrBoolean) {
            if (isRepresentativeView) {
              window.location.href = `/representative`
            } else {
              window.location.href = `/missions/view/${missionId}`
            }
          } else {
            setActiveStep(activeStep + 1)
          }
        })
      } catch (error) {
        MySwal.fire({
          text: 'Something went wrong',
          timer: 2000,
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
        })
      }
    } else {
      console.log('Current mission registration does not exist.')
    }
  }
  console.log(company)

  return (
  <Box
    sx={{
      width: '100%',
      marginTop: 5,
    }}
  >
    <form onSubmit={submitHandler}>
      <Container>
        <Typography variant='h5'>
          Manage company profile information for this mission.
        </Typography>
        <Grid container spacing={2} mt={0.2}>
          {/* Left side: Form and Logo Upload */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='companyName'>
                  Company Display Name
                </InputLabel>
                <TextField
                  name='displayName'
                  fullWidth
                  value={company.displayName}
                  onChange={handleChange}
                  error={formSubmitted && !company.displayName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                sx={{
                  display: 'block',  
                  whiteSpace: 'normal', 
                  width: '100%',  
                }}>Would you like your participation in the mission to be mentioned in 
                any public materials (e.g., press releases, social media posts) from USABC? </InputLabel>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="participationPublic"
                    name="participationPublic"
                    value={company.participationPublic === null ? "" : String(company.participationPublic)}
                    onChange={handleRadioButtonChange}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor='companyLogo'
                  sx={{
                    marginTop: 2,
                  }}
                >
                  Company Logo{' '}
                </InputLabel>
                {hasDeclined ? (
                  <></>
                ) : (
                  <>
                    {company.compLogo ? (
                      <div
                        style={{
                          border: '1px dashed #ccc',
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          maxWidth: '500px',
                          maxHeight: '500px',
                          marginBottom: '5px',
                        }}
                      >
                        <img
                          src={company.compLogo['url'] ?? URL.createObjectURL(company.compLogo)}
                          alt='Company Logo'
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            padding: '10px',
                            marginBottom: '5px',
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          border: '1px dashed #ccc',
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          maxWidth: '500px',
                          maxHeight: '500px',
                          marginBottom: '5px',
                        }}
                      >
                        No logo uploaded
                      </div>
                    )}
                    <input
                      key={company.id + 'input_key'}
                      accept='.jpg, .jpeg, .png'
                      style={{ display: 'none', height: '1.4375em' }}
                      id='raised-button-file'
                      multiple
                      type='file'
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor='raised-button-file'
                      style={{
                        width: '100%',
                      }}
                    >
                      {formSubmitted && !company.compLogo && (
                        <span style={{ color: 'red' }}>
                          Please fill out Company Logo
                        </span>
                      )}
                      <Button
                        variant='contained'
                        component='span'
                        fullWidth
                        sx={{
                          marginTop: 1,
                        }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                  </>
                )}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='edit-csr-decline'
                          name='csr_decline'
                          checked={hasDeclined}
                          onChange={handleCheckboxChange}
                          color='primary'
                        />
                      }
                      label={
                        //@ts-ignore
                        <strong>
                          {currentMissionRegistration?.compDisplayName} will not provide logo
                          for this registration.
                        </strong>
                      }
                    />
                  </FormGroup>
                </Grid>
                <TextField
                  size='small'
                  key={company.id}
                  value={company.compLogo ? company.compLogo.name : ''}
                  fullWidth
                  style={{
                    display: 'none',
                  }}
                  InputProps={{
                    endAdornment: (
                      <label htmlFor='raised-button-file'>
                        <Button variant='contained' component='span' size='small'>
                          Upload
                        </Button>
                      </label>
                    ),
                  }}
                  error={formSubmitted && !company.compLogo}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side: Company Bio */}
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
            <InputLabel
                 sx={{
                  display: 'block',  
                  whiteSpace: 'normal', 
                  width: '100%',  
                }}>Name of company</InputLabel>
                <TextField
                  fullWidth
                  name="referredName"
                  value={company.referredName}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
                <InputLabel
                 sx={{
                  display: 'block',  
                  whiteSpace: 'normal', 
                  width: '100%',  
                }}><i>Please fill out the ‘Name of participating company’ exactly 
                as you would like it to be referred to during the mission 
                (on tent cards and other signage). This name will also be used on 
                any public materials about the mission, should you choose to be mentioned:</i></InputLabel>
              </Grid>
              <div id='editor1' style={{ paddingTop: '10px' }}>
              <InputLabel htmlFor='editor'>Company Profile</InputLabel>

              <CKEditor
                editor={Editor}
                id={`${company.id}`}
                onReady={(editor) => {
                  editor.setData(company.compBio ?? ' ')
                }}
                onChange={(event, editor) => {
                  const wordCountPlugin = editor.plugins.get('WordCount')
                  setBioCharCount(wordCountPlugin.characters)
                  const data = editor.getData()

                  setCompany((prevData) => ({
                    ...prevData,
                    compBio: data,
                  }))
                }}
              />
            </div>
            <div
              id='word-count'
              style={{
                textAlign: 'right',
                color: bioCharCount > 2500 ? 'red' : 'black',
              }}
            >
              Character count: {bioCharCount}/2500
            </div>
          </Grid>
        </Grid>

        {/* Footer buttons */}
        <Grid mt={2} textAlign='right' xs={12}>
          {missionRegistrationId && (
            <Button
              type='submit'
              variant='outlined'
              color='primary'
              sx={{
                marginRight: 2,
              }}
              onClick={() => {
                if (!CsrBoolean) {
                  if (isRepresentativeView) {
                    window.location.href = `/representative`
                  } else {
                    window.location.href = `/missions/view/${missionId}`
                  }
                } else {
                  setActiveStep(activeStep + 1)
                }
              }}
            >
              Continue Without Saving
            </Button>
          )}
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={submitHandler}
          >
            Next
          </Button>
          <Button
            type='button'
            variant='contained'
            color='primary'
            sx={{
              float: 'left',
              marginRight: 2,
            }}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
          {showBackToDetailsButton && (
            <Button
              type='button'
              variant='contained'
              color='primary'
              href={`/missions/view/${missionId}`}
              sx={{
                float: 'left',
              }}
            >
              Back to Details Page
            </Button>
          )}
        </Grid>
      </Container>
    </form>
  </Box>
)
}

export default CompanyProfile
