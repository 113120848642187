import React, {useState} from 'react'
import pngLogo from '../../../../assets/logo/usasean_logo.png.png'
import {z} from 'zod'
import axios from 'axios'

const TestLogin: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessages, setErrorMessages] = useState({
    username: '',
    password: '',
  })

  const apiToken = localStorage.getItem('apiToken')

  const login = () => {
    if (apiToken) {
      window.location.href = `/`
    }
    window.location.href = `${process.env.REACT_APP_BACKEND_HOST}/api/verify`
  }

  const handleLogin = () => {
    const userSchema = z.object({
      username: z.string().email('Please enter a valid email address'),
      password: z.string().min(6, 'Password must be at least 6 characters long'),
    })

    const inputData = {username, password}
    const validationErrors = {}

    try {
      userSchema.parse(inputData)

      axios
        .post(`${process.env.REACT_APP_BACKEND_HOST}/api/auth/local`, {
          identifier: inputData.username,
          password: inputData.password,
        })
        .then((res) => {
          const currentTimestampInSeconds = Math.floor(Date.now() / 1000)
          const expirationTimeInSeconds = currentTimestampInSeconds + 5 * 60 * 60
          window.location.href = `/callback/${res.data.jwt}/${res.data.user.id}/${expirationTimeInSeconds}`
        })
        .catch(() => {
          setErrorMessages({
            ...errorMessages,
            password: 'Login failed. Please check your credentials.',
          })
        })
    } catch (validationError) {
      //@ts-ignore
      const fieldErrors = validationError.errors.reduce((errors, error) => {
        errors[error.path[0]] = error.message
        return errors
      }, {})

      setErrorMessages(fieldErrors)
    }
  }

  return (
    <div className='form w-100' id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center'>
        <img src={pngLogo} className='h-250px' alt='Logo' />
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-2'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          id='username'
          className='form-control'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type='email'
          autoComplete='off'
        />
        {errorMessages.username && <div className='text-danger'>{errorMessages.username}</div>}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          id='password'
          className='form-control'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errorMessages.password && <div className='text-danger'>{errorMessages.password}</div>}
      </div>

      <div className='d-grid mb-10'>
        <button className='btn btn-primary' onClick={handleLogin}>
          <span className='indicator-label'>Login</span>
        </button>
        <a
          href='#'
          onClick={login}
          className='btn btn-flex mt-2 btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          Sign in with GrowthZone
        </a>
      </div>
    </div>
  )
}

export default TestLogin
