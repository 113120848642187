import React from 'react'
import {IParticipantInformation, TMissionTalkingPoints} from '../../../../../types/types'

type MeetingTalkingPointsCardProps = {
  memMissionTalkingPoints: TMissionTalkingPoints[]
  triggerEdit: (step: number) => void
  isSummary: boolean
  hasPermission?: boolean
}

const MeetingTalkingPointsCard: React.FC<MeetingTalkingPointsCardProps> = ({
  memMissionTalkingPoints,
  triggerEdit,
  isSummary,
  hasPermission
}) => {
  const booleanToYesNo = (boolean: boolean) => {
    return boolean ? 'Yes' : 'No'
  }
  const order = [
    "Prime Minister",
    "State President",
    "National Assembly",
    "Central Party Committee on Economic Affairs",
    "Ministry of Public Security",
    "Ministry of National Defense",
    "Ministry of Planning and Investment",
    "Ministry of Natural Resources and Environment",
    "Ministry of Industry and Trade",
    "Ministry of Information and Communications",
    "Ministry of Finance",
    "State Bank of Vietnam",
    "Ministry of Science and Technology",
    "Ministry of Agriculture and Rural Development",
    "Ministry of Transport",
    "Ministry of Labor, Invalids and Social Affairs",
    "Ministry of Education and Training",
    "Ministry of Culture, Sports and Tourism",
    "Ministry of Justice",
    "U.S. Embassy",
    "Economists Luncheon (World Bank, International Monetary Fund, International Finance Corporation, National Financial Supervisory Commission)",
    "Electricity of Vietnam (EVN)",
    "PetroVietnam (PVN)"
  ];
  const sortByMeetingName = (a, b) => {
    const indexA = order.indexOf(a.missionMeetingsId.name.trim());
    const indexB = order.indexOf(b.missionMeetingsId.name.trim());
    const largeIndex = order.length; // Use for non-matching entries to sort them at the bottom
    return (indexA === -1 ? largeIndex : indexA) - (indexB === -1 ? largeIndex : indexB);
  };
  const sortedMemMissionTalkingPoints = memMissionTalkingPoints.sort(sortByMeetingName);
  return (
    <>
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>Meetings & Talking Points</h3>
          </div>
          <div className='card-toolbar'>
            {hasPermission && (<>
                  {isSummary ? <></> :
                <i
                className='btn btn-sm btn-icon btn-light-primary bi-pencil-square fs-4'
                onClick={() => {
                  triggerEdit(3)
                }}
              ></i>}
            </>)}
          </div>
        </div>
        <div className='card-body'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th scope='col'>
                  <b>Speaking Role</b>
                </th>
                <th scope='col'>
                  <b>Company Going</b>
                </th>
                <th scope='col'>
                  <b>Submitted Talking Points</b>
                </th>
                <th scope='col'>
                  <b>Meeting Name</b>
                </th>
                <th scope='col'>
                  <b>Talking Point</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedMemMissionTalkingPoints.map((talkingPoints, index) => (
                <tr key={index}>
                  <td>{booleanToYesNo(talkingPoints.speakingRole)}</td>
                  <td>{booleanToYesNo(talkingPoints.companyGoing)}</td>
                  <td>{booleanToYesNo(talkingPoints.submitTalkingPoints)}</td>
                  <td>
                    <b>{talkingPoints.missionMeetingsId.name}</b>
                  </td>
                  <td dangerouslySetInnerHTML={{__html: talkingPoints.talkingPoints}} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default MeetingTalkingPointsCard
