import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    window.location.href = `${process.env.REACT_APP_API_LOGOUT_URL}&redirect_uri=${process.env.REACT_APP_FRONTEND_HOST}`
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/' />
    </Routes>
  )
}
