import React from 'react'
import {TBillingInformation} from '../../../../../types/types'

type BillingInformationCardProps = {
  billingInformation: TBillingInformation
  triggerEdit: (step: number) => void
  isSummary: boolean
  hasPermission?: boolean
}

const BillingInformationCard: React.FC<BillingInformationCardProps> = ({billingInformation, triggerEdit,isSummary,hasPermission}) => {
  return (
    <div className='card card-custom gutter-b'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='card-label'>
            Billing Information
            {/* <small>sub title</small> */}
          </h3>
        </div>
        <div className='card-toolbar'>
          {hasPermission && (<>
            {isSummary ? <></> :
          <i className='btn btn-sm btn-icon btn-light-primary bi-pencil-square fs-4' onClick={() => {
            triggerEdit(1)
          }}></i>}
          </>)}       
        </div>
      </div>
      <div className='card-body'>
        <div className='row mb-4'>
          <span>
            <b>{billingInformation.billingRepName}</b> ({billingInformation.billingRepTitle})
          </span>
          <span>
            {billingInformation.billingPhoneNum} /{' '}
            <a href={`mailto:${billingInformation.billingEmailAdd}`}>
              {billingInformation.billingEmailAdd}
            </a>
          </span>
        </div>
        <div className='row mb-4'>
          <span>
            <b>{billingInformation.billingRepCompany}</b>
          </span>
          <span>{billingInformation.billingAddress}</span>{' '}
          {/* TODO: format display (currently in straight line) */}
        </div>
        <div className='row'>
          <span>
            <b>Special Instructions:</b>
            <span dangerouslySetInnerHTML={{__html: billingInformation.billingSpecIns}} />
          </span>
        </div>
      </div>
    </div>
  )
}

export default BillingInformationCard
