import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import Login from './components/Login'
import StrapiLogin from './components/StrapiLogin'
import StaffLogin from './components/StaffLogin'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='/login' element={<Login />} />
      <Route path='/staff' element={<StaffLogin />} />
      <Route path='/strapi' element={<StrapiLogin />} />
      {process.env.REACT_APP_TEST === 'true' ? <Route index element={<StrapiLogin />} />:<Route index element={<Login />} />}
    </Route>
  </Routes>
)

export {AuthPage}
