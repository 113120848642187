import CheckIcon from '@mui/icons-material/Check'
import {Button, Container, List, ListItem, ListItemText} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import {TParticpantInfo, TUser} from '../../../../../types/types'
import AddParticipant from './AddParticipantList'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '500px',
  bgcolor: '#F5F5F5',
  border: '1px solid #ffff',
  boxShadow: 1,
  p: 4,
}

type ParticipantSelectorModalProps = {
  selectedMemberParticipants: TParticpantInfo[]
  memberParticipants: TUser[]
  setSelectedMemberParticipants: React.Dispatch<React.SetStateAction<TParticpantInfo[]>>
  setOpenMemberParticipantModel: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  openAdd: boolean
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>,
  handleAddParticipant: (participant: any) => void;
}
const ParticipantSelectorModal: React.FC<ParticipantSelectorModalProps> = ({
  selectedMemberParticipants,
  memberParticipants,
  setSelectedMemberParticipants,
  setOpenMemberParticipantModel,
  open,
  openAdd,
  setOpenAdd,
  handleAddParticipant
}) => {
  const selectedMemberParticipantsHandler = (id: number,memParticipant) => {
    if (!selectedMemberParticipants.find((memPar) => memPar.id === id))
      setSelectedMemberParticipants((prev: any) => [
        ...prev,
        {
          ...memberParticipants.find((memPar) => memPar.id === id),
          dietaryRestrictions: memParticipant ? memParticipant.dietaryRestrictions : '',
          contactNumber: memParticipant ? memParticipant.contactNumber : '',
          participantEmail: memParticipant ? memParticipant.participantEmail: '',
          bio: memParticipant ? memParticipant.bio: '',
          emergencyContactName: memParticipant ? memParticipant.emergencyContactName: '',
          emergencyRelationships: memParticipant ? memParticipant.emergencyRelationships: '',
          emergencyEmail: memParticipant ? memParticipant.emergencyEmail: '',
          emergencyContact: memParticipant ? memParticipant.emergencyContact: '',
        },
      ]);
    else {
      setSelectedMemberParticipants((prev: any) => prev.filter((memPar) => memPar.id !== id))
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      if (selectedMemberParticipants.length === 0) return
    }
    setOpenMemberParticipantModel(false)
  }

  return (
    <div>
      <Modal
        open={open}
        disableAutoFocus
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={style}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 10px',
            justifyContent: 'space-between',
          }}
        >
          <ListItem
            divider
            sx={{
              display: 'block',
              textAlign: 'center',
            }}
          >
            <Typography textAlign='left' fontSize={18}>
              {' '}
              Please select one or more representatives who will participate the mission.{' '}
            </Typography>
          </ListItem>
          <List style={{width: '100%', height: '350px', overflow: 'auto'}}>
            {memberParticipants.map((participant, index) => (
              <ListItem
                key={index}
                divider
                onClick={() => selectedMemberParticipantsHandler(participant.id,participant.missionMemparticipantUserId)}
                sx={{
                  height: '65px',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#E0E0E0',
                  },
                  '&.active': {
                    backgroundColor: '#BBDEFB',
                  },
                }}
              >
                {selectedMemberParticipants.find((memPar) => memPar.id === participant.id) ? (
                  <>
                    <ListItemText
                      primary={`${participant.firstName} ${participant.lastName} - ${participant.jobTitle}`}
                    />
                    <CheckIcon />
                  </>
                ) : (
                  <ListItemText
                    primary={`${participant.firstName} ${participant.lastName} - ${participant.jobTitle} `}
                  />
                )}
              </ListItem>
            ))}
             <ListItem>
              <Button
                color='inherit'
                variant='outlined'
                fullWidth={true}
                
                style={{marginBottom: '10px', marginTop: '10px'}}
                onClick={() => setOpenAdd(true)}
              >
                Add New Participant
              </Button>
             </ListItem>
          </List>

          <Container>
          <AddParticipant onAddParticipant={handleAddParticipant} openAdd={openAdd} setOpenAdd={setOpenAdd} />
          <Button
            color='error'
            variant='contained'
            style={{marginBottom: '10px',float: 'right'}}
            onClick={() => setOpenMemberParticipantModel(false)}
          >
            Close
          </Button>
          </Container>
        </Box>
      </Modal>
    </div>
  )
}

export default ParticipantSelectorModal
