import React from 'react'
import {useParams} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import {useMission} from '../../mission/core/MissionProvider'

const CallBack: React.FC = () => {
  const {verificationToken} = useParams()
  const {userId} = useParams()
  const {expToken} = useParams()
  const {getCurrentUser} = useAuth()

  const updateLocalStorageAndNavigate = async () => {
    const tokenDetails = {
      token: verificationToken,
      exp: expToken,
    }

    localStorage.setItem('usasean-pkey', JSON.stringify(tokenDetails))
    localStorage.setItem('userId', JSON.stringify(userId))
    getCurrentUser()
  }

  updateLocalStorageAndNavigate()

  return null
}

export default CallBack
