import AddIcon from '@mui/icons-material/Add'
import {Button, List, ListItem, ListItemText, Typography} from '@mui/material'
import React  from 'react'
import {IParticipantInformation, TParticpantInfo, TUser} from '../../../../../types/types'
import {useMission} from '../../core/MissionProvider'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'


interface ParticipantListProps {
  addParticipantHandler: () => void
  getParticipant: (id: number,index:number) => void
  deleteParticipant: (id: number, memParticipantId: number,index: number) => void
  memberParticipants: TParticpantInfo[]
  currentActiveId: number | undefined
}


const ParticipantList: React.FC<ParticipantListProps> = ({
  addParticipantHandler,
  getParticipant,
  deleteParticipant,
  memberParticipants,
  currentActiveId,
}) => {
  

  const selectedStyle = {
    backgroundColor: 'rgb(251, 248, 248)',
    padding: '5px',
    borderRadius: '5px',
  };

  const notSelected = {
    backgroundColor: 'rgb(251, 248, 248)',
    padding: '5px',
    borderRadius: '5px',
    cursor: "pointer",
  };

  const savedStyle = {
    border: '2px solid #00ff00', // Green border glow
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const notSavedStyle = {
    border: '2px solid #ff0000', // Red border glow
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  const temporarySavedStyle = {
    border: '2px solid #ffff00', // Yellow border glow
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  
  return (
    <>
      <div className='card card-xl-stretch mb-xl-3'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark fs-5'>Participants</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>joining the mission</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              
              onClick={addParticipantHandler}
            >
              <KTIcon iconName='plus' className='fs-2' />
            </button>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-5'>
          {/* begin::Item */}
          {memberParticipants.map((participant, index) => (
            <React.Fragment key={participant.id}>
              <div 
                 style={ participant.memParticipantId
                  ?  (
                    !participant.dietaryRestrictions ||
                    !participant.contactNumber ||
                    !participant.emergencyContactName ||
                    !participant.emergencyEmail ||
                    !participant.emergencyContact ||
                    !participant.bio ||
                    !participant.emergencyRelationships ||
                    !participant.participantPhoto ||
                    !participant.participantEmail
                  )
                    ? temporarySavedStyle
                    : savedStyle
                    : notSavedStyle}
                className='d-flex align-items-sm-center mb-7 participant-container P-5'
                onClick={() => getParticipant(participant.id,index)} 
              >
                
              
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-5'>
                  <span className='symbol-label'>
                    <img
                      src={toAbsoluteUrl('/media/avatars/blank.png')}
                      className='h-50 align-self-center'
                      alt=''
                    />
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Section */}
                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                  <div className='flex-grow-1 me-2'>
                    <span
                      className='text-gray-800 text-hover-primary fs-6 fw-bold'
                    >
                      {`${participant.firstName}  ${participant.lastName}`} 
                    </span>
                    <span className='text-muted fw-semibold d-block fs-7'>{`${participant.jobTitle}`}</span>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-default btn-active-light-danger'
                    onClick={()=> deleteParticipant(participant.id, participant.memParticipantId,index)} // !TODO Need to add confirmation dialog
                  >
                    <KTIcon iconName='trash' className='fs-6' />
                  </button>
                </div>
                {/* end::Section */}
              </div>
            </React.Fragment>
          ))}
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default ParticipantList
