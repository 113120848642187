import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import {
  DataGrid,
  GridCellEditStopParams,
  GridColDef,
  GridRenderEditCellParams,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {IStepperProps, ITalkingPoints, TTalkingPoints} from '../../../../../types/types'
import axiosInstance from '../../../../../utils/axios'
import MySwal from '../../../../../utils/sweetAlert'
import CustomNoRowsOverlay from '../../../../common/components/TableNoDataOverlay'
import {useMission} from '../../core/MissionProvider'
import {useAuth} from '../../../auth'

const TalkingPoints: React.FC<ITalkingPoints & IStepperProps> = ({activeStep, setActiveStep}) => {
  const [talkingPoints, setTalkingPoints] = useState<TTalkingPoints[]>([])
  const {currentMissionRegistration, setLoadingScreen} = useMission()
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [noMeeting, setNoMeeting] = useState(false)
  const [meetingLength, setMeetingLength] = useState()
  const {missionId, missionRegistrationId} = useParams()
  const [companyName, setCompanyName]= useState()
  const {currentUser} = useAuth()
  const [estimatedInvestments, setEstimatedInvestments] = useState('');
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');

  const handleChange = (event) => {
    setEstimatedInvestments(event.target.value);
  };

  useEffect(() => {
    if(currentMissionRegistration) {
      fetchTalkingPoints()
      axiosInstance.get(`/api/mission-registrations/${currentMissionRegistration.id}`)
      .then((response)=>{
        setCompanyName(response.data.data.attributes.compDisplayName)
        setEstimatedInvestments(response.data.data.attributes.estimatedInvestments)
      })
    }
    if(missionRegistrationId){
      fetchTalkingPoints()
      axiosInstance.get(`/api/mission-registrations/${missionRegistrationId}`)
      .then((response)=>{
        setCompanyName(response.data.data.attributes.compDisplayName)
        setEstimatedInvestments(response.data.data.attributes.estimatedInvestments)
      })
    }
  }, [currentMissionRegistration,missionRegistrationId])

  useEffect(() => {
    if (talkingPoints.length > 0) {
      talkingPoints.find(
        (talkingPoint) =>
          talkingPoint.speakingRole === 'true' && talkingPoint.talkingPoints.length < 1
      )
        ? setIsNextButtonDisabled(true)
        : setIsNextButtonDisabled(false)
    }
  }, [talkingPoints, isNextButtonDisabled, setIsNextButtonDisabled])

  const disableNextButton = () =>
    talkingPoints.find(
      (talkingPoint) =>
        talkingPoint.speakingRole === 'true' && talkingPoint.talkingPoints.length < 1
    )
      ? setIsNextButtonDisabled(true)
      : setIsNextButtonDisabled(false)

  const fetchTalkingPoints = async () => {
    setLoadingScreen(true)
    if (typeof currentMissionRegistration !== 'undefined') {
      const response = await axiosInstance.get(
        `/api/mission-meetings?populate[talkingPoints][filters][memMissionRegistration][id][$eq]=${currentMissionRegistration.id}&filters[missionMeetings][id][$eq]=${missionId}`
      )
      setMeetingLength(response.data.data.length)
      if(response.data.data.length === 0){
        setNoMeeting(true)
      }
      else{
        setNoMeeting(false)
      }
      if (response) {
        const order = [
          "Prime Minister",
          "State President",
          "National Assembly",
          "Central Party Committee on Economic Affairs",
          "Ministry of Public Security",
          "Ministry of National Defense",
          "Ministry of Planning and Investment",
          "Ministry of Natural Resources and Environment",
          "Ministry of Industry and Trade",
          "Ministry of Information and Communications",
          "Ministry of Finance",
          "State Bank of Vietnam",
          "Ministry of Science and Technology",
          "Ministry of Agriculture and Rural Development",
          "Ministry of Transport",
          "Ministry of Labor, Invalids and Social Affairs",
          "Ministry of Education and Training",
          "Ministry of Culture, Sports and Tourism",
          "Ministry of Justice",
          "U.S. Embassy",
          "Economists Luncheon (World Bank, International Monetary Fund, International Finance Corporation, National Financial Supervisory Commission)",
          "Electricity of Vietnam (EVN)",
          "PetroVietnam (PVN)"
        ];

          const sortedMissionMeetings = response.data.data.sort((a, b) => {
              const indexA = order.indexOf(a.attributes.name.trim());
              const indexB = order.indexOf(b.attributes.name.trim());
              const largeIndex = order.length + 1;
              return (indexA === -1 ? largeIndex : indexA) - (indexB === -1 ? largeIndex : indexB);
            });
        setTalkingPoints(
          sortedMissionMeetings.map((talkingPoint, index) => ({
            missionMeetingId: talkingPoint.id, // Field set to required but no need - had to set to 0
            missionMeetingName: talkingPoint.attributes.relatedTopic ? `${talkingPoint.attributes.name} - ${talkingPoint.attributes.relatedTopic}` : talkingPoint.attributes.name , // Field set to required but no need - had to set to 0
            userId: JSON.parse(localStorage.getItem('userId') || '{}'), // Field set to required but no need - had to set to 0
            priority: 'false', // Field set to required but no need - had to set to false
            talkingPoints:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].attributes.talkingPoints
                : '',
            speakingRole:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].attributes.speakingRole
                : '',
            companyGoing:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].attributes.companyGoing
                : '',
            submitTalkingPoints:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].attributes.submitTalkingPoints
                : '',
            notes:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].attributes.notes
                : '',
            talkingPointId:
              talkingPoint.attributes.talkingPoints.data.length > 0
                ? talkingPoint.attributes.talkingPoints.data[0].id
                : '',
            id: index, // table key id
          }))
        )
      }
    } else {
      console.error('Cannot load talking points without a current mission registration')
    }
    setLoadingScreen(false)
  }

  const isTruthy = new RegExp('true')
  const handleSubmit = async () => {
    setLoadingScreen(true);
    let logType = '';
    const talkingPointsRequests: Promise<any>[] = [];
    async function updateTalkingPoints(talkingPoint) {
      if (
        !talkingPoint.talkingPoints &&
        typeof talkingPoint.companyGoing === 'string' &&
        !talkingPoint.companyGoing &&
        typeof talkingPoint.speakingRole === 'string' &&
        !talkingPoint.speakingRole &&
        typeof talkingPoint.submitTalkingPoints === 'string' &&
        !talkingPoint.submitTalkingPoints
      ) {
        console.log(`Skipping ${talkingPoint.missionMeetingName}`);
        return;
      }
  
      const url = talkingPoint.talkingPointId
        ? `/api/talking-points/${talkingPoint.talkingPointId}`
        : '/api/talking-points/?populate=memMissionRegistration';
  
      const payload = {
        data: {
          missionMeetingsId: talkingPoint.missionMeetingId,
          priority: talkingPoint.priority,
          talkingPoints: talkingPoint.talkingPoints,
          speakingRole: isTruthy.test(talkingPoint.speakingRole),
          companyGoing: isTruthy.test(talkingPoint.companyGoing),
          submitTalkingPoints: !!talkingPoint.talkingPoints,
          memMissionRegistration: JSON.parse(`${currentMissionRegistration?.id}`),
        },
      };
  
      if (talkingPoint.talkingPointId) {
        talkingPointsRequests.push(axiosInstance.put(url, payload));
        logType = 'Updated';
      } else {
        //@ts-ignore
        payload.data = { ...payload.data, users_id: talkingPoint.userId };
        talkingPointsRequests.push(axiosInstance.post(url, payload));
        logType = 'Created';
      }
    }
  
    if (noMeeting) {
      axiosInstance.put(`/api/mission-registrations/${missionRegistrationId ? missionRegistrationId : currentMissionRegistration?.id}`,{
        data:{
          estimatedInvestments: estimatedInvestments,
        }
      })
      setLoadingScreen(false);
      MySwal.fire({
        text: 'No Meetings Proceeding To Next Step',
        timer: 2000,
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
      }).then(() => {
        setActiveStep(activeStep + 1);
      });
    } else {
      for (const talkingPoint of talkingPoints) {
        await updateTalkingPoints(talkingPoint);
      }
      await Promise.all(talkingPointsRequests).then(() => {
        axiosInstance.post(`/api/registration-logs`, {
          data: {
            title: logType ? logType : 'Updated',
            message: `${logType ? logType : 'Updated'} Talking Points for the Mission`,
            company: companyName,
            category: 'Talkpoints',
            user_id: currentUser?.id,
            mission: missionId,
          },
        });
      });
      const evaluateTalkingPoints = (talkingPoints) => {
        let status = 0;  
        const hasAnyData = talkingPoints.some(item =>
            (item.companyGoing !== "" && item.companyGoing !== null) ||
            (item.speakingRole !== "" && item.speakingRole !== null) ||
            (item.talkingPoints !== "" && item.talkingPoints !== null)
        );
    
        const allHaveData = talkingPoints.every(item => {
            if (item.speakingRole) {
                return (item.companyGoing !== "" && item.companyGoing !== null) &&
                       (item.talkingPoints !== "" && item.talkingPoints !== null);
            } else {
                return (item.companyGoing !== "" && item.companyGoing !== null) &&
                       (item.speakingRole !== "" && item.speakingRole !== null);
            }
        });
    
        if (hasAnyData) {
            status = 1;
        } 
        if (allHaveData) {
            status = 2;
        }
        return status;
    };
    
    const status = evaluateTalkingPoints(talkingPoints);
    if (currentMissionRegistration?.talkpointsStatus !== status) {
        axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
            data: {
                talkpointsStatus: status,
                billingStatus: 2,
            },
        });
    }    
      axiosInstance.put(`/api/mission-registrations/${missionRegistrationId ? missionRegistrationId : currentMissionRegistration?.id}`,{
        data:{
          estimatedInvestments: estimatedInvestments,
        }
      })
      setLoadingScreen(false);
      MySwal.fire({
        text: 'Talking Points Saved Successfully',
        timer: 2000,
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false,
      }).then(() => {
        talkingPoints.forEach((item) => {
          localStorage.removeItem(`${item.id}`);
          localStorage.removeItem(`${item.id}_companyGoing`);
          localStorage.removeItem(`${item.id}_speakingRole`);
      });
        setActiveStep(activeStep + 1);
      });
    }
  }
  

  const selectFieldHandler = async (
    event: SelectChangeEvent,
    rowId: string,
    talkingPointId: string,
    meetingId: string,
    apiRef
  ) => {
    const {name, value} = event.target
    const updatedTalkingPoints = talkingPoints.map((item) => {
      if (item.id === rowId) {
        return {
          ...item,
          [name]: value,
        }
      }
      return item
    })

    setTalkingPoints(updatedTalkingPoints)
    apiRef.current.setEditCellValue({
      rowId,
      name,
      value,
    })
    if(!talkingPointId){
    localStorage.setItem(`${rowId}_${name}`, value);
    }
  }

  const RenderSelect = (
    name: string,
    speakingRole: string,
    rowId: string,
    talkingPointId: string,
    meetingId: string
  ) => {
    const [selectedValue, setSelectedValue] = useState(speakingRole);
    const apiRef = useGridApiContext()
    useEffect(() => {
      const savedValue = localStorage.getItem(`${rowId}_${name}`);
      if (savedValue !== null) {
          setSelectedValue(savedValue);
      }
  }, [rowId, name]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    selectFieldHandler(event, rowId, talkingPointId, meetingId, apiRef);
};

    return (
      <FormControl
        sx={{
          width: '100px',
        }}
      >
        <Select
                name={name}
                value={selectedValue}
                onChange={handleSelectChange}
                size="small"
            >
          <MenuItem value='true'>Yes</MenuItem>
          <MenuItem value='false'>No</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const RenderTextarea = (params) => {
    const apiRef = useGridApiContext()
    const talkingPointId = params.id
    const talkingPoint = params.value
    const isSpeaking = params.row.speakingRole
    const TpId = params.row.talkingPointId
    const [defValue, setDefValue] = useState('')
    const editorRef = useRef<any>(null)

    useEffect(() => {
      const savedValue = localStorage.getItem(talkingPointId);
      if (savedValue !== null) {
          setDefValue(savedValue);
      } else if (isSpeaking == false) {
          setDefValue('-');
      } else {
          setDefValue(talkingPoint);
      }
  }, [isSpeaking, talkingPoint, talkingPointId]);

    return (
      <div
        style={{
          width: '100%',
          display: 'block',
        }}
      >
        <textarea
          key={talkingPointId + '__data'}
          style={{
            width: '100%',
            background: 'white',
          }}
          value={isSpeaking === 'false' ? '-' : defValue}
          onChange={(e) => {
            e.preventDefault()
            setDefValue(e.target.value)
            apiRef.current.setEditCellValue({
              id: talkingPointId,
              field: e.target.name,
              value: e.target.value,
            })
            const updatedTalkingPoints = talkingPoints.map((prev) => {
              if (prev.id === talkingPointId) {
                return {
                  ...prev,
                  talkingPoints: e.target.value,
                }
              }
              return prev
            })

            setTalkingPoints(updatedTalkingPoints)
            if(!TpId){
              localStorage.setItem(talkingPointId, e.target.value);
            }
          }}
          rows={7}
          cols={51}
          wrap='off'
          maxLength={3000}
          disabled={isSpeaking === 'false'}
        />
      </div>
    )
  }

  const renderCompanyGoingSelect = (
    speakingRole: string,
    rowId: string,
    talkingPointId: string,
    meetingId: string
  ) => RenderSelect('companyGoing', speakingRole, rowId, talkingPointId, meetingId)

  const renderSubmitTalkingPointsSelect = (
    speakingRole: string,
    rowId: string,
    talkingPointId: string,
    meetingId: string
  ) => RenderSelect('submitTalkingPoints', speakingRole, rowId, talkingPointId, meetingId)

  const renderYesOrNoSelect = (
    speakingRole: string,
    rowId: string,
    talkingPointId: string,
    meetingId: string
  ) => RenderSelect('speakingRole', speakingRole, rowId, talkingPointId, meetingId)

  const EditTalkingPointComponent: React.FC<GridRenderEditCellParams> = ({
    id,
    field,
    hasFocus,
    currentText,
    value,
  }) => {
    const apiRef = useGridApiContext()
    const editorRef = useRef<any>(null)
    const [inputValue, setInputValue] = useState<string>(value)
    const cursorPosition = 0

    const updateTalkingPointsState = (data) => {
      const updatedTalkingPoints = talkingPoints.map((prev) => {
        if (prev.id === id) {
          return {
            ...prev,
            talkingPoints: data.target.value,
          }
        }

        return prev
      })

      apiRef.current.setEditCellValue({
        id,
        field,
        value: data.target.value,
      })

      setInputValue(value)
      setTalkingPoints(updatedTalkingPoints)
    }

    const handleInputChange = useCallback(debounce(updateTalkingPointsState, 700), [])

    return (
      <div
        style={{
          width: '100%',
          display: 'block',
          padding: '10px',
        }}
      >
        <textarea
          key={`id_${id}`}
          style={{
            width: '100%',
          }}
          defaultValue={inputValue}
          onChange={handleInputChange}
          onFocus={(e) => {
            e.target.selectionStart = e.target.selectionEnd = inputValue.length
          }}
          rows={7}
          cols={50}
          autoFocus={true}
        />
      </div>
    )
  }
  const columns: GridColDef[] = [
    {
      field: 'companyGoing',
      headerName: 'Company Going',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        renderCompanyGoingSelect(
          params.value,
          params.id as string,
          params.row.talkingPointId,
          params.row.missionMeetingId
        ),
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'speakingRole',
      headerName: 'Speaking',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        renderYesOrNoSelect(
          params.value,
          params.id as string,
          params.row.talkingPointId,
          params.row.missionMeetingId
        ),
    },
    {
      field: 'missionMeetingName',
      headerName: 'Meeting',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'talkingPoints',
      headerName: 'Talking Points',
      headerAlign: 'center',
      align: 'center',
      flex: 4,
      width: 100,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => RenderTextarea(params),
    },
  ];  

  return (
    <>
      <Box mt={5}>
        <Container>
          <div className='alert alert-sm alert-dismissible alert-dark d-flex align-items-center p-5 pb-0'>
            <span className='svg-icon svg-icon-2hx svg-icon-dark me-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-dark me-4'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.3'
                    x='2'
                    y='2'
                    width='20'
                    height='20'
                    rx='10'
                    fill='black'
                  ></rect>
                  <rect
                    x='11'
                    y='14'
                    width='7'
                    height='2'
                    rx='1'
                    transform='rotate(-90 11 14)'
                    fill='black'
                  ></rect>
                  <rect
                    x='11'
                    y='17'
                    width='2'
                    height='2'
                    rx='1'
                    transform='rotate(-90 11 17)'
                    fill='black'
                  ></rect>
                </svg>
              </span>
            </span>
            <div className='d-flex flex-column pe-0 pe-sm-10'>
              <h5 className='mb-3'>
                Provide talking points and priority preferences for planned meetings.
              </h5>
              <p className='fs-8'>
                For each scheduled meeting, you need to evaluate your participation level. If there
                are specific issues or topics you need to discuss, mark
                <span style={{fontWeight: 'bold'}}> "Yes" </span> in the
                <strong style={{fontWeight: 'bold', color: 'red'}}>
                  {' '}
                  Submit Talking Points
                </strong>{' '}
                column, otherwise mark "No".
                <span style={{fontWeight: 'bold'}}>
                  Remember, your participation in all meetings is not confirmed until further
                  notice.
                </span>
              </p>

              <p className='fs-8'>
                When you're slated to actively participate in the conversation, please indicate
                <span style={{fontWeight: 'bold'}}>"Yes"</span> in the
                <strong style={{fontWeight: 'bold', color: 'red'}}> Speaking Role</strong> column.
                When selecting "Yes", ensure that you provide brief talking points for that
                particular meeting. Lastly, for the
                <strong style={{fontWeight: 'bold', color: 'red'}}> Company Going</strong> column,
                select "Yes" or "No" to denote your company's decision to attend or skip the
                meeting.
              </p>

              <p className='fs-8'>
                You can come back to add or edit talking points after. No need to finalize upon registration.
              </p>
              
            </div>
            <button
              type='button'
              className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
              data-bs-dismiss='alert'
            >
              <span className='svg-icon svg-icon-2x svg-icon-light'>
                <span className='svg-icon svg-icon-2x svg-icon-light'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='mh-50px'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    ></rect>
                  </svg>
                </span>
              </span>
            </button>
          </div>
          <Box marginTop={2}>
            <DataGrid
              sx={{
                fontSize: '12px',
                boxShadow: 2,
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
                '&.MuiDataGrid-cell': {
                  padding: '5px',
                },
              }}
              editMode='cell'
              rowHeight={150}
              rows={talkingPoints}
              columns={columns}
              hideFooterPagination
              onCellKeyDown={(params, event: MuiEvent) => {
                event.defaultMuiPrevented = true
              }}
              onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
                event.defaultMuiPrevented = true
                params.hasFocus = false
                params.isEditable = false
              }}
              hideFooter
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              className='cell-padding'
            />
          </Box>
          {missionId === '17' || missionId === '16' ? <>
          <Box mt={5}>
          <h5>Brief Overview of Estimated Investments and Employment in the Philippines </h5>
          <form>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="estimatedInvestments"
                value={estimatedInvestments}
                onChange={handleChange}
              />
            </div>
          </form>
        </Box>
          </> : <></>}
          <Grid mt={2} textAlign='right' item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{
                marginRight: 2,
                float: 'left',
              }}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Back
            </Button>
            { showBackToDetailsButton && (<Button
              type='button'
              variant='contained'
              color='primary'
              href={`/missions/view/${missionId}`}
              style={{float: "left"}}
            >
              Back to Details Page
            </Button>)}
            {missionRegistrationId && (
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                sx={{
                  marginRight: 2,
                }}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Continue Without Saving
              </Button>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              // disabled={isNextButtonDisabled}
            >
              Next
            </Button>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default TalkingPoints
