import {useMission} from '../core/MissionProvider'
import OtherMissions from '../components/OtherMissions'
import PastMission from '../components/PastMissions'
import UpcomingMissions from './UpcomingMissions'

const UserMissions = () => {
  const {otherMissions} = useMission()

   return (
    <div className='container-fluid px-20'>
      <UpcomingMissions isRepresentativeView={false} />
      <PastMission pastMissions={otherMissions.filter(mission => new Date(mission.regCloseDate) < new Date())}/>
      <OtherMissions />
    </div>
  );
};

export default UserMissions
