import {Box, Button, Grid, InputLabel, TextField} from '@mui/material'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
//@ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {
  IMemberParticipants,
  IStepperProps,
  //ParticipantInformationArrayDef,
  TParticpantInfo,
} from '../../../../../types/types'
import axiosInstance from '../../../../../utils/axios'
import axiosInstanceFormData from '../../../../../utils/axiosFormData'
import MySwal from '../../../../../utils/sweetAlert'
import {useMission} from '../../core/MissionProvider'
import ParticipantList from './ParticipantList'
import ParticipantSelectorModal from './ParticipantSelectorModal'
import {useParams} from 'react-router-dom'
import COBCard from './COBCard'
import MuiPhoneNumber from 'material-ui-phone-number'
import isValidPhoneNumber from 'libphonenumber-js';
import AddParticipant from './AddParticipantList'
import {useAuth} from '../../../auth'

const ParticipantInformation: React.FC<IStepperProps> = ({activeStep, setActiveStep}) => {
  const {setLoadingScreen, memberParticipants, currentMissionRegistration, loadingScreen} =
    useMission()
    const {currentUser, getCurrentMemberData} = useAuth()

  const {missionId, missionRegistrationId} = useParams()
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');

  const participantDefault = {
    firstName: '',
    lastName: '',
    dietaryRestrictions: '',
    participantPhoto: null,
    bio: '',
    emergencyContactName: '',
    emergencyEmail: '',
    emergencyContact: '',
    emergencyRelationships: '',
    contactNumber: '',
    memParticipantId: 0,
    participantEmail: '',
  }

  type TParticpantInfowithError = TParticpantInfo & {
    phoneNumError?: string
    phoneNumError2?: string
    emailError?: string
    emailError2?: string
    cIndex?: number
  }

  const [participantInformation, setParticipantInformation] =
    useState<Partial<TParticpantInfowithError>>(participantDefault)
  const [currentlyEdittingId, setCurrentlyEdittingId] = useState<number>()
  const [bioCharCount, setBioCharCount] = useState<number>(0)
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [selectedMemberParticipants, setSelectedMemberParticipants] = useState<TParticpantInfo[]>(
    []
  )
  const [openMemParticipantModel, setOpenMemberParticipantModel] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [companyName, setCompanyName]= useState()

  useEffect(() => {
    if (missionRegistrationId) {
      setOpenMemberParticipantModel(false)
    } else {
      if (memberParticipants.length === 0) {
        setOpenAdd(true)
      } else {
        setOpenMemberParticipantModel(true)
      }
    }
  }, [missionRegistrationId])

  useEffect(()=>{
    if(currentMissionRegistration){
      axiosInstance.get(`/api/mission-registrations/${currentMissionRegistration.id}`)
      .then((response)=>{
        setCompanyName(response.data.data.attributes.compDisplayName)
      })
    }
    if(missionRegistrationId){
      axiosInstance.get(`/api/mission-registrations/${missionRegistrationId}`)
      .then((response)=>{
        setCompanyName(response.data.data.attributes.compDisplayName)
      })
    }
  },[currentMissionRegistration,missionRegistrationId])

  const addParticipantHandler = () => {
    if (memberParticipants.length === 0) {
      setOpenAdd(true)
    } else {
      setOpenMemberParticipantModel(true)
    }
  }

  const deleteParticipant = (participantId: number, memParticipantId: number, cIndex: number) => {
    const updatedParticipants = selectedMemberParticipants.filter(
      (participant, index) => index !== cIndex
    )

    if (currentMissionRegistration) {
      MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingScreen(true)
          setSelectedMemberParticipants(updatedParticipants)
          if (memParticipantId !== undefined) {
            await axiosInstance
              .delete(`/api/mission-memparticipants/${memParticipantId}`)
              .then(() => {
                MySwal.fire('Deleted!', 'Participant has been deleted.', 'success')
                setLoadingScreen(false)
                const hasMissingDetails = updatedParticipants.some(participant =>
                  !participant.bio || !participant.contactNumber || !participant.dietaryRestrictions || !participant.emergencyContact || !participant.emergencyContactName 
                  || !participant.emergencyEmail || !participant.emergencyRelationships || !participant.firstName || !participant.lastName || !participant.participantPhoto.name || !participant.participantEmail
                );
                const allParticipantsComplete = updatedParticipants.every(participant =>
                  participant.bio &&
                  participant.contactNumber && 
                  participant.dietaryRestrictions &&
                  participant.emergencyContact &&
                  participant.emergencyContactName &&
                  participant.emergencyEmail &&
                  participant.emergencyRelationships &&
                  participant.firstName &&
                  participant.lastName &&
                  participant.participantPhoto &&
                  participant.participantPhoto.name &&
                  participant.participantEmail
                );
                if (updatedParticipants.length === 0) {
                  axiosInstance.put(
                    `/api/mission-registrations/${currentMissionRegistration?.id}`,
                    {
                      data: {
                        participantStatus: 0,
                        billingStatus: 2,
                      },
                    }
                  )
                }
                else if(hasMissingDetails){
                  axiosInstance.put(
                    `/api/mission-registrations/${currentMissionRegistration?.id}`,
                    {
                      data: {
                        participantStatus: 1,
                        billingStatus: 2,
                      },
                    }
                  )
                }
                else if(allParticipantsComplete){
                  axiosInstance.put(
                    `/api/mission-registrations/${currentMissionRegistration?.id}`,
                    {
                      data: {
                        participantStatus: 2,
                        billingStatus: 2,
                      },
                    }
                  )
                }
              })
              await axiosInstance.post(`/api/registration-logs`, {
                data:{
                  title: 'Deleted',
                  message: 'Deleted a Participant to the Mission',
                  company: companyName,
                  category: 'Participants',
                  user_id: currentUser?.id,
                  mission: missionId,
                }
              })
              .catch(() => setLoadingScreen(false))
              .finally(() => {
                setLoadingScreen(false)
              })
          } else {
            setLoadingScreen(false)
          }
        } else if (updatedParticipants.length === 0) {
          setLoadingScreen(true)
          setSelectedMemberParticipants(updatedParticipants)
          if (memParticipantId !== undefined) {
            await axiosInstance
              .delete(`/api/mission-memparticipants/${memParticipantId}`)
              .then(() => {
                MySwal.fire('Deleted!', 'Custom Participant has been deleted.', 'success')
                setLoadingScreen(false)
              })
              await axiosInstance.post(`/api/registration-logs`, {
                data:{
                  title: 'Deleted',
                  message: 'Deleted a Participant to the Mission',
                  company: companyName,
                  category: 'Participants',
                  user_id: currentUser?.id,
                  mission: missionId,
                }
              })
              .catch(() => setLoadingScreen(false))
              .finally(() => setLoadingScreen(false))
          } else {
            setLoadingScreen(false)
          }
        }
      })

      return
    }
    setSelectedMemberParticipants(updatedParticipants)
  }

  const getParticipant = (participantId: number, index: number) => {
    setLoadingScreen(true)
    setCurrentIndex(index)
    if (participantId === undefined && index >= 0 && index < selectedMemberParticipants.length) {
      const participant = selectedMemberParticipants[index]
      // Handle custom participants or other logic for index-based participants
      if (participant) {
        setParticipantInformation({
          ...participant,
          //@ts-ignore
          participantEmail: participant.participantEmail ? participant.participantEmail : participant.email ? participant.email : null,
          cIndex: index,
        })
        setCurrentlyEdittingId(undefined) // Clear the editing ID when handling custom participants
      }
    } else if (participantId !== undefined) {
      const participant = selectedMemberParticipants.find((member) => member.id === participantId)
      if (participant && participant.memParticipantId) {
        axiosInstance
          .get(
            `/api/mission-memparticipants/${participant?.memParticipantId ? participant?.memParticipantId : participant?.id }?populate=usersPermissionsUserId,registrationId,photo&filters[registrationId][id]=${currentMissionRegistration?.id}`
          )
          .then((res) => {
            if (res.data.data.length > 0) {
              setParticipantInformation({
                id: res.data.data[0].attributes.usersPermissionsUserId.data.id,
                firstName:
                  res.data.data[0].attributes.usersPermissionsUserId.data.attributes.firstName,
                lastName:
                  res.data.data[0].attributes.usersPermissionsUserId.data.attributes.lastName,
                dietaryRestrictions: res.data.data[0].attributes.dietaryRestrictions,
                bio: res.data.data[0].attributes.bio,
                contactNumber: res.data.data[0].attributes.contactNumber,
                emergencyContactName: res.data.data[0].attributes.emergencyContactName,
                emergencyRelationships: res.data.data[0].attributes.emergencyRelationships,
                emergencyEmail: res.data.data[0].attributes.emergencyEmail,
                emergencyContact: res.data.data[0].attributes.emergencyContact,
                participantEmail: res.data.data[0].attributes.participantEmail,
                participantPhoto: {
                  name: res.data.data[0].attributes.photo.data
                    ? res.data.data[0].attributes.photo.data.attributes.name
                    : '',
                  url: res.data.data[0].attributes.photo.data
                  ? res.data.data[0].attributes.photo.data.attributes.url
                  : '',
                },
                cIndex: index,
              })
              setCurrentlyEdittingId(res.data.data[0].attributes.usersPermissionsUserId.data.id)
            } else {
              if (participant) {
                setParticipantInformation({
                  ...participant,
                  //@ts-ignore
                  participantEmail: participant.email ? participant.email : participant.participantEmail ? participant.participantEmail : null,
                  cIndex: index,
                })
                setCurrentlyEdittingId(participantId)
              }
            }
          })
      } else {
        if (participant) {
          setParticipantInformation(participant)
          setCurrentlyEdittingId(participantId)
        }
      }
    }
    setLoadingScreen(false)
  }

  const isEmailValid = (email: string) => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleChangeNumberEmergencyContact = (value,country) => {
    const countryCode = country.countryCode.toUpperCase();
    const phoneNumber = isValidPhoneNumber(value, countryCode);

    if (!phoneNumber || !phoneNumber.isValid()) {
      setParticipantInformation((prevData) => ({
        ...prevData,
        phoneNumError2: 'Please enter a valid phone number',
      }))
    } else {
      setParticipantInformation((prevData) => ({
        ...prevData,
        phoneNumError2: '',
      }))
    }
    const participantIndex = currentlyEdittingId
      ? selectedMemberParticipants.findIndex(
          (participant) => participant.id === currentlyEdittingId
        )
      : currentIndex

    if (participantIndex !== -1 && participantIndex !== undefined) {
      setSelectedMemberParticipants((prevParticipants) => {
        const updatedParticipants = [...prevParticipants]
        updatedParticipants[participantIndex] = {
          ...updatedParticipants[participantIndex],
          emergencyContact: value,
        }
        return updatedParticipants
      })
    }

    setParticipantInformation((prevData) => ({
      ...prevData,
      emergencyContact: value,
    }))
  }
  const handleChangeNumberContactNumber = (value,country) => {
    const countryCode = country.countryCode.toUpperCase();
    const phoneNumber = isValidPhoneNumber(value, countryCode);
    if (!phoneNumber || !phoneNumber.isValid()) {
      setParticipantInformation((prevData) => ({
        ...prevData,
        phoneNumError: 'Please enter a valid phone number',
      }))
    } else {
      setParticipantInformation((prevData) => ({
        ...prevData,
        phoneNumError: '',
      }))
    }
    const participantIndex = currentlyEdittingId
      ? selectedMemberParticipants.findIndex(
          (participant) => participant.id === currentlyEdittingId
        )
      : currentIndex

    if (participantIndex !== -1 && participantIndex !== undefined) {
      setSelectedMemberParticipants((prevParticipants) => {
        const updatedParticipants = [...prevParticipants]
        updatedParticipants[participantIndex] = {
          ...updatedParticipants[participantIndex],
          contactNumber: value,
        }
        return updatedParticipants
      })
    }

    setParticipantInformation((prevData) => ({
      ...prevData,
      contactNumber: value,
    }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target

    // Validate email and phone number in real-time
    if (name === 'emergencyEmail') {
      if (!isEmailValid(value)) {
        setParticipantInformation((prevData) => ({
          ...prevData,
          emailError: 'Please enter a valid email address',
        }))
      } else {
        setParticipantInformation((prevData) => ({
          ...prevData,
          emailError: '',
        }))
      }
    }
    if (name === 'participantEmail') {
      if (!isEmailValid(value)) {
        setParticipantInformation((prevData) => ({
          ...prevData,
          emailError2: 'Please enter a valid email address',
        }))
      } else {
        setParticipantInformation((prevData) => ({
          ...prevData,
          emailError2: '',
        }))
      }
    }
    const participantIndex = currentlyEdittingId
      ? selectedMemberParticipants.findIndex(
          (participant) => participant.id === currentlyEdittingId
        )
      : currentIndex

    if (participantIndex !== -1 && participantIndex !== undefined) {
      setSelectedMemberParticipants((prevParticipants) => {
        const updatedParticipants = [...prevParticipants]
        updatedParticipants[participantIndex] = {
          ...updatedParticipants[participantIndex],
          [name]: value,
        }
        return updatedParticipants
      })
    }

    setParticipantInformation((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!allowedFileTypes.includes(file.type)) {
        MySwal.fire({
            text: 'Please select a valid JPEG or PNG image.',
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,
        });
        event.target.value = ''; // Reset the input value
        return;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxSize) {
        MySwal.fire({
            html: `The file size exceeds 5MB. Please <a href="https://imageresizer.com/" target="_blank" rel="noopener noreferrer">resize your image</a> using this link.`,
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,
        });
        event.target.value = ''; // Reset the input value
        return;
    }

    const participantIndex = currentlyEdittingId
      ? selectedMemberParticipants.findIndex(
          (participant) => participant.id === currentlyEdittingId
        )
      : currentIndex;

    if (participantIndex !== -1 && participantIndex !== undefined) {
        const updatedParticipants = [...selectedMemberParticipants];
        updatedParticipants[participantIndex] = {
            ...updatedParticipants[participantIndex],
            participantPhoto: file,
        };
        setSelectedMemberParticipants(updatedParticipants);
    }

    setParticipantInformation((prevData) => ({
        ...prevData,
        participantPhoto: file,
    }));

    // Optionally reset the input after processing if you want to allow selecting the same file again
    event.target.value = '';
};

  type IMemberParticipantsWithOptionalId = IMemberParticipants & {
    memParticipantId?: number
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    // Check if required fields are filled
    if (
        !participantInformation.firstName ||
        !participantInformation.lastName
    ) {
        await setLoadingScreen(false);
        MySwal.fire({
            text: 'Please fill in all required fields.',
            timer: 2000,
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        return; // Don't proceed with submission
    }

    // Check if there are phone or email errors
    if (
        participantInformation.phoneNumError ||
        participantInformation.phoneNumError2 ||
        participantInformation.emailError || 
        participantInformation.emailError2
    ) {
        await setLoadingScreen(false);
        MySwal.fire({
            text: 'Please fix the errors in the phone number and email fields.',
            timer: 2000,
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        return; // Don't proceed with submission
    }

    // Image size validation
    if (
        participantInformation.participantPhoto &&
        participantInformation.participantPhoto.size !== undefined
    ) {
        const maxSize = 5 * 1024 * 1024; // 5 MB
        if (participantInformation.participantPhoto.size > maxSize) {
            MySwal.fire({
                text: 'File size exceeds the limit of 5 MB.',
                icon: 'error',
                showConfirmButton: true,
                allowOutsideClick: false,
            });
            setLoadingScreen(false);
            return; // Don't proceed with submission
        }
    }

    if (currentMissionRegistration !== undefined) {
        if (currentIndex !== undefined) {
            const currentData = selectedMemberParticipants[currentIndex];
            const {id, ...restData} = currentData;
            const modifiedData = {
                ...restData,
                memParticipantId: currentData.memParticipantId || 0,
                mission: missionId,
                bio: currentData.bio,
                registrationId: missionRegistrationId
                    ? parseInt(missionRegistrationId, 10)
                    : parseInt(currentMissionRegistration.id, 10),
                title: currentData.jobTitle,
                //@ts-ignore
                participantEmail: currentData.email ? currentData.email : currentData.participantEmail,
            };

            if (modifiedData.memParticipantId === 0) {
                await axiosInstance
                    .post('/api/mission-memparticipants', {
                        data: modifiedData,
                    })
                    .then(async (response) => {
                        setSelectedMemberParticipants((prev) => {
                            const updatedParticipants = [...prev]; // Create a copy of the array
                            updatedParticipants[currentIndex].memParticipantId = response.data.data.id;
                            return updatedParticipants; // Return the updated array
                        });
                        MySwal.fire({
                            text: 'Participant Saved',
                            icon: 'success',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                        });

                        if (
                            participantInformation.participantPhoto &&
                            participantInformation.participantPhoto.size !== undefined
                        ) {
                            const formData = new FormData();
                            formData.append('files', participantInformation.participantPhoto);
                            formData.append('refId', response.data.data.id);
                            formData.append('ref', 'api::mission-memparticipant.mission-memparticipant');
                            formData.append('field', 'photo');
                            formData.append('path', 'photo_memparticipants');
                            await axiosInstanceFormData.post('/api/upload', formData);
                        }

                        if (currentMissionRegistration?.participantStatus === 0 || 1) {
                            await axiosInstance.put(
                                `/api/mission-registrations/${currentMissionRegistration?.id}`,
                                {
                                    data: {
                                        participantStatus: 1,
                                        billingStatus: 2,
                                    },
                                }
                            );
                        }
                    });
                await axiosInstance.post(`/api/registration-logs`, {
                    data:{
                        title: 'Added',
                        message: 'Added a Participant to the Mission',
                        company: companyName,
                        category: 'Participants',
                        user_id: currentUser?.id,
                        mission: missionId,
                    }
                });
            } else {
                await axiosInstance
                    .put(`/api/mission-memparticipants/${modifiedData.memParticipantId}`, {
                        data: modifiedData,
                    })
                    .then(async (response) => {
                        MySwal.fire({
                            text: 'Participant Updated',
                            icon: 'success',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                        });

                        if (
                            participantInformation.participantPhoto &&
                            participantInformation.participantPhoto.size !== undefined
                        ) {
                            const formData = new FormData();
                            formData.append('files', participantInformation.participantPhoto);
                            formData.append('refId', response.data.data.id);
                            formData.append('ref', 'api::mission-memparticipant.mission-memparticipant');
                            formData.append('field', 'photo');
                            formData.append('path', 'photo_memparticipants');
                            await axiosInstanceFormData.post('/api/upload', formData);
                        }

                        if (modifiedData.dietaryRestrictions &&
                            modifiedData.contactNumber &&
                            modifiedData.emergencyContactName &&
                            modifiedData.emergencyEmail &&
                            modifiedData.emergencyContact &&
                            modifiedData.bio &&
                            modifiedData.emergencyRelationships &&
                            modifiedData.participantPhoto) {
                            await axiosInstance.put(
                                `/api/mission-registrations/${currentMissionRegistration?.id}`,
                                {
                                    data: {
                                        participantStatus: 2,
                                        billingStatus: 2,
                                    },
                                }
                            );
                        } else {
                            await axiosInstance.put(
                                `/api/mission-registrations/${currentMissionRegistration?.id}`,
                                {
                                    data: {
                                        participantStatus: 1,
                                        billingStatus: 2,
                                    },
                                }
                            );
                        }
                    });
                await axiosInstance.post(`/api/registration-logs`, {
                    data:{
                        title: 'Updated',
                        message: 'Updated a Participant to the Mission',
                        company: companyName,
                        category: 'Participants',
                        user_id: currentUser?.id,
                        mission: missionId,
                    }
                });
            }
        }
        setLoadingScreen(false);
    } else {
        console.log('Current Registration Not Found!');
        setLoadingScreen(false);
    }
};

  //!Update this is a handler to direct to the next step and post or put is moved to save button (handleSubmit)
  const handleNext = async () => {
    setLoadingScreen(true)
    for (const participant of selectedMemberParticipants) {
      try {
        // Assuming you have an API function called saveParticipantData
        await saveParticipantData(participant);
        console.log(`Participant ${participant.firstName} ${participant.lastName} saved successfully.`);
      } catch (error) {
        console.error(`Error saving participant ${participant.firstName} ${participant.lastName}:`, error);
        // Handle error as needed, e.g., show an alert or retry saving the participant
      }
    }
    setLoadingScreen(false)
    setActiveStep(activeStep + 1);
  }
  const handleNextQuick = async () => {
    setLoadingScreen(true)
    setLoadingScreen(false)
    setActiveStep(activeStep + 1);
  }
  function isAnyNull(obj) {
    for (let key in obj) {
      if (obj[key] === null) {
        return true;
      }
    }
    return false;
  }
  
  // Function to check if all properties have data
  function isAllData(obj) {
    for (let key in obj) {
      if (obj[key] === null) {
        return false;
      }
    }
    return true;
  }
  const saveParticipantData = async (participant) => {
    try {
      const response = await axiosInstance.put(`/api/mission-memparticipants/${participant.memParticipantId}`,{
        data:{
          firstName: participant.firstName,
          lastName: participant.lastName,
          title: participant.jobTitle,
          contactNumber: participant.contactNumber,
          dietaryRestrictions: participant.dietaryRestrictions,
          bio: participant.bio,
          emergencyContactName: participant.emergencyContactName,
          emergencyContact: participant.emergencyContact,
          emergencyEmail: participant.emergencyEmail,
          emergencyRelationships: participant.emergencyRelationships,
        }
      })
      .then(async (response)=>{
        MySwal.fire({
          text: 'Participant Saved',
          icon: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        let participantStatus;
        if (isAllData(participant)) {
          participantStatus = 2;
        } else if (isAnyNull(participant)) {
          participantStatus = 1;
        } else {
          participantStatus = 0;
        }
      
        if (currentMissionRegistration?.participantStatus === 0 || 1) {
          await axiosInstance.put(
            `/api/mission-registrations/${currentMissionRegistration?.id}`,
            {
              data: {
                participantStatus: participantStatus,
                billingStatus: 2,
              },
            }
          )
        }
        const formData = new FormData()
              if (
                participant.participantPhoto &&
                participant.participantPhoto.size !== undefined
              ) {
                if (participant.participantPhoto) {
                  if (!participant.participantPhoto.type.includes('image/jpeg') && !participant.participantPhoto.type.includes('image/png')) {
                    MySwal.fire({
                      text: 'Please select a valid JPEG or PNG image.',
                      icon: 'error',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    });
                  } else {
                    const maxSize = 5 * 1024 * 1024;
                    if (participant.participantPhoto.size > maxSize) {
                      MySwal.fire({
                        text: 'File size exceeds the limit of 5 MB.',
                        icon: 'error',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                      });
                    } else {
                      formData.append('files', participant.participantPhoto)
                      formData.append('refId', response.data.data.id)
                      formData.append('ref', 'api::mission-memparticipant.mission-memparticipant')
                      formData.append('field', 'photo')
                      formData.append('path', 'photo_memparticipants')
                      await axiosInstanceFormData.post('/api/upload', formData)
                    }
                  }
                }
              }
              return response
      })
      const data = await response.data;
      return data;
    } catch (error) {
      throw new Error('Failed to save participant data:');
    }
  }

  useEffect(() => {
    setSelectedMemberParticipants([])
    if (missionRegistrationId && selectedMemberParticipants.length > 0) {
      setOpenMemberParticipantModel(false)
      setParticipantInformation(selectedMemberParticipants[0])
    }
  }, [])
  useEffect(() => {
    if (currentMissionRegistration) {
      fetchMissionRegistration()
    }
  }, [currentMissionRegistration])

  //!Update fetch the users that exist in the current mission registration
  const fetchMissionRegistration = async () => {
    axiosInstance
      .get(
        `/api/mission-registrations/${currentMissionRegistration?.id}?populate=missionMemparticipantsRegistrationId.usersPermissionsUserId,missionMemparticipantsRegistrationId.mission,missionMemparticipantsRegistrationId.photo`
      )
      .then((res) => {
        const filteredData =
          res.data.data.attributes.missionMemparticipantsRegistrationId.data.filter(
            (item) => item.attributes.mission.data.id === parseInt(missionId ?? '', 10)
          )
        const transformedData = filteredData.map((item) => {
          const memParticipantId = item.id
          const {id} = item.attributes.usersPermissionsUserId.data ? item.attributes.usersPermissionsUserId.data : item
          const payload = {
            firstName: item.attributes.firstName,
            lastName: item.attributes.lastName,
            jobTitle: item.attributes.title,
            bio: item.attributes.bio,
            contactNumber: item.attributes.contactNumber,
            dietaryRestrictions: item.attributes.dietaryRestrictions,
            emergencyEmail: item.attributes.emergencyEmail,
            emergencyContactName: item.attributes.emergencyContactName,
            emergencyContact: item.attributes.emergencyContact,
            emergencyRelationships: item.attributes.emergencyRelationships,
            participantEmail: item.attributes.participantEmail,
            participantPhoto: {
              name: item.attributes.photo.data ? item.attributes.photo.data.attributes.name : '',
              url: item.attributes.photo.data ? item.attributes.photo.data.attributes.url : '',
            },
            memParticipantId: memParticipantId,
          }
          return {id, ...payload}
        })
        setSelectedMemberParticipants((prev) => [...prev, ...transformedData])
      })
  }

  const handleAddParticipant = async (participant: any) => {
    setLoadingScreen(true)
    const isCustomParticipant = !participant.id
    if (isCustomParticipant) {
      await axiosInstance
      .post('/api/mission-memparticipants', {
        data: {
          firstName: participant.firstName,
          lastName: participant.lastName,
          title: participant.jobTitle,
          oldTitle: participant.jobTitle,
          participantEmail: participant.participantEmail,
          registrationId: currentMissionRegistration?.id,
          mission: missionId,
        },
      })
      await axiosInstance.post(`/api/registration-logs`, {
        data:{
          title: 'Added',
          message: 'Added a Participant to the Mission',
          company: companyName,
          category: 'Participants',
          user_id: currentUser?.id,
          mission: missionId,
        }
      })
      setSelectedMemberParticipants([])
      await fetchMissionRegistration()
      setSelectedMemberParticipants((prev) => [...prev])
    } else {
      const isAlreadySelected = selectedMemberParticipants.some(
        (memPar) => memPar.id === participant.id
      )

      if (!isAlreadySelected) {
        setSelectedMemberParticipants((prev) => [...prev, participant])
      }
    }
    setLoadingScreen(false)
    setOpenMemberParticipantModel(false)
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginTop: 5,
        }}
      >
        <Grid container spacing={2}>
          {participantInformation.firstName !== '' ? (
            <>
              <Grid item xs={12} sm={7} md={7}>
                <form
                  id='participantForm'
                  style={{
                    height: '100%',
                  }}
                >
                  <div className='card card-xl-stretch mb-xl-3'>
                    {/* begin::Header */}
                    <div className='card-header border-0 pt-0'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-dark fs-5'>
                          Representative Information
                        </span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>
                          {participantInformation.firstName} {participantInformation.lastName} -{' '}
                          {participantInformation.jobTitle}
                        </span>
                      </h3>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body pt-0'>
                      <div className='alert alert-secondary d-flex align-items-center p-2 mb-5'>
                        <span className='svg-icon-secondary me-3'></span>
                        <div className='d-flex flex-column text-black'>
                          <span className='fs-8'>
                            Verify the information below and, if necessary, make corrections for{' '}
                            <strong>
                              {' '}
                              {participantInformation.firstName} {participantInformation.lastName}
                            </strong>
                            .
                          </span>
                        </div>
                      </div>
                      <Grid container spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='firstName' style={{fontSize: '11px'}}>
                            {' '}
                            First Name{' '}
                          </InputLabel>
                          <TextField
                            name='firstName'
                            fullWidth
                            value={participantInformation.firstName ?? ''}
                            onChange={handleChange}
                            size='small'
                            error={formSubmitted && !participantInformation.firstName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='lastName' style={{fontSize: '11px'}}>
                            {' '}
                            Last Name{' '}
                          </InputLabel>
                          <TextField
                            name='lastName'
                            fullWidth
                            value={participantInformation.lastName ?? ''}
                            onChange={handleChange}
                            size='small'
                            error={formSubmitted && !participantInformation.lastName}
                          />
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <div>
                            <InputLabel htmlFor="jobTitle" style={{ fontSize: '11px' }}>
                              Job Title
                            </InputLabel>
                            <TextField
                              name="jobTitle"
                              fullWidth
                              value={participantInformation.jobTitle ?? ''}
                              onChange={handleChange}
                              size="small"
                              error={formSubmitted && !participantInformation.jobTitle}
                            />
                            </div>
                            <div style={{paddingTop: "16px"}}>
                            <InputLabel htmlFor='dietaryRestrictions' style={{fontSize: '11px'}}>
                              {' '}
                              Dietary Restrictions{' '}
                            </InputLabel>
                            <TextField
                              name='dietaryRestrictions'
                              fullWidth
                              value={participantInformation.dietaryRestrictions ?? ''}
                              onChange={handleChange}
                              size='small'
                              error={formSubmitted && !participantInformation.dietaryRestrictions}
                            />
                            </div>
                            <div  style={{paddingTop: "16px"}}>
                                <InputLabel htmlFor='contactNumber' style={{fontSize: '11px'}}>
                                {' '}
                                Contact Number{' '}<i> (Preferably available on WhatsApp)</i>
                              </InputLabel>
                              <MuiPhoneNumber
                                defaultCountry={'us'}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                //@ts-ignore
                                onChange={handleChangeNumberContactNumber}
                                name='contactNumber'
                                fullWidth
                                value={participantInformation.contactNumber ?? ''}
                                size='small'
                                error={
                                  (formSubmitted && !participantInformation.contactNumber) ||
                                  participantInformation.phoneNumError ===
                                    'Please enter a valid phone number'
                                }
                                variant='outlined'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  classes: {
                                    root: 'MuiInput-root',
                                    notchedOutline: 'MuiOutlinedInput-notchedOutline',
                                  },
                                }}
                              />
                              <span style={{color: 'red'}}>{participantInformation.phoneNumError}</span>
                                </div>
                            <div  style={{paddingTop: "16px"}}>
                              <InputLabel htmlFor='email' style={{fontSize: '11px'}}>
                                {' '}
                                Email{' '}
                              </InputLabel>
                              <TextField
                                name='participantEmail'
                                fullWidth
                                value={participantInformation.participantEmail ?? ''}
                                onChange={handleChange}
                                size='small'
                                error={
                                  (formSubmitted && !participantInformation.participantEmail) ||
                                  participantInformation.emailError2 ===
                                    'Please enter a valid email address'
                                }
                              />
                              <span style={{color: 'red'}}>{participantInformation.emailError2}</span>
                            </div>
                        </Grid>

                        <Grid item xs={6} sx={{alignItems: 'center' }}>
                            <div
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                width: '182px',
                                height: '182px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                              }}
                            >
                              {participantInformation.participantPhoto && participantInformation.participantPhoto.name !== "" ? (
                                participantInformation.participantPhoto.url && participantInformation.participantPhoto.url !== "" ? (
                                  <img
                                    src={participantInformation.participantPhoto.url}
                                    alt="Participant"
                                    style={{ width: '100%', height: 'auto' }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(participantInformation.participantPhoto)}
                                    alt="Participant"
                                    style={{ width: '100%', height: 'auto' }}
                                  />
                                )
                              ) : (
                                <span style={{ fontSize: '12px', color: '#aaa' }}>
                                  No image uploaded
                                </span>
                              )}
                            </div>
                          <InputLabel htmlFor='participantPhoto' style={{fontSize: '11px'}}>
                            Upload Participant Photo{' '}
                          </InputLabel>
                          <input
                            key={participantInformation.id + 'input_key'}
                            accept='image/*'
                            style={{
                              display: 'none',
                              height: '1.4375em',
                            }}
                            id='raised-button-file'
                            multiple
                            type='file'
                            onChange={handleImageChange}
                          />
                          <TextField
                            size='small'
                            key={participantInformation.id}
                            value={
                              participantInformation.participantPhoto
                                ? participantInformation.participantPhoto.name
                                : ''
                            }
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <label htmlFor='raised-button-file'>
                                  <Button variant='contained' component='span' size='small'>
                                    Upload
                                  </Button>
                                </label>
                              ),
                            }}
                            disabled
                          />
                          {formSubmitted && !participantInformation.participantPhoto && (
                            <span style={{color: 'red'}}>Please fill out your profile photo</span>
                          )}    
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel
                            htmlFor='editor'
                            sx={{marginTop: 2}}
                            style={{fontSize: '11px'}}
                          >
                            Biography
                          </InputLabel>
                          <CKEditor
                            editor={Editor}
                            id={`bio_${participantInformation.cIndex}`}
                            onReady={(editor) => {
                              editor.setData(participantInformation.bio ?? '')
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              const wordCountPlugin = editor.plugins.get('WordCount')
                              setBioCharCount(wordCountPlugin.characters)
                              const participantIndex = currentlyEdittingId
                                ? selectedMemberParticipants.findIndex(
                                    (participant) => participant.id === currentlyEdittingId
                                  )
                                : currentIndex

                              if (participantIndex !== -1 && participantIndex !== undefined) {
                                setSelectedMemberParticipants((prevParticipants) => {
                                  const updatedParticipants = [...prevParticipants]
                                  updatedParticipants[participantIndex] = {
                                    ...updatedParticipants[participantIndex],
                                    bio: data,
                                  }
                                  return updatedParticipants
                                })

                                // Update the participantInformation's biography as well
                                setParticipantInformation((prevData) => ({
                                  ...prevData,
                                  bio: data,
                                }))
                              }
                            }}
                          />
                          <div
                            id='word-count'
                            style={{
                              textAlign: 'right',
                              color: bioCharCount > 2500 ? 'red' : 'black',
                              fontSize: '10px',
                              fontStyle: 'italic',
                            }}
                          >
                            Character count: {bioCharCount}/2500
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/* end::Body */}
                  </div>
                  <div className='card card-xl-stretch mb-xl-3 mt-5'>
                    {/* begin::Header */}
                    <div className='card-header border-0 pt-0'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-dark fs-5'>Emergency Contact</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>
                          {participantInformation.firstName} {participantInformation.lastName} -{' '}
                          {participantInformation.jobTitle}
                        </span>
                      </h3>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body pt-0'>
                      <div className='alert alert-secondary d-flex align-items-center p-2 mb-5'>
                        <span className='svg-icon-secondary me-3'></span>
                        <div className='d-flex flex-column text-black'>
                          <span className='fs-8'>
                            Fill in all contact information below for a person who should be
                            contacted in case of an emergency during the mission.
                          </span>
                        </div>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='emergencyContactName' style={{fontSize: '11px'}}>
                            {' '}
                            Name{' '}
                          </InputLabel>
                          <TextField
                            name='emergencyContactName'
                            fullWidth
                            value={participantInformation.emergencyContactName ?? ''}
                            onChange={handleChange}
                            size='small'
                            error={formSubmitted && !participantInformation.emergencyContactName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='emergencyRelationships' style={{fontSize: '11px'}}>
                            Relationship to Participant{' '}
                          </InputLabel>
                          <TextField
                            name='emergencyRelationships'
                            fullWidth
                            onChange={handleChange}
                            value={participantInformation.emergencyRelationships ?? ''}
                            size='small'
                            error={formSubmitted && !participantInformation.emergencyRelationships}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='emergencyEmail' style={{fontSize: '11px'}}>
                            Emergency Email{' '}
                          </InputLabel>
                          <TextField
                            name='emergencyEmail'
                            fullWidth
                            onChange={handleChange}
                            value={participantInformation.emergencyEmail ?? ''}
                            size='small'
                            style={{paddingBottom: '10px'}}
                            error={
                              (formSubmitted && !participantInformation.emergencyEmail) ||
                              participantInformation.emailError ===
                                'Please enter a valid email address'
                            }
                          />
                          <span style={{color: 'red'}}>{participantInformation.emailError}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor='emergencyContact' style={{fontSize: '11px'}}>
                            Phone Number{' '}
                          </InputLabel>

                          <MuiPhoneNumber
                            defaultCountry={'us'}
                            countryCodeEditable={false}
                            disableAreaCodes={true}
                            //@ts-ignore
                            onChange={handleChangeNumberEmergencyContact}
                            name='emergencyContact'
                            fullWidth
                            value={participantInformation.emergencyContact ?? ''}
                            size='small'
                            error={
                              (formSubmitted && !participantInformation.emergencyContact) ||
                              participantInformation.phoneNumError2 ===
                                'Please enter a valid phone number'
                            }
                            variant='outlined'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              classes: {
                                root: 'MuiInput-root',
                                notchedOutline: 'MuiOutlinedInput-notchedOutline',
                              },
                            }}
                          />
                          <span style={{color: 'red'}}>
                            {participantInformation.phoneNumError2}
                          </span>
                        </Grid>
                        <Grid mt={2} textAlign='right' item xs={12}></Grid>
                      </Grid>
                    </div>
                    {/* end::Body */}
                  </div>
                </form>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={7} md={7}>
                <form
                  id='participantForm'
                  style={{
                    height: '100%',
                  }}
                >
                  <div className='card card-xl-stretch mb-xl-3'>
                    {/* begin::Header */}
                    <div className='card-header border-0 pt-0'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-dark fs-5'>
                          Please Select A Participant
                        </span>
                      </h3>
                    </div>
                  </div>
                </form>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={5} md={5}>
            <ParticipantList
              memberParticipants={selectedMemberParticipants}
              addParticipantHandler={addParticipantHandler}
              getParticipant={getParticipant}
              deleteParticipant={deleteParticipant}
              currentActiveId={currentlyEdittingId}
            />
            <COBCard memberParticipants={selectedMemberParticipants} />
            <AddParticipant
              onAddParticipant={handleAddParticipant}
              openAdd={openAdd}
              setOpenAdd={setOpenAdd}
            />

            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
              <Grid item xs={5} display="flex" flexDirection="column" alignItems="flex-start">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveStep(activeStep - 1)}
                  sx={{ marginBottom: '10px' }}
                >
                  Back
                </Button>
                {showBackToDetailsButton && (<Button
                  type="button"
                  variant="contained"
                  color="primary"
                  href={`/missions/view/${missionId}`}
                >
                  Back to Details Page
                </Button>)}
              </Grid>
              <Grid item xs={6} display="flex" flexDirection="column" alignItems="flex-end">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleNextQuick()}
                  disabled={selectedMemberParticipants.some(participant =>
                    participant.bio && participant.bio.length > 2500
                  )}
                  sx={{ marginBottom: '10px' }}
                >
                  Next
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleNext()}
                  disabled={selectedMemberParticipants.some(participant =>
                    participant.memParticipantId === undefined || (participant.bio && participant.bio.length > 2500)
                  )}
                >
                  Save & Next
                </Button>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
              {participantInformation.firstName && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      handleSubmit(e);
                      setLoadingScreen(true);
                    }}
                    disabled={loadingScreen}
                    sx={{ alignSelf: 'center', marginBottom: '10px', marginRight: "50px" }}
                  >
                    Save Info for {participantInformation.firstName} {participantInformation.lastName}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      </Box>
      <ParticipantSelectorModal
        setOpenMemberParticipantModel={setOpenMemberParticipantModel}
        selectedMemberParticipants={selectedMemberParticipants}
        memberParticipants={memberParticipants}
        setSelectedMemberParticipants={setSelectedMemberParticipants}
        open={openMemParticipantModel}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        handleAddParticipant={handleAddParticipant}
      />
    </>
  )
}

export default ParticipantInformation
