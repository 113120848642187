import {Route, Routes} from 'react-router-dom'
import {RepresentativeLayout} from './RepresentativeLayout'
import RepresentativeDashboard from './RepresentativeDashboard'
import ViewRepresentativeMission from '../mission/pages/ViewRepresentativeMission'
import RegisterParticipants from '../mission/pages/RegisterParticipant'


const RepresentativePage = () => (
  <Routes>
    <Route element={<RepresentativeLayout />}>
      <Route index element={<RepresentativeDashboard />} />
      <Route path='/view/:missionId/registration' element={<RegisterParticipants />} />
      <Route path='/mission/:missionId/registration/:missionRegistrationId' element={<ViewRepresentativeMission />} />
    </Route>
  </Routes>
)

export default RepresentativePage
