import { useEffect } from 'react'
import pngLogo from '../../../../assets/logo/usasean_logo.png.png'
import { log } from 'console'

const StaffLogin: React.FC = () => {
  useEffect(() => {
    login()
  }, [])
  const apiToken = localStorage.getItem('apiToken')
  const login = () => {
    if (apiToken) {
      window.location.href = `/`
    }
    window.location.href = `${process.env.REACT_APP_API_LOGIN_URL}&redirect_uri=${process.env.REACT_APP_BACKEND_HOST}/api/verify`
  }
  
  return (
    <div className='form w-100'>
      {apiToken === 'error' && (
        <div className='alert alert-danger bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10'>
          <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
            <span className=''>
              Something went wrong with connecting with the GrowthZone Platform. <br></br> <br></br>
              Please contact your administrator.
            </span>
          </div>
        </div>
      )}
      <div className='text-center w-70'>
        <div className='w-80'>
          <img src={pngLogo} className='h-350px' alt='Logo' />
        </div>
        <h1 className='text-dark fw-bolder'>Welcome</h1>
        <div className='text-gray-500 fw-semibold fs-6  d-flex justify-content-center'>
          Logging in to Business Mission Manager as Staff
        </div>
        <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
      </div>
    </div>
  )
}

export default StaffLogin
