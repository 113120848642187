import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {useNavigate} from 'react-router-dom'
import pngLogo from '../../../assets/logo/usasean_logo.png.png'
import {useAuth} from '../../modules/auth'
import {IconButton, Avatar, Menu, MenuItem,Badge,List,ListItem,ListItemText} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import {useParams} from 'react-router-dom'
import axiosInstance from '../../../utils/axios'

const pages = ['Products', 'Pricing', 'Blog']
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']


export default function ButtonAppBar({ isRepresentative }: { isRepresentative: boolean }) {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {missionId, missionRegistrationId} = useParams()


  const createMission = () => {
    navigate('/missions/create')
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [anchorElNotifications, setAnchorElNotifications] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = React.useState([]);
  const [loadingNotifications, setLoadingNotifications] = React.useState(true);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };
  const handleCloseNotificationsMenu = () => {
    setAnchorElNotifications(null);
  };
  React.useEffect(() => {
    const fetchNotifications = async () => {
      setLoadingNotifications(true);
      try {
        let url = missionId ? `/api/notifications?filters[url][$containsi]=/missions/view/${missionId}&sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=99`  : '/api/notifications?sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=99'
        const response = await axiosInstance.get(url);
        let data = response.data.data;
        data.sort((a, b) => {
          let dateA = new Date(a.attributes.createdAt);
          let dateB = new Date(b.attributes.createdAt);
          //@ts-ignore
          return dateB - dateA;
        });
        
        setNotifications(data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      } finally {
        setLoadingNotifications(false);
      }
    };
  
    fetchNotifications();
  }, []);

  const markNotificationAsViewed = async (notification) => {
    try {
      await axiosInstance.put(`/api/notifications/${notification.id}`, {
        data: {
          viewed: true
        }
      });
      //@ts-ignore
      setNotifications(notifications.map(n => n.id === notification.id ? { ...n, attributes: { ...n.attributes, viewed: true } } : n));
    } catch (error) {
      console.error('Failed to mark notification as viewed:', error);
    }
  };

  return (
    <AppBar
      position='static'
      sx={{
        background: '#f5f5f5',
      }}
    >
      <Toolbar>
        <Typography
          variant='h6'
          component='div'
          sx={{
            flexGrow: 1,
            color: 'black',
          }}
        >
          <a
            href='/'
            style={{
              textDecoration: 'none',
              color: 'black',
              fontWeight: 'bold',
            }}
            className='d-flex justify-content-start align-items-center'
          >
            <img src={pngLogo} className='w-100px me-2' alt='Logo' />

            <span>
              Business Mission Manager
              <span style={{fontSize: '0.75rem', display: 'block'}}>
                by US-ASEAN Business Council
              </span>
            </span>
          </a>
        </Typography>
        <Box sx={{flexGrow: 0}}>
          {!isRepresentative && !missionId && !missionRegistrationId &&
          <Button className='mx-5' color='primary' variant='contained' onClick={createMission}>
          Create Mission
        </Button>}
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}} color='primary'>
              <Avatar alt={currentUser?.firstName} src='/static/images/avatar/2.jpg' />
            </IconButton>
          </Tooltip>
          {!isRepresentative &&
          <Tooltip title="Notifications">
            <IconButton onClick={handleOpenNotificationsMenu} color="inherit">
              <Badge 
              //@ts-ignore
                badgeContent={notifications.filter(notification => !notification.attributes.viewed).length}
                color="secondary"
              >
                <NotificationsIcon sx={{ fontSize: 28, color: 'gray' }} />
              </Badge>
            </IconButton>
          </Tooltip>
            }
          <Menu
            sx={{mt: '45px'}}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key='logout' onClick={logout}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
          </Menu>
          <Menu
            id="menu-notifications"
            anchorEl={anchorElNotifications}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseNotificationsMenu}
            keepMounted
            PaperProps={{
              style: {
                maxHeight: 500,
              },
            }}
          >
            {notifications.length > 0 ? (
              notifications.map((notification, index) => {
                //@ts-ignore
                const formattedDateTime = new Date(notification.attributes.createdAt).toLocaleString('en-US', {
                  year: 'numeric', month: 'short', day: 'numeric',
                  hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                });

                return (
                  <MenuItem key={index}
                    onClick={async () => {
                        //@ts-ignore
                      if (!notification.attributes.viewed) {
                        await markNotificationAsViewed(notification);
                      }
                      await handleCloseNotificationsMenu();
                      //@ts-ignore
                      await navigate(notification.attributes.url)
                    }}
                    style={{
                        //@ts-ignore
                      backgroundColor: notification.attributes.viewed ? 'transparent' : '#f0f0f0',
                        //@ts-ignore
                      fontWeight: notification.attributes.viewed ? 'normal' : 'bold',
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {/* @ts-ignore */}
                        {notification.attributes.title}
                      </Typography>
                      <Typography variant="body2">
                        {/* @ts-ignore */}
                        {notification.attributes.message}
                      </Typography>
                      <Typography variant="caption" style={{ display: 'block', marginTop: '8px' }}>
                        {formattedDateTime}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>
                <Typography variant="body1" style={{ textAlign: 'center', width: '100%' }}>
                  No notifications
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
